"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMerchandisingURL = void 0;
var merchandising_1 = require("../helpers/merchandising");
function useMerchandisingURL(dto) {
    try {
        var dealerCode = dto.dealerCode, channel = dto.channel, subdealer = dto.subdealer, retail = dto.retail, locationName = dto.locationName, locationPhone = dto.locationPhone, masterDealerName = dto.masterDealerName, principalEmail = dto.principalEmail;
        var currentDate = new Date().toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        var concatenatedPrincipalEmail = "".concat(dealerCode, "-").concat(principalEmail.trimEnd());
        var dealerInfo = {
            authenticator: {
                dealerCode: dealerCode,
                channel: channel,
                subdealer: subdealer,
                retail: retail,
                locationName: locationName,
                locationPhone: locationPhone,
                masterDealerName: masterDealerName,
                principalEmail: concatenatedPrincipalEmail,
                date: currentDate,
                userToken: (0, merchandising_1.getUserToken)(concatenatedPrincipalEmail, currentDate),
            },
        };
        var baseMerchandiseURL = new URL('https://1334893.extforms.netsuite.com/app/site/hosting/scriptlet.nl');
        var merchandiseQueryParams = new URLSearchParams({
            script: process.env.REACT_APP_MERCHANDISE_URL_QUERY_PARAM_SCRIPT,
            deploy: '1',
            compid: '1334893',
            "ns-at": process.env.REACT_APP_MERCHANDISE_URL_QUERY_PARAM_NS_AT,
        });
        var xml = (0, merchandising_1.objectToXML)(dealerInfo);
        merchandiseQueryParams.append('Authenticator', xml);
        baseMerchandiseURL.search = merchandiseQueryParams.toString();
        return {
            urlEncoded: baseMerchandiseURL.toString(),
        };
    }
    catch (error) {
        return {
            error: error,
        };
    }
}
exports.useMerchandisingURL = useMerchandisingURL;
