"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCartContextController = void 0;
var tslib_1 = require("tslib");
var react_1 = require("react");
var hooks_1 = require("../../hooks");
var usePersistedState_1 = require("../../hooks/usePersistedState");
var useCartContextController = function () {
    var update = (0, hooks_1.useCart)().update;
    var _a = (0, usePersistedState_1.usePersistedState)('cartItems', {
        products: [],
        events: [],
    }), items = _a[0], setItems = _a[1];
    var updateDatabase = (0, react_1.useCallback)(function (cart) { return (0, tslib_1.__awaiter)(void 0, void 0, void 0, function () {
        var products, events, err_1;
        var _a, _b;
        return (0, tslib_1.__generator)(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    products = cart.products.map(function (_a) {
                        var product = _a.product, productRest = (0, tslib_1.__rest)(_a, ["product"]);
                        return ((0, tslib_1.__assign)({ product: product === null || product === void 0 ? void 0 : product._id }, productRest));
                    });
                    events = (_b = (_a = cart === null || cart === void 0 ? void 0 : cart.events) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (_a) {
                        var event = _a.event, eventRest = (0, tslib_1.__rest)(_a, ["event"]);
                        return ((0, tslib_1.__assign)({ event: event === null || event === void 0 ? void 0 : event._id }, eventRest));
                    });
                    return [4 /*yield*/, update({ products: products, events: events })];
                case 1: return [2 /*return*/, _c.sent()];
                case 2:
                    err_1 = _c.sent();
                    console.log(err_1);
                    throw new Error(err_1);
                case 3: return [2 /*return*/];
            }
        });
    }); }, [update]);
    var addProduct = (0, react_1.useCallback)(function (product) {
        updateDatabase((0, tslib_1.__assign)((0, tslib_1.__assign)({}, items), { products: (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], items.products, true), [product], false) })).then(function (items) { return setItems(items); });
    }, [items, setItems, updateDatabase]);
    var addEvent = (0, react_1.useCallback)(function (event) {
        updateDatabase((0, tslib_1.__assign)((0, tslib_1.__assign)({}, items), { events: (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], items.events, true), [event], false) })).then(function (items) { return setItems(items); });
    }, [items, setItems, updateDatabase]);
    var removeProduct = (0, react_1.useCallback)(function (removeIndex) {
        updateDatabase((0, tslib_1.__assign)((0, tslib_1.__assign)({}, items), { products: items.products.filter(function (_, index) { return index !== removeIndex; }) })).then(function (items) { return setItems(items); });
    }, [items, setItems, updateDatabase]);
    var removeEvent = (0, react_1.useCallback)(function (removeIndex) {
        updateDatabase((0, tslib_1.__assign)((0, tslib_1.__assign)({}, items), { events: items.events.filter(function (_, index) { return index !== removeIndex; }) })).then(function (items) { return setItems(items); });
    }, [items, setItems, updateDatabase]);
    var clearAllItems = (0, react_1.useCallback)(function () {
        updateDatabase((0, tslib_1.__assign)((0, tslib_1.__assign)({}, items), { products: [], events: [] })).then(function (items) { return setItems(items); });
    }, [items, setItems, updateDatabase]);
    return {
        items: items,
        addProduct: addProduct,
        addEvent: addEvent,
        removeProduct: removeProduct,
        removeEvent: removeEvent,
        clearAllItems: clearAllItems,
    };
};
exports.useCartContextController = useCartContextController;
