/* eslint-disable @typescript-eslint/no-explicit-any */
import * as S from './styles'

import { Dealer, DealerChannel, DealerResponse } from '@monorepo/interfaces'
import { Pagination, SectionTeamMembersList } from '../..'
import React, { useCallback, useEffect, useState, useRef } from 'react'

import { PageSpinner, Spinner, SearchTerm } from '@monorepo/components'
import { RegistrationUserResponse } from '../Registration'
import {
  httpUserInfo,
  useChannel,
  useDebounce,
  useQueryParams,
  KeycloakHelper,
  httpDialogDirect,
  ChannelHelper,
} from '@monorepo/infra'
import axios from 'axios'
import {
  LogItemType,
  LogType,
  UserActivityLogData,
  log,
} from '../../../services/log.service'
import { useKeycloak } from '@react-keycloak/web'

type TeamMemebersProps = {
  profile: RegistrationUserResponse | null
  isAdmin: boolean
  dialog: {
    showEditDialog: boolean
    showCreateDialog: boolean
    showDeleteDialog: boolean
    setShowEditDialog: (toggle: boolean) => void
    setShowCreateDialog: (toggle: boolean) => void
    setShowDeleteDialog: (toggle: boolean) => void
  }
}

interface CreateDealer {
  user: {
    firstName: string
    lastName: string
    email: string
    groups: string[]
    enabled: boolean
  }
  dealer: {
    hasCoop: boolean
    channels?: string[]
    associatedMasterDealers: [
      {
        masterDealerId?: string
        channels?: string[]
        dealerTypes?: string[]
      }
    ]
  }
}

export interface DealerForm {
  firstName: string
  lastName: string
  email: string
  channels: string[]
  types: string[]
  status: { value: string; label: string }
  hasCoop: boolean
  userId: string
  dealerId: string
}

const TeamMembers: React.FC<TeamMemebersProps> = ({
  isAdmin,
  profile,
  dialog,
}) => {
  const [paginationURL, setPaginationURL] = useState('page=1&pageSize=20')
  const [pagination, setPagination] = useState({
    totalCount: 1,
    page: 1,
    pageSize: 20,
    totalPages: 2,
  })
  const [dealers, setDealers] = useState<Dealer[] | null>()
  const masterDealerId =
    profile?.dealer?.associatedMasterDealers[0]?.masterDealerId
  const masterDealerIdURL = masterDealerId
    ? `masterDealerId=${masterDealerId}`
    : ''
  const [error, setError] = useState('')
  const [principalEmails, setPrincipalEmails] = useState<string[] | undefined>()

  const handlePageChange = useCallback((pageNumber: number) => {
    setPaginationURL(`page=${pageNumber}&pageSize=20`)
  }, [])

  const { selectedChannel, selectedMasterDealerId } = useChannel()
  const { keycloak } = useKeycloak()
  const searchURLKey = 's'
  const queryParams = useQueryParams()
  const [search, setSearch] = useState(queryParams.get(searchURLKey) ?? '')
  const debouncedSearch = useDebounce(search, 1000)

  const [loading, setLoading] = useState(false)
  const previousApiUrl = useRef<string | undefined>(undefined)

  const handleUserActivity = (data: DealerForm) => {
    const userChanges: UserActivityLogData = {
      userId: data.userId,
      changes: [],
    }

    const previusData = dealers?.find((d) => d.dealer._id === data.dealerId)
    if (previusData?.dealer.hasCoop !== data.hasCoop) {
      userChanges.changes.push({
        key: 'hasCoop',
        previusValue: Boolean(previusData?.dealer.hasCoop),
        currentValue: data.hasCoop,
      })
    }
    if (!previusData?.groups.includes(data.status.value)) {
      userChanges.changes.push({
        key: 'group',
        previusValue: `${previusData?.groups}`,
        currentValue: data.status.label,
      })
    }
    if (
      previusData?.dealer.associatedMasterDealers[0].channels
        .toString()
        .toLowerCase() !== data.channels.toString().toLowerCase()
    ) {
      Object.values(DealerChannel).filter((d) => {
        if (
          !previusData?.dealer.associatedMasterDealers[0].channels?.includes(
            d
          ) &&
          data?.channels?.includes(d)
        ) {
          userChanges.changes.push({
            key: d,
            previusValue: false,
            currentValue: true,
          })
        }
        if (
          previusData?.dealer.associatedMasterDealers[0].channels?.includes(
            d
          ) &&
          !data?.channels?.includes(d)
        ) {
          userChanges.changes.push({
            key: d,
            previusValue: true,
            currentValue: false,
          })
        }
      })
    }
    if (userChanges.changes.length > 0) {
      log(
        {
          type: LogType.USER_ACTIVITY,
          itemType: LogItemType.EVENT,
          masterDealerId: selectedMasterDealerId,
          channel: selectedChannel,
          userActivity: userChanges,
        },
        KeycloakHelper.getToken(keycloak)
      )
    }
  }

  const handleCreateUser = async (data: DealerForm) => {
    const dealer: CreateDealer = {
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        groups: [data.status.value],
        enabled: true,
      },
      dealer: {
        hasCoop: data.hasCoop,
        associatedMasterDealers: [
          {
            masterDealerId,
            channels: data.channels,
            dealerTypes: data.types,
          },
        ],
      },
    }
    const userExists = await httpUserInfo.get({
      url: `/dealer/${data.email}/exists`,
    })
    if (userExists.data === true) {
      setError(
        `That user already exists for another master dealer. Please use a different email address.`
      )
    } else {
      httpUserInfo
        .post({ url: '/dealer', data: dealer })
        .then(() => {
          addDialogDirectUser(data)
            .then(() => {
              setError('')
              dialog.setShowCreateDialog(false)
              getData()
            })
            .catch()
        })
        .catch()
    }
  }

  const handleDeleteUser = async (userId: string, dealerId: string) => {
    try {
      await httpUserInfo.delete({ url: `/dealer/${dealerId}` })
      await httpUserInfo.delete({ url: `/user/${userId}` })
    } catch (err) {
      console.error(err)
    } finally {
      dialog.setShowDeleteDialog(false)
      getData()
    }
  }

  const addDialogDirectUser = async (data: DealerForm) => {
    const addUserDto = {
      companyName: '',
      emailAddress: data.email.trim(),
      channel: data.channels.join('|').toUpperCase(),
      corpId: ChannelHelper.getMasterDealerId(
        masterDealerId as string,
        selectedChannel
      ),
      multiCorpId: data.channels
        .map((c) =>
          ChannelHelper.getMasterDealerId(
            masterDealerId as string,
            c.toUpperCase()
          )
        )
        .join('|'),
      address1: 'address1',
      addressee: '',
      city: 'Palo Alto',
      zip: '94301',
      state: 'CA',
      phoneNumber: '',
      attention: '',
      firstName: data.firstName,
      lastName: data.lastName,
    }

    await httpDialogDirect.post({ url: '/AddCustomer', data: addUserDto })
  }

  const handleEditUser = async (data: DealerForm) => {
    try {
      const dealerData = {
        hasCoop: data.hasCoop,
        associatedMasterDealers: [
          {
            masterDealerId,
            channels: data.channels,
            dealerTypes: data.types,
          },
        ],
      }

      const userData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        groups: [data.status.value],
      }

      await httpUserInfo.patch({
        url: `/dealer/${data.dealerId}`,
        data: dealerData,
      })

      await httpUserInfo.patch({
        url: `/user/${data.userId}`,
        data: userData,
      })
      handleUserActivity(data)
      dialog.setShowEditDialog(false)
      getData()
    } catch (err) {
      console.error(err)
    }
  }

  const handleClearSearch = () => {
    setSearch('')
  }

  const getData = () => {
    const searchURL =
      debouncedSearch.length > 2
        ? `search=${encodeURIComponent(debouncedSearch)}`
        : ''
    if (masterDealerIdURL) {
      const apiURL = `/dealer?${paginationURL}&${masterDealerIdURL}&${searchURL}`

      if (previousApiUrl.current === apiURL) {
        return
      }

      previousApiUrl.current = apiURL
      setLoading(true)
      httpUserInfo
        .get<DealerResponse>({
          url: apiURL,
        })
        .then((response) => {
          const { totalCount, page, pageSize, totalPages, data } = response.data

          setPagination({
            totalCount,
            page,
            pageSize,
            totalPages,
          })
          setDealers(data)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    getData()
  }, [masterDealerIdURL, paginationURL, debouncedSearch])

  useEffect(() => {
    getDealerUniqueEmails()
  }, [])

  const getDealerUniqueEmails = () => {
    axios
      .get('/United/DealerUniqueEmails', {
        baseURL: process.env.REACT_APP_TX_BASE_URL,
      })
      .then((response) => {
        setPrincipalEmails(response?.data?.DealerEmails)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  if (!dealers) return <PageSpinner />

  return (
    <S.Container>
      <S.SearchContainer>
        <S.Search
          placeholder={'Search Team Members'}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <SearchTerm searchTerm={search} onClear={handleClearSearch} />
      </S.SearchContainer>
      {!!loading ? (
        <Spinner spinnerSize={8} />
      ) : (
        <>
          <SectionTeamMembersList
            isAdmin={isAdmin}
            dealers={dealers}
            dialog={dialog}
            loading={!dealers}
            createDealer={handleCreateUser}
            deleteDealer={handleDeleteUser}
            editDealer={handleEditUser}
            error={error}
            principalEmails={principalEmails}
          />
          <Pagination
            onPageChange={handlePageChange}
            totalCount={pagination.totalCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalPages={pagination.totalPages}
          />
        </>
      )}
    </S.Container>
  )
}

export default TeamMembers
