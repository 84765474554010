import { colors } from '@monorepo/theme'
import { ChangeEvent } from 'react'
import * as S from './styles'

export type SearchProps = {
  width?: string | number
  height?: string | number
  placeholder?: string
  iconSize?: number
  backgroundColor?: string
  value?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onClear?: () => void
  color?: string
  borderBottom?: boolean
  iconColor?: string
fontSize?:string | number
}

const Search: React.FC<SearchProps> = ({
  height,
  width,
  backgroundColor,
  placeholder = 'Search',
  iconSize,
  value,
  onChange,
  onClear,
  color,
  borderBottom,
  iconColor,
  fontSize,
  ...props
}) => {
  const searchInputProps = {
    height,
    width,
    backgroundColor,
    placeholder,
    color,
  }

  const isInputControlled = typeof value === 'string'

  const handleClear = () => {
    if (value && onClear) {
      onClear()
    }
  }

  return (
    <S.SearchContainer>
      <span onClick={() => handleClear()}>
        <S.StyledIcon
          size={18}
          hasFill={false}
          icon={value ? 'CloseCircular' : 'Search'}
          color={iconColor || colors.white}
        />
      </span>
      {isInputControlled ? (
        <S.SearchInput
          {...searchInputProps}
          onChange={onChange}
          value={value}
          color={color}
          fontSize={fontSize ?? 18}
          borderBottom={borderBottom}
        />
      ) : (
        <S.SearchInput {...searchInputProps} />
      )}
    </S.SearchContainer>
  )
}

export default Search
