import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import { Link } from 'react-router-dom'

export const HeaderContainer = styled.header`
  display: grid;
  grid-template-areas: 'logo nav-items nav-tools';
  grid-template-columns: 0.5fr 1fr 0.5fr;
  width: 100%;
  min-height: 88px;
  background: ${colors.dark};
`

export const NavItemsContainer = styled.ul`
  grid-area: nav-items;
  display: flex;
  margin: auto;
  gap: 36px;
  height: 100%;
`

export const LogoContainer = styled(Link)`
  grid-area: logo;
  margin: auto;
  margin-left: 30px;
`

export const NavToolsContainer = styled.div`
  margin: auto;
  display: flex;
  gap: 12px;
  input {
    margin-right: 12px;
  }
`

export const HeaderButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  max-width: 55px;
`
interface UserNameProps {
  color?: string
}

export const UserName = styled.div<UserNameProps>`
  color: ${(props) => props.color || colors.white};
  font-family: PFDINText;
  margin: 0 12px 0 auto;
  font-size: 14px;
  max-width: 100px;
  text-align: right;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  line-height: 33px;
`
