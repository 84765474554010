import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const SearchTermContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const ClearSearchButton = styled.button`
  width: 22px;
  height: 22px;
  margin: 0 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
  outline: unset;
  cursor: pointer;

  &:hover:not(:disabled) {
    background-color: ${colors.lightGrey};
  }
`

export const SearchTerm = styled.strong`
  font-weight: 600;
  margin: 0 10px 0 5px;
`
