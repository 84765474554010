"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppInsightsProvider = void 0;
var tslib_1 = require("tslib");
var jsx_runtime_1 = require("react/jsx-runtime");
var applicationinsights_react_js_1 = require("@microsoft/applicationinsights-react-js");
var setup_1 = require("./setup");
var AppInsightsProvider = function (_a) {
    var children = _a.children;
    return ((0, jsx_runtime_1.jsx)(applicationinsights_react_js_1.AppInsightsContext.Provider, (0, tslib_1.__assign)({ value: setup_1.reactPlugin }, { children: children }), void 0));
};
exports.AppInsightsProvider = AppInsightsProvider;
