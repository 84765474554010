import * as S from './styles'

export interface HeroCreative2Props {
  upTitle?: string
  title?: string
  description?: string
  buttonGetStartedUrl?: string
  background?: string
}

const HeroCreative2: React.FC<HeroCreative2Props> = ({
  upTitle,
  title,
  description,
  buttonGetStartedUrl,
  background,
}) => {
  return (
    <S.HeroCreative2Container background={background}>
      <S.HeroCreative2TextContainer>
        {!!upTitle ? (
          <S.HeroCreative2TextContainerUpTitle>
            {upTitle}
          </S.HeroCreative2TextContainerUpTitle>
        ) : undefined}
        {!!title ? (
          <S.HeroCreative2TextContainerTitle>
            {title}
          </S.HeroCreative2TextContainerTitle>
        ) : undefined}
        {!!description ? (
          <S.HeroCreative2TextContainerDescription>
            {description}
          </S.HeroCreative2TextContainerDescription>
        ) : undefined}
        <S.HeroCreative2TextContainerButtons>
          {!!buttonGetStartedUrl ? (
            <S.ButtonLink to={{ pathname: `${buttonGetStartedUrl}` }}>
              Get started
            </S.ButtonLink>
          ) : undefined}
        </S.HeroCreative2TextContainerButtons>
      </S.HeroCreative2TextContainer>
    </S.HeroCreative2Container>
  )
}

export default HeroCreative2
