import styled from 'styled-components'

export const Form = styled.div`
  width: 100%;
  max-width: 922px;
  margin: 1rem auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Container = styled.div`
  min-height: calc(100vh - 100px - 76px);
`
