import { colors } from '@monorepo/theme'
import styled from 'styled-components'
import { Dialog as ReachDialog } from '@reach/dialog'

const ProductList = styled.section`
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;

  @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
  }
  
  @media (min-width: 1440px) {
      grid-template-columns: repeat(4, 1fr);
  }
  
  @media (min-width: 1920px) {
      grid-template-columns: repeat(6, 1fr);
    }
    `
    
    const PromotionalArea = styled.div`
    display: flex;
    max-height: 304px;
    background-color: ${colors.delicateGrey};

    @media (min-width: 1920px) {
      max-height: 302px;
      }
`

const Container = styled.div`
  gap: 16px;
  margin: 40px 30.25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Title = styled.h1`
  color: ${colors.lapis};
  font-size: 23px;
  font-weight: 500;
  line-height: 30px;
  text-transform: none;
  font-family: PFDINText;
  `
  
  const Content = styled.p`
  font-family: PFDINText;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
  color: ${colors.darkBlue};
  margin-bottom: 30.77px;
`

const NoData = styled.h2`
  text-align: center;
  width: 100%;
`

const Dialog = styled(ReachDialog)`
  &[data-reach-dialog-content] {
    background-color: #1d2328;
    position: relative;
    padding: 58px;
  }
`

const CloseButton = styled.button`
  width: 26px;
  height: 26px;
  border: 0;
  background: #e7e9ec;
  border-radius: 50%;
  position: absolute;
  top: 27px;
  right: 27px;
`

const DialogContainer = styled.div`
  margin: auto 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`

export {
  ProductList,
  PromotionalArea,
  Container,
  Content,
  Title,
  NoData,
  Dialog,
  CloseButton,
  DialogContainer,
}
