"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChannel = exports.ChannelProvider = void 0;
var tslib_1 = require("tslib");
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var context_1 = require("./context");
var controller_1 = require("./controller");
var ChannelProvider = function (_a) {
    var children = _a.children;
    return ((0, jsx_runtime_1.jsx)(context_1.ChannelContext.Provider, (0, tslib_1.__assign)({ value: (0, tslib_1.__assign)({}, (0, controller_1.useChannelContextController)()) }, { children: children }), void 0));
};
exports.ChannelProvider = ChannelProvider;
function useChannel() {
    var context = (0, react_1.useContext)(context_1.ChannelContext);
    if (!context) {
        throw new Error('useChannel must be used within a ChannelProvider.');
    }
    return context;
}
exports.useChannel = useChannel;
