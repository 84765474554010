"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const S = (0, tslib_1.__importStar)(require("./styles"));
const core_1 = require("@material-ui/core");
const framer_motion_1 = require("framer-motion");
const infra_1 = require("@monorepo/infra");
const FilterColapse = ({ title, dropdownItems, className, }) => {
    const [expanded, setExpanded] = (0, react_1.useState)(true);
    const { selectedItems, setState } = (0, react_1.useContext)(infra_1.ItemsContext);
    const handleExpandedClick = () => {
        setExpanded(!expanded);
    };
    const handleChangeItems = (item) => {
        setState({
            type: infra_1.ActionType.SetItem,
            payload: { item },
        });
    };
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ "data-testid": "filterColapse", className: className }, { children: [(0, jsx_runtime_1.jsxs)(S.Item, { children: [(0, jsx_runtime_1.jsx)(S.Title, { children: title }, void 0), (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, Object.assign({ whileTap: { rotate: 180 } }, { children: (0, jsx_runtime_1.jsx)(core_1.IconButton, Object.assign({ onClick: handleExpandedClick, "aria-expanded": expanded, "aria-label": "show more" }, { children: (0, jsx_runtime_1.jsx)(S.ExpandMoreIcon, {}, void 0) }), void 0) }), void 0)] }, void 0), (0, jsx_runtime_1.jsx)(core_1.Collapse, Object.assign({ in: expanded, timeout: "auto", unmountOnExit: true }, { children: (0, jsx_runtime_1.jsx)(core_1.FormGroup, { children: dropdownItems.map((item, key) => {
                        return ((0, jsx_runtime_1.jsx)(S.FormControlLabel, { control: (0, jsx_runtime_1.jsx)(core_1.Checkbox, { checked: !(selectedItems.items.indexOf(item) === -1) }, void 0), onChange: () => {
                                handleChangeItems(item);
                            }, label: item }, key));
                    }) }, void 0) }), void 0)] }), void 0));
};
exports.default = FilterColapse;
