"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const core_1 = require("@material-ui/core");
const react_i18next_1 = require("react-i18next");
const S = (0, tslib_1.__importStar)(require("./styles"));
const RoleForm = (_a) => {
    var props = (0, tslib_1.__rest)(_a, []);
    const { t } = (0, react_i18next_1.useTranslation)('forms');
    const [roleName, setRoleName] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        setRoleName(props.roleName);
    }, [props.roleName]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(core_1.Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: (0, jsx_runtime_1.jsx)(S.Input, { id: "rolename", name: "rolename", onChange: (event) => setRoleName(event.target.value), value: roleName, label: t('role.inputs.roleName.label'), placeholder: t('role.inputs.roleName.placeholder') }, void 0) }), void 0) }, void 0));
};
exports.default = RoleForm;
