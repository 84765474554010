"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var applicationinsights_react_js_1 = require("@microsoft/applicationinsights-react-js");
var useTracking = function (_a) {
    var project = _a.project, componentName = _a.componentName;
    var appInsights = (0, applicationinsights_react_js_1.useAppInsightsContext)();
    var trackComponent = (0, applicationinsights_react_js_1.useTrackMetric)(appInsights, "Project: ".concat(project, " - Component ").concat(componentName));
    return trackComponent;
};
module.exports = { useTracking: useTracking };
