import { colors } from '@monorepo/theme'
import mediaDetails from '../../../assets/media/media-details.png'
import styled from 'styled-components'

const Wrap = styled.main`
  max-width: 100vw;
`

const SectionProduct = styled.section`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`

const SectionPorductItem = styled.div<{ background?: string }>`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-self: flex-start;
  height: 100%;
  position: relative;
  width: 50%;
  min-height: 640px;

  background: ${(props) => props.background || 'inherit'};

  & > .slick-slider {
    position: relative;
    width: 100%;
    padding-top: 145px;
    padding-bottom: 70px;
    background-color: ${colors.lighterGrey};
    // display: flex;
    // flex: 1;

    & > .slick-list > .slick-track {
      place-items: center;
      display: flex;
    }

    & > .slick-dots {
      bottom: -22px;
      background-color: ${colors.lighterGrey};
      & > li > button::before {
        font-size: 10px;
      }
    }
  }

  & div {
    max-height: 100%;
  }
`

const CaroselImage = styled.div`
  & img {
    margin: 0 auto;
    max-width: 476px;
    max-height: 616px;
  }
`

const MediaHightligh = styled.section`
  background-image: url(${mediaDetails});
  background-position: top;
  background-size: cover;
  height: 570px;
  margin: 40px 0 0;
  width: 100%;
`

export {
  Wrap,
  SectionProduct,
  SectionPorductItem,
  CaroselImage,
  MediaHightligh,
}
