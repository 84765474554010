import { OrderAddress, IPayment } from '@monorepo/interfaces'
import React from 'react'
import * as S from '../styles'

interface CheckoutPaymentViewProps {
  payment: IPayment
  onClickEdit: () => void
}

export const SectionCheckoutPaymentView: React.FC<CheckoutPaymentViewProps> = ({
  payment,
  onClickEdit,
}) => {
  const last4 = payment.cardNumber.slice(payment.cardNumber.length - 4)
  const getAddress = (address: OrderAddress) => {
    if (address.address2 && address.address2 !== '') {
      return `
          ${address.firstName} ${address.lastName}
          <br />
          ${address.address1}, ${address.address2}, ${address.city}, ${address.state} ${address.zipCode}
          `
    }
    return `
          ${address.firstName} ${address.lastName}
          <br />
          ${address.address1}, ${address.city}, ${address.state} ${address.zipCode}
          `
  }
  return (
    <S.ViewContainer>
      <S.ViewDetails flexBasis="90%">
        <S.Title>PAYMENT</S.Title>
        <S.Text
          dangerouslySetInnerHTML={{
            __html: getAddress(payment.billingAddress),
          }}
        />
        <S.Text>
          {payment.cardNumber === ''
            ? 'Click Edit to Update'
            : `Card ending in ${last4}`}
        </S.Text>
      </S.ViewDetails>
      <S.ViewDetails flexBasis="10%">
        <S.EditButton onClick={() => onClickEdit()}>Edit</S.EditButton>
      </S.ViewDetails>
    </S.ViewContainer>
  )
}
