import * as S from './styles'
import * as Yup from 'yup'

import { Field, Formik, setNestedObjectValues } from 'formik'
import { Input, Select, Spinner } from '@monorepo/components'
import React, { useCallback, useState } from 'react'
import { httpUserInfo, useChannel } from '@monorepo/infra'

import { RegistrationUserResponse } from '../../components/templates/Registration'
import { useKeycloak } from '@react-keycloak/web'

const MyInformationSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name.'),
  lastName: Yup.string().required('Please enter your last name.'),
})

const PasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Please enter your new password.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  confirmNewPassword: Yup.string()
    .required('Please re-enter your new password.')
    .when('newPassword', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        'Both password need to be the same'
      ),
    }),
})

const MyChannelsSchema = Yup.object().shape({
  channel: Yup.string().required('Please select a channel.'),
})

const FORM_OPTIONS = {
  MY_INFORMATION: 'MY_INFORMATION',
  PASSWORD: 'PASSWORD',
  MY_CHANNELS: 'MY_CHANNELS',
}

interface MyAccountProps {
  profile: RegistrationUserResponse | null
}

const MyAccount: React.FC<MyAccountProps> = ({ profile }) => {
  const { keycloak } = useKeycloak()
  const { selectedChannel, setState, selectedMasterDealerId } = useChannel()
  const [formOption, setformOption] = useState<string | null>(null)

  const [showDialog, setShowDialog] = React.useState(false)
  const open = (option: string) => {
    setformOption(option)
    setShowDialog(true)
  }
  const close = () => setShowDialog(false)

  const getSchema = (option: string | null) => {
    switch (option) {
      case FORM_OPTIONS.MY_INFORMATION:
        return MyInformationSchema
      case FORM_OPTIONS.PASSWORD:
        return PasswordSchema
      case FORM_OPTIONS.MY_CHANNELS:
        return MyChannelsSchema
      default:
        return null
    }
  }

  const channels = profile?.dealer?.associatedMasterDealers[0]?.masterDealer
    .map((md) =>
      md.channels.map((channel) => {
        return {
          label: channel,
          masterDealerId: md.masterDealerId,
          value: `${md.masterDealerId}-${channel}`,
        }
      })
    )
    .flat(2)

  const handleSubmit = useCallback(
    (values) => {
      if (formOption === FORM_OPTIONS.MY_CHANNELS) {
        const masterDealerId = values.channel.split('-')[0]
        const channel = values.channel.split('-')[1]
        setState(masterDealerId, channel.toUpperCase())
      }
      if (formOption === FORM_OPTIONS.MY_INFORMATION) {
        // TODO: fix success return, remove refresh page to refrech profile data
        httpUserInfo
          .patch({
            url: `/user/${profile?._id}`,
            data: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: profile?.email,
              keycloakUserId: profile?.keycloakUserId,
            },
          })
          .then(() => window.location.reload())
      }
      if (formOption === FORM_OPTIONS.PASSWORD) {
        httpUserInfo
          .post({
            url: `/user/updatePassword`,
            data: {
              email: profile?.email,
              password: values.newPassword,
              confirmPassword: values.confirmNewPassword,
            },
          })
          .then(() => {
            localStorage.removeItem('selectedChannel')
            localStorage.removeItem('selectedMasterDealerId')
            keycloak.logout()
          })
      }
    },
    [setState, formOption, profile, keycloak]
  )

  return (
    <>
      <S.Container>
        <S.Content>
          <S.Card gridRow={'1/3'} gridColumn={'1'}>
            <S.CardTitle>My Information</S.CardTitle>
            <S.CardInfoContainer>
              <S.CardLabel>First Name</S.CardLabel>
              <S.CardItem>
                {profile?.firstName ? (
                  <p>{profile?.firstName}</p>
                ) : (
                  <Spinner spinnerSize={4} />
                )}
              </S.CardItem>
            </S.CardInfoContainer>
            <S.CardInfoContainer>
              <S.CardLabel>Last Name</S.CardLabel>
              <S.CardItem>
                {profile?.lastName ? (
                  profile?.lastName
                ) : (
                  <Spinner spinnerSize={4} />
                )}
              </S.CardItem>
            </S.CardInfoContainer>
            <S.CardInfoContainer>
              <S.CardLabel>Email</S.CardLabel>
              <S.CardItem>
                {profile?.email ? profile?.email : <Spinner spinnerSize={4} />}
              </S.CardItem>
            </S.CardInfoContainer>
          </S.Card>

          <S.Card gridRow={'1'} gridColumn={'2'}>
            <S.CardTitle>Password</S.CardTitle>
            <S.CardLabel>************</S.CardLabel>
            <S.CardButton
              onClick={() => {
                open(FORM_OPTIONS.PASSWORD)
              }}
            >
              Change Password
            </S.CardButton>
          </S.Card>

          <S.Card gridRow={'2'} gridColumn={'2'}>
            <S.CardTitle>My Channels</S.CardTitle>
            <S.CardItem>
              {channels ? (
                channels?.map((channel, index) => (
                  <p key={index}>
                    {channel.label}
                    {selectedMasterDealerId + selectedChannel ===
                      channel.masterDealerId + channel.label &&
                      ` - Current Channel`}
                  </p>
                ))
              ) : (
                <Spinner spinnerSize={4} />
              )}
            </S.CardItem>
          </S.Card>
        </S.Content>
      </S.Container>

      <S.ProfileDialog
        aria-label="Dialog"
        isOpen={showDialog}
        onDismiss={close}
      >
        <S.DialogContent aria-label="Content">
          <S.DialogTitle>
            {formOption === FORM_OPTIONS.MY_INFORMATION
              ? 'My Info'
              : formOption === FORM_OPTIONS.PASSWORD
              ? 'Password'
              : formOption === FORM_OPTIONS.MY_CHANNELS
              ? 'My Channels'
              : ''}
          </S.DialogTitle>
          <Formik
            initialValues={
              formOption === FORM_OPTIONS.MY_INFORMATION
                ? {
                    firstName: profile?.firstName,
                    lastName: profile?.lastName,
                  }
                : formOption === FORM_OPTIONS.PASSWORD
                ? {
                    newPassword: '',
                    confirmNewPassword: '',
                  }
                : {
                    channel: `${selectedMasterDealerId}-${selectedChannel}`,
                  }
            }
            validationSchema={getSchema(formOption)}
            validateOnBlur={formOption === FORM_OPTIONS.PASSWORD ? false : true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              handleSubmit(values)
              actions.setSubmitting(false)
              close()
            }}
          >
            {({
              errors,
              touched,
              values,
              setFieldValue,
              setFieldTouched,
              validateForm,
              submitForm,
              setTouched,
            }) => (
              <S.DialogForm>
                {formOption === FORM_OPTIONS.MY_INFORMATION && (
                  <>
                    <Field
                      id="firstName"
                      name="firstName"
                      component={Input}
                      value={values.firstName}
                      invalid={touched.firstName && errors.firstName}
                      invalidMessage={errors.firstName}
                      label="First name"
                      onBlur={() => setFieldTouched('firstName')}
                      onChange={(option: { target: { value: string } }) => {
                        setFieldValue('firstName', option.target.value)
                      }}
                    />

                    <Field
                      id="lastName"
                      name="lastName"
                      component={Input}
                      value={values.lastName}
                      invalid={touched.lastName && errors.lastName}
                      invalidMessage={errors.lastName}
                      label="Last name"
                      onBlur={() => {
                        setFieldTouched('lastName')
                      }}
                      onChange={(option: { target: { value: string } }) => {
                        setFieldValue('lastName', option.target.value)
                      }}
                    />
                  </>
                )}
                {formOption === FORM_OPTIONS.PASSWORD && (
                  <>
                    <Field
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      component={Input}
                      invalid={touched.newPassword && errors.newPassword}
                      invalidMessage={errors.newPassword}
                      label="New password"
                      onBlur={() => {
                        setFieldTouched('newPassword')
                      }}
                      onChange={(option: { target: { value: string } }) => {
                        setFieldValue('newPassword', option.target.value)
                      }}
                    />

                    <Field
                      id="confirmNewPassword"
                      name="confirmNewPassword"
                      type="password"
                      component={Input}
                      invalid={
                        touched.confirmNewPassword && errors.confirmNewPassword
                      }
                      invalidMessage={errors.confirmNewPassword}
                      label="Re-enter new password"
                      onBlur={() => {
                        setFieldTouched('confirmNewPassword')
                      }}
                      onChange={(option: { target: { value: string } }) => {
                        setFieldValue('confirmNewPassword', option.target.value)
                      }}
                    />
                  </>
                )}
                {formOption === FORM_OPTIONS.MY_CHANNELS && (
                  <>
                    <Field
                      id="channel"
                      name="channel"
                      component={Select}
                      options={channels}
                      invalid={touched.channel && errors.channel}
                      invalidMessage={errors.channel}
                      label="Current Channel"
                      value={
                        channels
                          ? channels.find(
                              (channel) =>
                                channel.value.toUpperCase() ===
                                values.channel?.toUpperCase()
                            )?.value
                          : ''
                      }
                      onBlur={(option: { target: { value: string } }) => {
                        setFieldValue(
                          'channel',
                          option.target.value.toUpperCase()
                        )
                        setFieldTouched('channel')
                      }}
                      onChange={(option: { target: { value: string } }) => {
                        setFieldValue(
                          'channel',
                          option.target.value.toUpperCase()
                        )
                      }}
                    />
                  </>
                )}
                <S.DialogActions>
                  <S.ModalButton
                    colorOption="stroke"
                    label="CANCEL"
                    onClick={close}
                  ></S.ModalButton>
                  <S.ModalButton
                    colorOption="black"
                    label="SAVE"
                    onClick={() => {
                      validateForm().then((errors) => {
                        setTouched(setNestedObjectValues(errors, true))
                      })
                      submitForm()
                    }}
                  ></S.ModalButton>
                </S.DialogActions>
              </S.DialogForm>
            )}
          </Formik>
        </S.DialogContent>
      </S.ProfileDialog>
    </>
  )
}

export default MyAccount
