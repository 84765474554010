"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DealerType = void 0;
var DealerType;
(function (DealerType) {
    DealerType["AUTHORIZED_DEALER"] = "authorized_dealer";
    DealerType["PREFERRED_DEALER"] = "preferred_dealer";
    DealerType["TOP_EVENT_DEALER"] = "top_event_dealer";
    DealerType["HEB_DEALER"] = "heb_dealer";
})(DealerType = exports.DealerType || (exports.DealerType = {}));
