import { CartHelper, EventHelper } from '@monorepo/infra'
import {
  CartEvent,
  CartItems,
  CartProduct,
  Payment,
} from '@monorepo/interfaces'
import * as S from '../styles'
import { ProductItem } from './productItem'

interface SectionCheckoutConfirmationProductsProps {
  items: CartItems | null
  payments: Payment[]
  isUsingCoop: boolean
}

export const SectionCheckoutConfirmationProducts: React.FC<SectionCheckoutConfirmationProductsProps> =
  ({ items, payments, isUsingCoop }) => {
    const subtotal = CartHelper.getSubtotal(items as CartItems)
    const coopSubtotal = payments
      .map((payment) => {
        return payment.type === 2 ? parseFloat(payment.amount) : 0
      })
      .reduce((a, b) => a + b, 0)
    const cardSubtotal = payments
      .map((payment) => {
        return payment.type === 1 ? parseFloat(payment.amount) : 0
      })
      .reduce((a, b) => a + b, 0)
    let total = 0

    const getTotal = () => {
      total = subtotal - coopSubtotal - cardSubtotal
      return total.toFixed(2)
    }

    if (items === null) return null
    return (
      <S.Container>
        <S.ProductList>
          {items.products.map((item: CartProduct, index: number) => {
            return <ProductItem item={item} key={`product-item-${index}`} />
          })}
          {items.events.map((item: CartEvent, index: number) => {
            return (
              <S.ProductItem key={`event-item-${index}`}>
                <S.ThumbnailContainer>
                  <S.Thumbnail
                    src={`${process.env.PUBLIC_URL}${EventHelper.getImageFile(
                      item.event.categories[0]
                    )}`}
                    alt={item.event.title}
                  />
                </S.ThumbnailContainer>
                <S.ProductDetail>
                  <S.ProductRow>
                    <S.ProductTitle>{item.event.title}</S.ProductTitle>
                    <S.Text alignSelf="flex-start">${item.event.price}</S.Text>
                  </S.ProductRow>
                  <S.Text color={'#9fa8b3'}>
                    {EventHelper.getLocation(item.event)}
                  </S.Text>
                </S.ProductDetail>
              </S.ProductItem>
            )
          })}
        </S.ProductList>
        <S.Line />
        <S.ProductRow>
          <S.ProductTitle>Subtotal</S.ProductTitle>
          <S.Text alignSelf="flex-start">
            ${subtotal.toFixed(2).toString()}
          </S.Text>
        </S.ProductRow>
        {isUsingCoop && (
          <S.ProductRow>
            <S.ProductTitle>Co-Op dollars applied</S.ProductTitle>
            <S.Text alignSelf="flex-start">
              ${coopSubtotal.toFixed(2).toString()}
            </S.Text>
          </S.ProductRow>
        )}
        <S.ProductRow>
          <S.ProductTitle>Paid on Card</S.ProductTitle>
          <S.Text alignSelf="flex-start">
            ${cardSubtotal.toFixed(2).toString()}
          </S.Text>
        </S.ProductRow>
        <S.Line />
        <S.ProductRow>
          <S.TotalTitle>Total Outstanding</S.TotalTitle>
          <S.TotalNumber>${getTotal()}</S.TotalNumber>
        </S.ProductRow>
      </S.Container>
    )
  }
