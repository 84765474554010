import { Spinner } from '@monorepo/components'
import { OrderHelper, useChannel, roundCurrency } from '@monorepo/infra'
import { OrderItem } from '@monorepo/interfaces'
import React from 'react'
import * as S from '../styles'

interface SectionCheckoutTotalReviewProps {
  items: OrderItem[]
  isUsingCoop: boolean
  loadingCoop: boolean
  coopSubtotal: number
  coopRequest: boolean
  canRequestCoop: boolean
  onReturn: () => void
  onContinue: (sendCoopRequest: boolean) => void
}

export const SectionCheckoutTotalReview: React.FC<SectionCheckoutTotalReviewProps> =
  ({
    items,
    isUsingCoop,
    loadingCoop,
    coopSubtotal,
    coopRequest = false,
    canRequestCoop,
    onReturn,
    onContinue,
  }) => {
    const { hasCoop, coop } = useChannel()
    const subtotal = OrderHelper.getItemsSubtotal(items)
    const difference = roundCurrency(subtotal - coopSubtotal)
    const coopPercentage = roundCurrency((coopSubtotal / subtotal) * 100)

    return (
      <S.Container>
        <S.ProductRow>
          <S.ProductTitle>Total</S.ProductTitle>
          <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
        </S.ProductRow>
        {isUsingCoop &&
        (hasCoop || coopRequest || coopSubtotal > 0) &&
        coop?.availableBalance ? (
          <>
            <S.ProductRow>
              <S.ProductTitle>Total Co-Op %</S.ProductTitle>
              {loadingCoop && isUsingCoop ? (
                <Spinner spinnerSize={4} />
              ) : (
                <S.Text alignSelf="flex-start">
                  {coopPercentage.toFixed(2)}%
                </S.Text>
              )}
            </S.ProductRow>
            <S.ProductRow>
              <S.ProductTitle>Total Co-Op Amount</S.ProductTitle>
              {loadingCoop && isUsingCoop ? (
                <Spinner spinnerSize={4} />
              ) : (
                <S.Text alignSelf="flex-start">
                  ${coopSubtotal.toFixed(2)}
                </S.Text>
              )}
            </S.ProductRow>
          </>
        ) : null}
        <S.ProductRow>
          <S.ProductTitle>Total to be Charged to Credit Card</S.ProductTitle>
          {isUsingCoop &&
          (hasCoop || coopRequest || coopSubtotal > 0) &&
          coop?.availableBalance ? (
            !loadingCoop ? (
              <S.Text alignSelf="flex-start">${difference.toFixed(2)}</S.Text>
            ) : (
              <Spinner spinnerSize={4} />
            )
          ) : (
            <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
          )}
        </S.ProductRow>
        <S.ProductRow style={{ gap: '15px' }}>
          {hasCoop && (
            <S.Button
              onClick={() => onReturn()}
              isSecondary={true}
              disabled={loadingCoop && isUsingCoop}
            >
              Back
            </S.Button>
          )}
          {canRequestCoop ? (
            <S.Button
              onClick={() => onContinue(true)}
              disabled={loadingCoop && isUsingCoop}
            >
              Send Co-Op Request
            </S.Button>
          ) : (
            <S.Button
              onClick={() => onContinue(false)}
              disabled={loadingCoop && isUsingCoop}
            >
              Continue
            </S.Button>
          )}
        </S.ProductRow>
      </S.Container>
    )
  }
