"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImpersonatedUserContextController = void 0;
var react_1 = require("react");
var useImpersonatedUserContextController = function () {
    var _a = (0, react_1.useState)(), impersonatedUser = _a[0], setImpersonatedUser = _a[1];
    var setState = (0, react_1.useCallback)(function (name) {
        setImpersonatedUser(name);
    }, [setImpersonatedUser]);
    return { name: impersonatedUser, setState: setState };
};
exports.useImpersonatedUserContextController = useImpersonatedUserContextController;
