import { Button as ButtonComponent } from '@monorepo/components'
import { Drawer as MuiDrawer } from '@material-ui/core'
import styled from 'styled-components'
import { Dialog as ReachDialog } from '@reach/dialog'
import { colors } from '@monorepo/theme'

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px;
`

export const Container = styled.section`
  position: relative;
`

export const Header = styled.header`
  width: 100%;
  padding: 34px;
  padding-top: 108px;
  border-bottom: 1px solid #d9dbdd;
`

export const Title = styled.h1`
  font-size: 36px;
  font-weight: 500;
`

export const BackButton = styled.button`
  border: unset;
  background: unset;
  cursor: pointer;
  font-size: 18px;
  font-family: PFDINText;
  padding: unset;
  margin: auto 0;
  position: relative;
  top: 1px;
`
export const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 14px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  gap: 8px;
`

export const OrderTitle = styled.h1`
  padding-bottom: 28px;
  font-size: 20px;

  > strong {
    font-weight: 500;
  }
`

export const InfoTitle = styled.h1`
  color: #707d8d;
  font-size: 12px;
`

export const Text = styled.p`
  font-size: 15px;

  > strong {
    font-weight: 500;
  }
`

export const OrderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SubtotalSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%
  border-top: 1px solid #d9dbdd;
  border-bottom: 1px solid #d9dbdd;
  padding: 32px 0;
  margin: 16px 0;
  gap: 40px;
`

export const TotalSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Order = styled.div`
  flex: 45%;
`

export const Actions = styled.div`
  flex: 55%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > button {
    margin-left: auto;
    min-width: 250px;
    place-content: center;
  }
`
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  background: #f4f6f8;
  padding: 50px;
`

export const Image = styled.img`
  width: 130px;
  height: 130px;
  object-fit: contain;
`

export const Info = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  gap: 12px;
`

export const InfoCardTitle = styled.h1`
  font-size: 20px;
  font-weight: 500;
`

export const InfoCardPrice = styled.h2`
  font-size: 20px;
  font-weight: 500;
`

export const Type = styled.h3`
  font-size: 18px;
`

export const OrderItemsContainer = styled.div`
  border-top: 1px solid #9fa8b3;
  margin-top: 32px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const SubtotalItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InfoSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-left: 2px solid #fff;
  padding-left: 40px;
  width: 360px;
`

export const ActionTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
`

export const ActionDescription = styled.h1`
  font-size: 16px;
`

export const HistoryContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30vw;
  min-width: 400px;
  height: 100%;
  padding: 55px 55px;
  background: #f4f6f8;
  box-shadow: 0px 0px 50px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const HistorySection = styled.div`
  display: flex;
  flex-direction: column;
`

export const HistorySectionTitle = styled.div`
  height: 40px;
`

export const HistoryWrapper = styled.div`
  border-left: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const HistoryItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  position: relative;
`
export const HistoryStepContainer = styled.div`
  width: 40px;
  height: 40px;
  background: #f4f6f8;
  position: absolute;
  left: -13px;
  top: -13px;
  display: flex;
  user-select: none;
`

export const HistoryStep = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid black;
  padding: 11px;
  margin: auto;

  span {
    position: relative;
    top: -3px;
    font-size: 15px;
    color: #707d8d;
  }

  ::after {
    content: '';
  }
`

export const HistoryInformation = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

export const HistoryText = styled.p`
  font-size: 15px;
  color: #8394a1;

  strong {
    font-weight: 500;
    color: black;
  }
`

export const Button = styled(ButtonComponent)`
  width: 8px;
  height: 8px;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  padding: 12px;
`

export const MessagesDrawer = styled(MuiDrawer)``

export const Dialog = styled(ReachDialog)`
  &[data-reach-dialog-content] {
    background-color: #1d2328;
    position: relative;
    padding: 58px;
  }
`

export const CloseButton = styled.button`
  width: 26px;
  height: 26px;
  border: 0;
  background: #e7e9ec;
  border-radius: 50%;
  position: absolute;
  top: 27px;
  right: 27px;
`

export const DialogContainer = styled.div`
  margin: auto 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`

export const ErrorContainer = styled(InfoContainer)`
  align-self: stretch;
  background-color: ${colors.white};
  padding: 1em;
`

export const Error = styled.p`
  color: ${colors.red};
`

export const TextButton = styled.button<{ isSecondary?: boolean }>`
  background-color: ${(props) => (props.isSecondary ? '#000000' : '#337ace')};
  border: 1px solid ${(props) => (props.isSecondary ? '#000000' : '#337ace')};
  color: #ffffff;
  cursor: pointer;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 16px;
  padding: 17px 30px 17px 30px;

  &:disabled,
  &[disabled] {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`

export const ViewMessagesButton = styled(ButtonComponent)`
  display: inline-block;
`
