"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const S = (0, tslib_1.__importStar)(require("./styles"));
const atoms_1 = require("../../atoms");
const Menu = ({ items }) => {
    return ((0, jsx_runtime_1.jsx)(S.MenuContainer, Object.assign({ "data-testid": "menu" }, { children: (0, jsx_runtime_1.jsx)(S.List, { children: Object.keys(items).map((key, index) => ((0, jsx_runtime_1.jsx)(S.ListItem, { children: (0, jsx_runtime_1.jsx)(atoms_1.Link, Object.assign({ url: items[index].link, color: "white" }, { children: items[index].text }), void 0) }, `${index}-itemList`))) }, void 0) }), void 0));
};
exports.default = Menu;
