import { useEffect, useState } from 'react'
import { useChannel, http, usePersistedState } from '@monorepo/infra'
import { PopUp as PopUpModal } from '@monorepo/components'
import { PopUp } from '@monorepo/interfaces'

const SectionNewUserPopUp = () => {
  const { selectedChannel } = useChannel()
  const [activePopUp, setActivePopUp] = useState<PopUp | null | undefined>()
  const [activePopUps, setActivePopUps] = useState<PopUp[] | null | undefined>()
  const [shownPopUps, setShownPopUps] = useState<string[]>([])
  const [backgroundImage, setBackgroundImage] = useState<string>()
  const [ctaURL, setCTAURL] = useState<string>()
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [popUps, setPopUps] = usePersistedState<{
    [key: string]: { id: string; version: number }[]
  }>('popUps', {})

  useEffect(() => {
    if (!selectedChannel) {
      return
    }

    getPopUps(selectedChannel)
  }, [selectedChannel])

  useEffect(() => {
    if (!Array.isArray(activePopUps) || !activePopUps.length) {
      return
    }

    deleteInactivePopUps()

    const tmpActivePopUp = getFirstActivePopUp()
    if (!tmpActivePopUp) {
      return
    }

    setActivePopUp(tmpActivePopUp)
  }, [activePopUps])

  useEffect(() => {
    if (!activePopUp) {
      return
    }

    getBackgroundImage(activePopUp.backgroundImage)
    getCTAResource(activePopUp.ctaResource)
  }, [activePopUp])

  useEffect(() => {
    if (!activePopUp || !shouldBeShown(activePopUp)) {
      return
    }

    const channel = selectedChannel?.toLowerCase()

    if (!!channel && !!backgroundImage) {
      const tmpPopUps = { ...popUps }
      if (!Array.isArray(tmpPopUps[channel])) {
        tmpPopUps[channel] = []
      }

      const tmpPopUp = tmpPopUps[channel].find(
        (item) => item.id === activePopUp._id
      )
      if (!tmpPopUp) {
        tmpPopUps[channel].push({
          id: activePopUp._id as string,
          version: activePopUp.version || 1,
        })
      } else {
        tmpPopUp.version = activePopUp.version || 1
      }

      setPopUps(tmpPopUps)

      const tmpShownPopUs = [...shownPopUps]
      tmpShownPopUs.push(activePopUp._id as string)
      setShownPopUps(tmpShownPopUs)

      setIsPopUpOpen(true)
    }
  }, [backgroundImage, ctaURL])

  useEffect(() => {
    if (!activePopUp || isPopUpOpen) {
      return
    }

    setBackgroundImage(undefined)
    setCTAURL(undefined)

    if (Array.isArray(activePopUps) && activePopUps.length) {
      setActivePopUp(getFirstActivePopUp())
    }
  }, [isPopUpOpen])

  const getPopUps = (channel?: string) => {
    if (!channel) {
      return
    }
    http
      .get({
        baseURL: process.env.REACT_APP_REST_BASE_URL,
        url: '/popup',
        params: {
          channel: channel.toLowerCase(),
          status: 'active',
          pageNum: 1,
          pageSize: 50,
        },
      })
      .then((response) => {
        const data = (response?.data as { data: PopUp[] })?.data
        if (Array.isArray(data) && data.length) {
          setActivePopUps(data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteInactivePopUps = () => {
    const channel = selectedChannel?.toLowerCase()

    if (
      !channel ||
      !Array.isArray(popUps[channel]) ||
      !popUps[channel].length ||
      !Array.isArray(activePopUps) ||
      !activePopUps.length
    ) {
      return
    }

    const tmpPopUps = { ...popUps }
    const localPopUps = tmpPopUps[channel]

    let deleted = false

    for (let i = localPopUps.length - 1; i >= 0; --i) {
      const idx = activePopUps.findIndex(
        (item) => item._id === localPopUps[i].id
      )
      if (idx === -1) {
        localPopUps.splice(idx, 1)
        deleted = true
      }
    }

    if (deleted) {
      setPopUps(tmpPopUps)
    }
  }

  const getFirstActivePopUp = () => {
    if (!Array.isArray(activePopUps) || !activePopUps.length) {
      return
    }

    let tmpActivePopUp

    for (let i = 0, l = activePopUps.length; i < l; ++i) {
      if (
        shownPopUps.indexOf(activePopUps[i]._id as string) === -1 &&
        shouldBeShown(activePopUps[i])
      ) {
        tmpActivePopUp = activePopUps[i]
        break
      }
    }

    return tmpActivePopUp
  }

  const shouldBeShown = (popUp?: PopUp): boolean => {
    if (!popUp) {
      return false
    }

    const channel = selectedChannel?.toLowerCase()
    const localPopUps = popUps[channel]

    let version = 0

    if (Array.isArray(localPopUps) && localPopUps.length) {
      const localPopUp = localPopUps.find((item) => item.id === popUp._id)
      if (localPopUp) {
        version = localPopUp.version
      }
    }

    return version < (popUp.version || 1)
  }

  const getBackgroundImage = (id?: string) => {
    if (!id) {
      return
    }
    http
      .get({
        url: `${process.env.REACT_APP_MEDIA_URL}/${id}`,
      })
      .then((response) => {
        setBackgroundImage((response?.data as { path: string })?.path)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCTAResource = (id?: string) => {
    if (!id) {
      return
    }
    http
      .get({
        baseURL: process.env.REACT_APP_REST_BASE_URL,
        url: `/resource/${id}`,
      })
      .then((response) => {
        setCTAURL((response?.data as { document: string })?.document)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return activePopUp && !!backgroundImage ? (
    <PopUpModal
      isOpen={isPopUpOpen}
      title={activePopUp?.headline}
      text={activePopUp?.text}
      backgroundImage={backgroundImage}
      ctaText={activePopUp?.ctaText}
      ctaURL={ctaURL as string}
      toggleModal={() => setIsPopUpOpen(false)}
      size="medium"
    />
  ) : null
}

export default SectionNewUserPopUp
