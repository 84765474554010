"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const __1 = require("../..");
const S = (0, tslib_1.__importStar)(require("./styles"));
const SetPermission = ({ permissionTitle, features, }) => {
    return ((0, jsx_runtime_1.jsxs)(S.Container, { children: [(0, jsx_runtime_1.jsx)(__1.Title, { children: permissionTitle }, void 0), (0, jsx_runtime_1.jsx)(S.PermissionsList, { children: features.map((feature, index) => ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(__1.Checkbox, { value: feature.value, name: feature.name, checked: feature.checked }, index) }, index))) }, void 0)] }, void 0));
};
exports.default = SetPermission;
