import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import { Dialog as ReachDialog } from '@reach/dialog'
import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
} from '@material-ui/core'
import '@reach/dialog/styles.css'

export const CoopBarContainer = styled.header`
  display: grid;
  grid-template-areas: '. message coop';
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  min-height: 40px;
  background: ${colors.black};
`
export const GlobalMessage = styled.p`
  color: ${colors.lightGrey};
  grid-area: message;
  margin: auto;
`

export const CoopContainer = styled.div`
  color: ${colors.lightGrey};
  grid-area: coop;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 11px;
  font-family: PFDINText;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`

export const CoopSpacer = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`

export const CoopNoButton = styled.div`
  color: ${colors.lightGrey};
`

export const CoopButton = styled.button`
  border: 0;
  background: transparent;
  color: ${colors.lightGrey};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const Dialog = styled(ReachDialog)`
  &[data-reach-dialog-content] {
    background-color: #1d2328;
    position: relative;
    padding: 58px;
  }
`

export const CloseButton = styled.button`
  width: 26px;
  height: 26px;
  border: 0;
  background: #e7e9ec;
  border-radius: 50%;
  position: absolute;
  top: 27px;
  right: 27px;
`

export const ChannelButton = styled.button`
  border: 0;
  background: #000000;
  color: ${colors.white};
  font-family: PFDINText;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  padding: 20px 17px;
  min-width: 156px;

  &:disabled {
    background-color: #e5e5e5;
  }
`

export const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    overflow-y: hidden;
  }
`

export const Table = styled(MuiTable)`
  background-color: ${colors.white};
`

export const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    padding: 17px 20px;
    border-bottom: 1px solid gray;
  }

  &:last-child {
    text-align: right;
  }
`

export const CurrentText = styled.p`
  font-family: PFDINText;
  font-weight: 400;
  font-size: 18px;
  color: ${colors.white};
  margin: 15px 0;
`

export const CurrentTextCode = styled.span`
  background-color: #405167de;
  padding: 15px;
  margin-right: 10px;
`

export const CurrentTextCodeIdPart = styled.span`
  padding-right: 15px;
`
export const TableTitle = styled.h4`
  font-family: PFDINText;
  font-weight: 700;
  font-size: 22px;
  color: ${colors.white};
  padding: 21px 0 26px 0;
`
