"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryParams = void 0;
var react_router_dom_1 = require("react-router-dom");
var react_1 = require("react");
var useQueryParams = function () {
    var search = (0, react_router_dom_1.useLocation)().search;
    return (0, react_1.useMemo)(function () { return new URLSearchParams(search); }, [search]);
};
exports.useQueryParams = useQueryParams;
