import React from 'react'
import { SectionContactSupport } from '../../organisms'
import { Ticket } from '@monorepo/interfaces'
import { http } from '@monorepo/infra'

const ContactSupportArea: React.FC = () => {
  const onSubmit = (ticket: Ticket) => {
    http
      .post({
        url: `/ticket`,
        data: ticket,
      })
      .then(() =>
        alert(
          'Ticket submitted successfully, you will be hearing from our team!'
        )
      )
  }

  return <SectionContactSupport onSubmit={onSubmit} />
}

export default ContactSupportArea
