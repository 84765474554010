import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import { SectionHomeProps } from '@monorepo/interfaces'

const Wrapper = styled.main`
  width: 100%;
`

const ButtonLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  width: 140.99px;
  height: 49.09px;
  left: 136.28px;
  top: 168px;
  background: #337ace;
  text-transform: uppercase;
`

const HowItWorks = styled.div`
display: flex;
flex-flow: column;
align-items: center;
justify-content: center;
gap: 16px;
`
const HeaderAndTitle = styled.div`
height: 224px;
display: flex;
flex-flow: column;
gap: 24px;
justify-content: center;
max-width: 568.79px;

@media (min-width: 1440px) {
  max-width:632.84px
}
`

const TitleH2 = styled.h2`
font-family: Jubilat;
font-size: 32px;
font-style: italic;
font-weight: 700;
line-height: 30.72px;
text-align: center;
color: ${colors.darkBlue};

@media (min-width: 1440px) {
  font-size: 36px;
  line-height: 34.56px;
}
`

const Subtitle = styled.p`
font-family: PFDINText;
font-size: 16px;
font-weight: 400;
line-height: 22.08px;
text-align: center;
color: ${colors.darkBlue};

@media (min-width: 1440px) {
  font-size: 18px;
  line-height: 24.84px;
}
`

const Steps = styled.div`
  display: flex;
  gap: 40px;
  padding: 40px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline; 
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100%;
  max-width: 280px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    flex-basis: calc(33.333% - 80px);
  }

  @media (min-width: 1024px) {
    max-width: 280px;
  }

  @media (min-width: 1440px) {
    max-width: 416px;
  }

`

const StepTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: ${colors.darkBlue};
  inline-size: max-content;


  @media (min-width: 1440px) {
    font-size: 22px;
    line-height: 30.36px;
  }

  & .stepNumber {
    font-family: Jubilat;
    font-weight: 700;
    text-align: center;
    margin: 0px 8px;
    color: ${colors.blue};
  }
`

const StepContent = styled.p`
  font-size: 16px;
  line-height: 22.08px;
  font-weight: 400;
  text-align: center;
  color: #4B5766;
  padding: 0px 10px;

  @media (min-width: 1440px) {
    font-size: 18px;
    line-height: 24.84px;
  }
`

const Line = styled.hr`
    margin: 10px 0 25px 0;
    border-width: 1px 0px 0px 0px;
    border-top: 1px solid ${colors.darkBlue};
    width: 100%;
`

const CardContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  margin: 72px;
  align-items: start;

  @media (min-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(2, 1fr)
  }
`


const MediaCard = styled.section<SectionHomeProps>`
  border-radius: 5px 0px 0px 0px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),
  rgba(0, 0, 0, 0.5)), url(${(props) => props.bgImage || 'none'});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  height: 260px;
  background-position-y: 40%;

   & > div {
    background: linear-gradient(180deg, rgba(16, 38, 65, 0.5) 0%, rgba(16, 38, 65, 0) 56.22%);
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
   
  & > p {
    margin: 10px;
    color: white;
    font-family: PFDINText;
    line-height: 21.6px;
    font-size: 21.6px;
    font-weight: 500;
    letter-spacing: -0.02em;
    text-align: left;
    
    @media (min-width: 1440px) {
      font-size: 32px;
      line-height: 32px;
    }

    @media (min-width: 1920px) {
      font-size: 36.6px;
      line-height: 36.6px;
    }
  }
}
  
  @media (min-width: 1440px) {
    height: 360px;
  }
  
  @media (min-width: 1920px) {
    height: 411.75px;
  }
`
const CustomLink = styled(Link)`

`

export {
  Wrapper,
  ButtonLink,
  HowItWorks,
  Step,
  StepContent,
  StepTitle,
  Steps,
  Subtitle,
  TitleH2,
  Line,
  HeaderAndTitle,
  CardContainer,
  MediaCard,
  CustomLink,
}
