import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`

export const PageArrows = styled.div`
  width: 60px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PageInformation = styled.div`
  width: max-content;
  display: flex;
  text-align: center;
  align-items: center;
  height: 100%;
  margin-right: 15px;
  font-family: PFDINText;
  font-size: 14px;
`

interface PageButtonProps {
  left?: boolean
  right?: boolean
}

export const PageButton = styled.button<PageButtonProps>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
  outline: unset;
  cursor: pointer;

  padding: 0
    ${({ left, right }) => (left ? '2.5px 0 0' : right ? '0 0 2.5px' : 0)};

  &:hover:not(:disabled) {
    background-color: ${colors.lightGrey};
  }
`
