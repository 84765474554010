"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const S = (0, tslib_1.__importStar)(require("./styles"));
const atoms_1 = require("../../atoms");
const react_1 = require("react");
const TextInput = ({ type = 'input', label, className, onChange, }) => {
    const isSimpleInput = (0, react_1.useMemo)(() => type === 'input', [type]);
    return ((0, jsx_runtime_1.jsxs)(S.Container, Object.assign({ className: className }, { children: [label && ((0, jsx_runtime_1.jsx)(atoms_1.Label, { "data-testid": "text_input_label", label: label, labelStyle: "input-label" }, void 0)), isSimpleInput ? ((0, jsx_runtime_1.jsx)(atoms_1.Input, { id: label, label: label, onChange: onChange }, void 0)) : ((0, jsx_runtime_1.jsx)(atoms_1.TextArea, { placeholder: label, onChange: onChange }, void 0))] }), void 0));
};
exports.default = TextInput;
