import { Button } from '@monorepo/components'
import Dialog from '@reach/dialog'
import { Form } from 'formik'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  gap: 34px;
  width: 100%;
  padding: 34px;
  display: grid;
`
type CardProps = {
  gridRow: string
  gridColumn: string
}
export const Card = styled.div<CardProps>`
  gap: 36px;
  width: 100%;
  padding: 50px;
  display: flex;
  background: #f4f6f8;
  border-radius: 18px;
  flex-direction: column;
  grid-row: ${({ gridRow }) => gridRow};
  grid-column: ${({ gridColumn }) => gridColumn};
`
export const CardTitle = styled.h1`
  font-size: 20px;
  font-weight: 500;
`

export const CardLabel = styled.label`
  color: #9fa8b3;
`
export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const CardButton = styled.button`
  font-family: 'PFDINText';
  border: unset;
  padding: 0;
  width: fit-content;
  background: unset;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #000;
  letter-spacing: -0.2px;
  cursor: pointer;
`

export const CardItem = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 36px;

  > div {
    padding: 12px 0;
  }
`

export const DialogActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`
export const ModalButton = styled(Button)`
  min-width: 106px;
  place-content: center;
`

export const ProfileDialog = styled(Dialog)`
  background: #f5f5f5;
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const DialogTitle = styled.h1`
  font-size: 20px;
`

export const DialogForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
