"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.itemsReducer = void 0;
var tslib_1 = require("tslib");
var actions_1 = require("./actions");
var itemsReducer = function (selectedItems, action) {
    switch (action.type) {
        case actions_1.ActionType.SetItem:
            return (0, tslib_1.__assign)((0, tslib_1.__assign)({}, selectedItems), { items: checkArray(selectedItems.items, action.payload.item) });
        case actions_1.ActionType.ClearItems:
            return (0, tslib_1.__assign)((0, tslib_1.__assign)({}, selectedItems), { items: Array() });
        default:
            return selectedItems;
    }
};
exports.itemsReducer = itemsReducer;
var checkArray = function (state, item) {
    var arrayCopy = (0, tslib_1.__spreadArray)([], state, true);
    var indexOfItem = arrayCopy.indexOf(item);
    if (indexOfItem !== -1)
        arrayCopy.splice(indexOfItem, 1);
    else
        arrayCopy.push(item);
    return arrayCopy;
};
