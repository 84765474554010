import { IPayment } from '@monorepo/interfaces'
import React from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import * as S from '../styles'

interface CheckoutPaymentEditProps {
  payment: IPayment
  hideSameAsShippingCheckbox: boolean
  onSubmit: (payment: IPayment) => void
  onCheckCopyShipping: () => void
}

const PaymentSchema = Yup.object().shape({
  billingAddress: Yup.object().shape({
    firstName: Yup.string().required('Billing First Name is required'),
    lastName: Yup.string().required('Billing Last Name is required'),
    address1: Yup.string().required('Billing Address Line 1 is required'),
    address2: Yup.string().optional(),
    city: Yup.string().required('Billing City is required'),
    state: Yup.string().max(2).required('Billing State is required'),
    zipCode: Yup.string().max(10).required('Billing Zip Code is required'),
  }),
  cardNumber: Yup.string().required('Card Number is required'),
  expirationMonth: Yup.string().required('Expiration Month is required'),
  expirationYear: Yup.string().required('Expiration Year is required'),
  CCV: Yup.string().required('CCV is required'),
})

export const SectionCheckoutPaymentEdit: React.FC<CheckoutPaymentEditProps> = ({
  payment,
  hideSameAsShippingCheckbox,
  onSubmit,
  onCheckCopyShipping,
}) => {
  const yearRange = () => {
    const start = new Date().getFullYear()
    const stop = start + 10
    const step = 1
    return Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={payment}
        validationSchema={PaymentSchema}
        onSubmit={(values, actions) => {
          onSubmit({
            billingAddress: values.billingAddress,
            cardNumber: values.cardNumber,
            expirationMonth: values.expirationMonth,
            expirationYear: values.expirationYear,
            CCV: values.CCV,
          })
          actions.setSubmitting(false)
          actions.resetForm()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <S.Container>
              <S.Title>Payment Information</S.Title>
              {hideSameAsShippingCheckbox && <S.CheckboxLabel htmlFor="sameAsShipping">
                <S.Checkbox
                  name="sameAsShipping"
                  onChange={() => onCheckCopyShipping()}
                />
                Same as Shipping
              </S.CheckboxLabel>}
              <S.Input
                id="billingAddress.firstName"
                name="billingAddress.firstName"
                type="text"
                placeholder="Billing First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingAddress.firstName}
              />
              {touched.billingAddress?.firstName &&
                errors.billingAddress?.firstName && (
                  <S.Text color="red">{errors.billingAddress.firstName}</S.Text>
                )}
              <S.Input
                id="billingAddress.lastName"
                name="billingAddress.lastName"
                type="text"
                placeholder="Billing Last Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingAddress.lastName}
              />
              {touched.billingAddress?.lastName &&
                errors.billingAddress?.lastName && (
                  <S.Text color="red">{errors.billingAddress.lastName}</S.Text>
                )}
              <S.Input
                id="billingAddress.address1"
                name="billingAddress.address1"
                type="text"
                placeholder="Billing Address Line 1"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingAddress.address1}
              />
              {touched.billingAddress?.address1 &&
                errors.billingAddress?.address1 && (
                  <S.Text color="red">{errors.billingAddress.address1}</S.Text>
                )}
              <S.Input
                id="billingAddress.address2"
                name="address2"
                type="text"
                placeholder="Billing Address Line 2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingAddress.address2}
              />
              {touched.billingAddress?.address2 &&
                errors.billingAddress?.address2 && (
                  <S.Text color="red">{errors.billingAddress.address2}</S.Text>
                )}
              <S.Input
                id="billingAddress.city"
                name="billingAddress.city"
                type="text"
                placeholder="Billing City"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingAddress.city}
              />
              {touched.billingAddress?.city && errors.billingAddress?.city && (
                <S.Text color="red">{errors.billingAddress.city}</S.Text>
              )}
              <S.Select
                name="billingAddress.state"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingAddress.state}
                required
              >
                <option value="" disabled selected>
                  Billing State
                </option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </S.Select>
              {touched.billingAddress?.state &&
                errors.billingAddress?.state && (
                  <S.Text color="red">{errors.billingAddress.state}</S.Text>
                )}
              <S.Input
                id="billingAddress.zipCode"
                name="billingAddress.zipCode"
                type="text"
                placeholder="Billing Zip Code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingAddress.zipCode}
              />
              {touched.billingAddress?.zipCode &&
                errors.billingAddress?.zipCode && (
                  <S.Text color="red">{errors.billingAddress?.zipCode}</S.Text>
                )}
              <S.CreditCard
                id="cardNumber"
                name="cardNumber"
                placeholder="Card Number"
                options={{ creditCard: true }}
                onChange={(e) => {
                  setFieldValue('cardNumber', e.target.rawValue)
                }}
                onBlur={handleBlur}
                value={values.cardNumber}
              />
              {touched.cardNumber && errors.cardNumber && (
                <S.Text color="red">{errors.cardNumber}</S.Text>
              )}
              <S.Select
                id="expirationMonth"
                name="expirationMonth"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.expirationMonth}
                required
              >
                <option value="" disabled selected>
                  Exp. Month
                </option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </S.Select>
              {touched.expirationMonth && errors.expirationMonth && (
                <S.Text color="red">{errors.expirationMonth}</S.Text>
              )}
              <S.Select
                id="expirationYear"
                name="expirationYear"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.expirationYear}
                required
              >
                <option value="" disabled selected>
                  Exp. Year
                </option>
                {yearRange().map((year) => (
                  <option value={year.toString()}>{year.toString()}</option>
                ))}
              </S.Select>
              {touched.expirationYear && errors.expirationYear && (
                <S.Text color="red">{errors.expirationYear}</S.Text>
              )}
              <S.Input
                id="CCV"
                name="CCV"
                placeholder="CCV"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.CCV}
              />
              {touched.CCV && errors.CCV && (
                <S.Text color="red">{errors.CCV}</S.Text>
              )}
              <S.Button type="submit">Continue to Review</S.Button>
            </S.Container>
          </Form>
        )}
      </Formik>
    </>
  )
}
