"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDealerOptions = exports.dealerTypes = void 0;
exports.dealerTypes = [
    // { value: 'authorized_dealer', label: 'Authorized Dealer' },
    { value: 'preferred_dealer', label: 'Preferred' },
    { value: 'top_event_dealer', label: 'Top Event' },
    { value: 'heb_dealer', label: 'HEB' },
];
var getDealerOptions = function () { return [
    // { id: 'authorized_dealer', value: 'Authorized Dealer' },
    { id: 'preferred_dealer', value: 'Preferred' },
    { id: 'top_event_dealer', value: 'Top Event' },
    { id: 'heb_dealer', value: 'HEB' },
]; };
exports.getDealerOptions = getDealerOptions;
