import * as S from './styles'

import { CartItems } from '@monorepo/interfaces'
import {
  CartHelper,
  ChannelHelper,
  EventHelper,
  ProductHelper,
  useCartContext,
  useChannel,
  formatCurrency,
  roundCurrency,
} from '@monorepo/infra'
import { useCallback, useEffect, useState } from 'react'

import { Icon } from '@monorepo/components'
import { useHistory } from 'react-router-dom'
import { Spinner } from '@monorepo/components'

interface SectionCartListProps {
  onClose: () => void
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const SectionCartList: React.FC<SectionCartListProps> = ({ onClose }) => {
  const history = useHistory()
  const { items, removeProduct, removeEvent } = useCartContext()
  const { hasCoop, selectedMasterDealerId, selectedChannel, coop } =
    useChannel()

  const handleRemoveProduct = (index: number) => {
    removeProduct(index)
    if (items.products.length + items.events.length - 1 === 0) onClose()
  }

  const handleRemoveEvent = (index: number) => {
    removeEvent(index)
    if (items.products.length + items.events.length - 1 === 0) onClose()
  }

  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [itemIndex, setItemIndex] = useState<number>(0)
  const [itemIsEvent, setItemIsEvent] = useState<boolean>(false)
  const [coopUsedTotalForCart, setCoopUsedTotalForCart] = useState<number>(0)
  const [loadingCoopAmountsForCart, setLoadingCoopAmountsForCart] =
    useState(false)
  const [cartItemsWithCoop, setCartItemsWithCoop] = useState<CartItems>(items)
  const openModal = useCallback((index: number, isEvent: boolean) => {
    setItemIsEvent(isEvent)
    setItemIndex(index)
    setShowRemoveModal((show) => !show)
  }, [])

  const closeModal = useCallback(() => {
    setShowRemoveModal((show) => !show)
  }, [])

  useEffect(() => {
    let isCartItemsChanged = false
    if (!CartHelper.isCartItemsEqual(items, cartItemsWithCoop)) {
      isCartItemsChanged = true
      setCartItemsWithCoop(items)
    }

    if (!hasCoop || !coop?.availableBalance || CartHelper.isEmpty(items)) {
      setCoopUsedTotalForCart(0)
      return
    }

    if (!isCartItemsChanged) {
      return
    }

    setLoadingCoopAmountsForCart(true)
    CartHelper.getCoopTotalForCartV2(
      ChannelHelper.getMasterDealerId(selectedMasterDealerId, selectedChannel),
      items
    )
      .then((cartItemsWithCoop) => {
        setCartItemsWithCoop(cartItemsWithCoop)
        setCoopUsedTotalForCart(
          CartHelper.getCoopUsedTotalForCart(cartItemsWithCoop)
        )
      })
      .catch(() => {
        setCoopUsedTotalForCart(0)
      })
      .finally(() => {
        setLoadingCoopAmountsForCart(false)
      })
  }, [items, hasCoop, selectedMasterDealerId, selectedChannel])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAmountAfterCoopText = (item: any) => {
    return `$${formatCurrency(
      Math.round(CartHelper.getCartItemTotalAfterCoop(item) * 100).toString()
    )}`
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCoopUsedTotalForCartText = () => {
    return coopUsedTotalForCart
      ? `$${formatCurrency(Math.round(coopUsedTotalForCart * 100).toString())}`
      : undefined
  }

  return (
    <>
      <S.Container>
        <S.IconButton onClick={onClose}>
          <Icon icon="Close" size={20} color="#405167" />
        </S.IconButton>
        <S.Title>1 Item Added to Cart</S.Title>
        <S.Line />
        <S.ProductList>
          {cartItemsWithCoop.products.map((item, index) => {
            const {
              product,
              selectedVariantIndex,
              selectedDeliveryMethodIndex,
              selectedPrintAndShipIndex,
              notes,
              quantity,
            } = item

            return (
              <S.ProductListItem key={`cart-product-${index}`}>
                <S.ItemImageContainer>
                  <S.ItemImage
                    src={product.variants[selectedVariantIndex].media[0]}
                  />
                </S.ItemImageContainer>
                <S.ItemInfoContainer>
                  <S.ItemTitle>{item.product.title}</S.ItemTitle>
                  {hasCoop && coop?.availableBalance ? (
                    !loadingCoopAmountsForCart ? (
                      CartHelper.getCartItemCoopUsed(item) > 0 && (
                        <S.ItemCoopInfo>Eligible Co-Op %</S.ItemCoopInfo>
                      )
                    ) : (
                      <Spinner spinnerSize={4} />
                    )
                  ) : undefined}
                  {hasCoop && coop?.availableBalance ? (
                    !loadingCoopAmountsForCart ? (
                      CartHelper.getCartItemCoopUsed(item) > 0 && (
                        <S.ItemCoopInfo>Amount after Co-Op</S.ItemCoopInfo>
                      )
                    ) : (
                      <Spinner spinnerSize={4} />
                    )
                  ) : undefined}
                  <S.ItemInfo>
                    {ProductHelper.getDeliveryMethod(
                      product.deliveryMethods[selectedDeliveryMethodIndex - 1],
                      product.variants[selectedVariantIndex]
                        .printAndShipOptions[selectedPrintAndShipIndex]
                        ?.pieceCount
                    )}
                  </S.ItemInfo>
                  <S.ItemInfo>
                    {ProductHelper.getVariantTitle(
                      product.variants[selectedVariantIndex]
                    )}
                  </S.ItemInfo>
                  {notes && notes !== '' && (
                    <S.ItemInfo>Notes: {notes}</S.ItemInfo>
                  )}
                  <S.ItemInfo>Quantity:{quantity}</S.ItemInfo>
                  {(item.dealerName ||
                    item.dealerAddress ||
                    item.dealerAddress2 ||
                    item.dealerPhone) && (
                    <>
                      {item.dealerName && (
                        <S.ItemInfo>{`Dealer Name: ${item.dealerName}`}</S.ItemInfo>
                      )}
                      {item.dealerAddress && (
                        <S.ItemInfo>{`Dealer Address: ${item.dealerAddress}`}</S.ItemInfo>
                      )}
                      {item.dealerAddress2 && (
                        <S.ItemInfo>{`Dealer Address 2: ${item.dealerAddress2}`}</S.ItemInfo>
                      )}
                      {item.dealerPhone && (
                        <S.ItemInfo>{`Dealer Phone: ${item.dealerPhone}`}</S.ItemInfo>
                      )}
                    </>
                  )}
                </S.ItemInfoContainer>
                <S.ItemPriceContainer>
                  <S.ItemPrice>
                    {formatter.format(CartHelper.getProductPrice(item))}
                  </S.ItemPrice>
                  {hasCoop && coop?.availableBalance
                    ? !loadingCoopAmountsForCart &&
                      CartHelper.getCartItemCoopUsed(item) > 0 && (
                        <S.ItemCoopPercentage>
                          {CartHelper.getCartItemCoopUsedPercentageText(item)}
                        </S.ItemCoopPercentage>
                      )
                    : undefined}
                  {hasCoop && coop?.availableBalance
                    ? !loadingCoopAmountsForCart &&
                      CartHelper.getCartItemCoopUsed(item) > 0 && (
                        <S.ItemAmountAfterCoop>
                          {getAmountAfterCoopText(item)}
                        </S.ItemAmountAfterCoop>
                      )
                    : undefined}
                </S.ItemPriceContainer>
                <S.TextButton onClick={() => openModal(index, false)}>
                  Remove
                </S.TextButton>
              </S.ProductListItem>
            )
          })}
          {cartItemsWithCoop.events.map((item, index) => {
            const { event, notes } = item
            return (
              <S.ProductListItem key={`cart-event-${index}`}>
                <S.ItemImageContainer>
                  <S.ItemImage
                    src={EventHelper.getImageFile(item.event.categories[0])}
                  />
                </S.ItemImageContainer>
                <S.ItemInfoContainer>
                  <S.ItemTitle>{event.title}</S.ItemTitle>
                  {hasCoop && coop?.availableBalance ? (
                    !loadingCoopAmountsForCart ? (
                      CartHelper.getCartItemCoopUsed(item) > 0 && (
                        <S.ItemCoopInfo>Eligible Co-Op %</S.ItemCoopInfo>
                      )
                    ) : (
                      <Spinner spinnerSize={4} />
                    )
                  ) : undefined}
                  {hasCoop && coop?.availableBalance ? (
                    !loadingCoopAmountsForCart ? (
                      CartHelper.getCartItemCoopUsed(item) > 0 && (
                        <S.ItemCoopInfo>Amount after Co-Op</S.ItemCoopInfo>
                      )
                    ) : (
                      <Spinner spinnerSize={4} />
                    )
                  ) : undefined}
                  <S.ItemInfo>{event.categories[0]}</S.ItemInfo>
                  {notes && notes !== '' && (
                    <S.ItemInfo>Notes: {notes}</S.ItemInfo>
                  )}
                </S.ItemInfoContainer>
                <S.ItemPriceContainer>
                  <S.ItemPrice>
                    {formatter.format(CartHelper.getEventPrice(item))}
                  </S.ItemPrice>
                  {hasCoop && coop?.availableBalance
                    ? !loadingCoopAmountsForCart &&
                      CartHelper.getCartItemCoopUsed(item) > 0 && (
                        <S.ItemCoopPercentage>
                          {CartHelper.getCartItemCoopUsedPercentageText(item)}
                        </S.ItemCoopPercentage>
                      )
                    : undefined}
                  {hasCoop && coop?.availableBalance
                    ? !loadingCoopAmountsForCart &&
                      CartHelper.getCartItemCoopUsed(item) > 0 && (
                        <S.ItemAmountAfterCoop>
                          {getAmountAfterCoopText(item)}
                        </S.ItemAmountAfterCoop>
                      )
                    : undefined}
                </S.ItemPriceContainer>
                <S.TextButton onClick={() => openModal(index, true)}>
                  Remove
                </S.TextButton>
              </S.ProductListItem>
            )
          })}
        </S.ProductList>
        <S.Line />
        <S.Row>
          <S.SubtotalInfo>SUBTOTAL</S.SubtotalInfo>
          <S.SubtotalPrice>
            {formatter.format(CartHelper.getSubtotal(items))}
          </S.SubtotalPrice>
        </S.Row>
        {hasCoop && coop?.availableBalance ? (
          !loadingCoopAmountsForCart ? (
            coopUsedTotalForCart > 0 && (
              <S.Row>
                <S.SubtotalInfo>
                  Eligible Co-Op calculated at checkout
                </S.SubtotalInfo>
                <S.SubtotalPrice>
                  {getCoopUsedTotalForCartText()}
                </S.SubtotalPrice>
              </S.Row>
            )
          ) : (
            <S.Row>
              <Spinner spinnerSize={4} />
            </S.Row>
          )
        ) : undefined}
        <S.Line />
        {hasCoop && coop?.availableBalance ? (
          !loadingCoopAmountsForCart ? (
            <S.Row>
              <S.TotalInfo>TOTAL</S.TotalInfo>
              <S.SubtotalPrice>
                {formatter.format(
                  roundCurrency(
                    CartHelper.getSubtotal(items) - coopUsedTotalForCart
                  )
                )}
              </S.SubtotalPrice>
            </S.Row>
          ) : (
            <S.Row>
              <Spinner spinnerSize={4} />
            </S.Row>
          )
        ) : (
          <S.Row>
            <S.TotalInfo>TOTAL</S.TotalInfo>
            <S.SubtotalPrice>
              {formatter.format(CartHelper.getSubtotal(items))}
            </S.SubtotalPrice>
          </S.Row>
        )}
        <S.ButtonsContainer>
          <S.CartButton onClick={() => history.push('/cart')}>
            {`View Cart (${items.products.length + items.events.length})`}
          </S.CartButton>
          <S.CheckoutButton
            colorOption="blue"
            label="CHECKOUT"
            onClick={() => history.push('/checkout')}
          />
        </S.ButtonsContainer>
        <S.Dialog
          isOpen={showRemoveModal}
          onDismiss={closeModal}
          aria-label="The removing confirmation dialog"
        >
          <S.Container>
            <S.ItemTitle>Are you sure?</S.ItemTitle>
            <S.ButtonsContainer>
              <S.DialogButton
                label="Yes"
                colorOption="delicateGrey"
                onClick={() => {
                  itemIsEvent
                    ? handleRemoveEvent(itemIndex)
                    : handleRemoveProduct(itemIndex)
                  closeModal()
                }}
              >
                Checkout
              </S.DialogButton>
              <S.DialogButton
                label="No"
                colorOption="blue"
                onClick={() => closeModal()}
              >
                Checkout
              </S.DialogButton>
            </S.ButtonsContainer>
          </S.Container>
          <S.CloseModalButton
            colorOption="delicateGrey"
            icon="Close"
            borderRadius
            size="large"
            onClick={closeModal}
          />
        </S.Dialog>
      </S.Container>
    </>
  )
}

export default SectionCartList
