import styled from 'styled-components'
import { colors } from '@monorepo/theme'

export const Container = styled.div`
  margin: 128px 100px 128px 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const PageTitle = styled.h1`
  font-family: PFDINText;
  font-weight: 700;
  font-size: 36px;
  text-transform: uppercase;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 55%;
  gap: 20px;
  margin: 0 104px 0 0;
`

export const Error = styled.p`
  color: ${colors.red};
`

export const ProductsContainer = styled.div`
  display: flex;
  flex-basis: 45%;
  background-color: #f6f8f9;
  padding: 60px;
`

export const Button = styled.button`
  background-color: #337ace;
  border: 1px solid #337ace;
  color: #ffffff;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 16px;
  padding: 17px 30px 17px 30px;
  align-self: flex-start;
`
