import * as Yup from 'yup'

import {
  Button,
  Container,
  ErrorItem,
  MainContainer,
  Row,
  TextInput,
  TextInputArea,
  Title,
  Wrapper,
} from './styles'
import { Form, Formik } from 'formik'

import React from 'react'
import { useTranslation } from 'react-i18next'

const TicketSchema = Yup.object().shape({
  eventName: Yup.string().required('Event name is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  dealershipName: Yup.string().required('Dealership name is required'),
  contactName: Yup.string().required('Contact name is required'),
  contactInfo: Yup.string().required('Contact info is required'),
  message: Yup.string().required('Message is required'),
})

interface SectionCustomEventProps {
  onSubmit: (subject: string, message: string) => void
}

const SectionCustomEvent: React.FC<SectionCustomEventProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation('customEvent')

  return (
    <>
      <MainContainer>
        <Container>
          <Wrapper>
            <Formik
              initialValues={{
                eventName: '',
                eventDates: '',
                message: '',
                city: '',
                state: '',
                dealershipName: '',
                contactName: '',
                contactInfo: '',
              }}
              validationSchema={TicketSchema}
              onSubmit={(values, actions) => {
                const subject = `Custom Event: ${values.eventName}`
                let message = ''
                if (values.eventDates)
                  message += `Event Dates: ${values.eventDates}\n\n`
                if (values.city && values.state)
                  message += `Event Location: ${values.city}, ${values.state}\n\n`
                if (values.contactName)
                  message += `Contact Name: ${values.contactName}\n\n`
                if (values.contactInfo)
                  message += `Contact Info: ${values.contactInfo}\n\n`
                if (values.dealershipName)
                  message += `Dealership Name: ${values.dealershipName}\n\n`
                message += values.message
                onSubmit && onSubmit(subject, message)
                actions.setSubmitting(false)
                actions.resetForm()
              }}
            >
              {({ values, touched, errors, setFieldValue }) => (
                <Form>
                  <Title as="h1"> {t('contact_label')}</Title>
                  <Row marginBetween={18}>
                    <TextInput
                      id="eventName"
                      name="eventName"
                      type="text"
                      placeholder="Event Name"
                      value={values.eventName}
                    />
                    <TextInput
                      id="eventDates"
                      name="eventDates"
                      type="text"
                      placeholder="Event Dates"
                      value={values.eventDates}
                    />
                  </Row>
                  <Row marginBetween={18}>
                    <TextInput
                      id="city"
                      name="city"
                      type="text"
                      placeholder="Event Location (City)"
                      value={values.city}
                    />
                    <TextInput
                      id="state"
                      name="state"
                      type="text"
                      placeholder="Event Location (State)"
                      value={values.state}
                    />
                  </Row>
                  <Row marginBetween={18}>
                    <TextInput
                      id="dealershipName"
                      name="dealershipName"
                      type="text"
                      placeholder="Dealership Name"
                      value={values.dealershipName}
                    />
                    <TextInput
                      id="contactName"
                      name="contactName"
                      type="text"
                      placeholder="Contact Name"
                      value={values.contactName}
                    />
                  </Row>
                  <Row marginBetween={18}>
                    <TextInput
                      id="contactInfo"
                      name="contactInfo"
                      type="text"
                      placeholder="Contact Info"
                      value={values.contactInfo}
                    />
                  </Row>
                  <Row>
                    <TextInputArea
                      name="message"
                      id="message"
                      placeholder="Additional Comments"
                      onChange={(option: { target: { value: string } }) => {
                        setFieldValue('message', option.target.value)
                      }}
                      value={values.message}
                    />
                  </Row>
                  <Row>
                    {touched.eventName && errors.eventName && (
                      <ErrorItem>{errors.eventName}</ErrorItem>
                    )}
                  </Row>
                  <Row>
                    {touched.message && errors.message && (
                      <ErrorItem>{errors.message}</ErrorItem>
                    )}
                  </Row>
                  <Row>
                    {touched.city && errors.city && (
                      <ErrorItem>{errors.city}</ErrorItem>
                    )}
                  </Row>
                  <Row>
                    {touched.state && errors.state && (
                      <ErrorItem>{errors.state}</ErrorItem>
                    )}
                  </Row>
                  <Row>
                    {touched.contactInfo && errors.contactInfo && (
                      <ErrorItem>{errors.contactInfo}</ErrorItem>
                    )}
                  </Row>
                  <Row>
                    {touched.contactName && errors.contactName && (
                      <ErrorItem>{errors.contactName}</ErrorItem>
                    )}
                  </Row>
                  <Row>
                    {touched.dealershipName && errors.dealershipName && (
                      <ErrorItem>{errors.dealershipName}</ErrorItem>
                    )}
                  </Row>

                  <Row>
                    <Button type="submit">Send Request</Button>
                  </Row>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </Container>
      </MainContainer>
    </>
  )
}

export default SectionCustomEvent
