export var ProductStatus;
(function (ProductStatus) {
    ProductStatus[ProductStatus["ACTIVE"] = 1] = "ACTIVE";
    ProductStatus[ProductStatus["DRAFT"] = 2] = "DRAFT";
    ProductStatus[ProductStatus["DELETED"] = 3] = "DELETED";
    ProductStatus[ProductStatus["HIDDEN"] = 8] = "HIDDEN";
})(ProductStatus || (ProductStatus = {}));
export var DeliveryMethod;
(function (DeliveryMethod) {
    DeliveryMethod[DeliveryMethod["PRINT"] = 1] = "PRINT";
    DeliveryMethod[DeliveryMethod["DOWNLOAD"] = 2] = "DOWNLOAD";
    DeliveryMethod[DeliveryMethod["CUSTOM_QUOTE"] = 3] = "CUSTOM_QUOTE";
    DeliveryMethod[DeliveryMethod["PLACE_WITH_AGENCY"] = 4] = "PLACE_WITH_AGENCY";
})(DeliveryMethod || (DeliveryMethod = {}));
export var ProductType;
(function (ProductType) {
    ProductType[ProductType["CREATIVE"] = 1] = "CREATIVE";
    ProductType[ProductType["CREATIVE_CUSTOM"] = 2] = "CREATIVE_CUSTOM";
    ProductType[ProductType["EVENT"] = 3] = "EVENT";
    ProductType[ProductType["MICROSITE"] = 4] = "MICROSITE";
    ProductType[ProductType["ALL"] = 5] = "ALL";
})(ProductType || (ProductType = {}));
export var ProductVariantSize;
(function (ProductVariantSize) {
    ProductVariantSize[ProductVariantSize["FSI"] = 1] = "FSI";
    ProductVariantSize[ProductVariantSize["FLYER"] = 2] = "FLYER";
    ProductVariantSize[ProductVariantSize["HALF_PAGE"] = 3] = "HALF_PAGE";
    ProductVariantSize[ProductVariantSize["DOORHANGER"] = 4] = "DOORHANGER";
    ProductVariantSize[ProductVariantSize["POSTCARD"] = 5] = "POSTCARD";
    ProductVariantSize[ProductVariantSize["POSTER"] = 6] = "POSTER";
    ProductVariantSize[ProductVariantSize["SANDWICH_BOARD"] = 7] = "SANDWICH_BOARD";
    ProductVariantSize[ProductVariantSize["BILL_INSERT"] = 8] = "BILL_INSERT";
    ProductVariantSize[ProductVariantSize["YARD_SIGN"] = 9] = "YARD_SIGN";
    ProductVariantSize[ProductVariantSize["BANNER_ADS"] = 10] = "BANNER_ADS";
    ProductVariantSize[ProductVariantSize["SOCIAL_POSTS"] = 11] = "SOCIAL_POSTS";
    ProductVariantSize[ProductVariantSize["TRI_FOLD_BROCHURE"] = 12] = "TRI_FOLD_BROCHURE";
    ProductVariantSize[ProductVariantSize["BUSINESS_CARD"] = 13] = "BUSINESS_CARD";
    ProductVariantSize[ProductVariantSize["NAME_BADGES"] = 14] = "NAME_BADGES";
    ProductVariantSize[ProductVariantSize["MULTI_PAGE_4PG"] = 15] = "MULTI_PAGE_4PG";
    ProductVariantSize[ProductVariantSize["MULTI_PAGE_8PG"] = 16] = "MULTI_PAGE_8PG";
    ProductVariantSize[ProductVariantSize["MULTI_PAGE_16PG"] = 17] = "MULTI_PAGE_16PG";
    ProductVariantSize[ProductVariantSize["CUSTOM"] = 18] = "CUSTOM";
})(ProductVariantSize || (ProductVariantSize = {}));
