"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const S = (0, tslib_1.__importStar)(require("./styles"));
const RadioButton = (_a) => {
    var { disabled = false, value = null, checked = null, name = '' } = _a, props = (0, tslib_1.__rest)(_a, ["disabled", "value", "checked", "name"]);
    return ((0, jsx_runtime_1.jsxs)(S.StyledLabel, Object.assign({ disabled: disabled }, { children: [(0, jsx_runtime_1.jsx)("input", { type: "radio", name: name, value: value, checked: checked, disabled: disabled, onChange: props.onChange }, void 0), props.label] }), void 0));
};
exports.default = RadioButton;
