import { colors } from '@monorepo/theme'
import styled from 'styled-components'
import { SearchProps } from './index'
import { Icon } from '@monorepo/components'

export const SearchContainer = styled.div<SearchProps>`
display: flex;
flex-direction: row;
gap: 20px;
align-items: center;
border: none;
border-bottom: ${({ borderBottom, color }) =>
borderBottom ? 0 : `1px solid ${color || colors.grey}`};
`

export const SearchInput = styled.input<SearchProps>`
  width: ${({ width }) => width ?? 170}px;
  height: ${({ height }) => height ?? 30}px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? colors.lighterGrey};
  color: ${({ color }) => color ?? colors.white};
  padding: 0 15px 0 0;
  font-size: 16px;
  font-family:PFDINText;
  font-weight:400;
  border: none;

  &:focus-visible {
    outline: none;
  }
  
  &:placeholder {
    color: ${({ color }) => color ?? colors.white};
  }
`

export const StyledIcon = styled(Icon)`
margin-left: 15px;
`
