"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const infra_1 = require("@monorepo/infra");
const S = (0, tslib_1.__importStar)(require("./styles"));
const getTableRow = (key, value) => ((0, jsx_runtime_1.jsxs)(S.ProcessEnvTRow, { children: [(0, jsx_runtime_1.jsx)(S.ProcessEnvTCell, { children: key }, void 0), (0, jsx_runtime_1.jsx)(S.ProcessEnvTCell, { children: value }, void 0)] }, void 0));
const getTable = (env) => {
    const keys = (0, infra_1.isObject)(env) ? Object.keys(env) : undefined;
    return Array.isArray(keys) && keys.length > 0 ? ((0, jsx_runtime_1.jsx)(S.ProcessEnvTable, { children: (0, jsx_runtime_1.jsx)(S.ProcessEnvTBody, { children: keys.map((key) => getTableRow(key, env[key])) }, void 0) }, void 0)) : undefined;
};
const ProcessEnv = () => {
    return (0, jsx_runtime_1.jsx)(S.ProcessEnvContainer, { children: getTable(process.env) }, void 0);
};
exports.default = ProcessEnv;
