import { Order, OrderAddress } from '@monorepo/interfaces'
import React from 'react'
import { format } from 'date-fns'
import * as S from '../styles'

interface SectionCheckoutConfirmationProps {
  orderSent: Order | null
  shippingAddress: OrderAddress
  isCoopRequest: boolean
}

export const SectionCheckoutConfirmation: React.FC<SectionCheckoutConfirmationProps> =
  ({ orderSent, shippingAddress, isCoopRequest }) => {
    if (orderSent === null) return null
    return (
      <S.Container>
        <S.ConfirmationTitle>Confirmation</S.ConfirmationTitle>
        <S.ConfirmationOrderNumber>
          Order #{orderSent.number}
        </S.ConfirmationOrderNumber>
        <S.ConfirmationThankYou>
          Thank You
          {shippingAddress.firstName && shippingAddress.firstName !== ''
            ? `, ${shippingAddress.firstName}`
            : ''}
          !
        </S.ConfirmationThankYou>
        {isCoopRequest? (<S.ConfirmationMessage>Your Co-Op request has been submitted and your order is saved.</S.ConfirmationMessage>)
        :(<S.ConfirmationMessage>Your order is confirmed!</S.ConfirmationMessage>)}
        <S.ConfirmationRow>
          <S.ConfirmationRowItem>
            <S.ConfirmationMessage>CONTACT INFORMATION</S.ConfirmationMessage>
            <S.ConfirmationMessage>
              {orderSent.customer.user}
            </S.ConfirmationMessage>
          </S.ConfirmationRowItem>
          <S.ConfirmationRowItem>
          {shippingAddress.address1 && shippingAddress.address1 !== '' ?
           ( <>
           <S.ConfirmationMessage>SHIPPING ADDRESS</S.ConfirmationMessage>
            <S.ConfirmationMessage>
              {shippingAddress.address1 && shippingAddress.address1 !== ''
                ? `${shippingAddress.firstName} ${shippingAddress.lastName}\n${shippingAddress.address1}\n${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zipCode}`
                :  'None'}
            </S.ConfirmationMessage>
           </>):shippingAddress.contactName && shippingAddress.contactName !==''? 
            (<S.ConfirmationMessage>
              {`${shippingAddress.contactName}\n${shippingAddress.email}\n${shippingAddress.phoneNumber}`}
            </S.ConfirmationMessage>)
            :('')}
          </S.ConfirmationRowItem>
        </S.ConfirmationRow>
        <S.ConfirmationHistoryList>
          {orderSent.history.map((history, index) => (
            <S.ConfirmationHistoryItem key={index}>
              {`${history.description} on ${format(
                new Date(history.date),
                'MM/dd/yyyy'
              )}`}
            </S.ConfirmationHistoryItem>
          ))}
        </S.ConfirmationHistoryList>
      </S.Container>
    )
  }
