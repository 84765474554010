import {
  AppInsightsErrorBoundary,
  AppInsightsProvider,
  CartProvider,
  ChannelProvider,
  initializeApollo,
  keycloakClient,
  reactPlugin,
  useApollo,
  ImpersonatedUserContextProvider,
  KeycloakHelper,
} from '@monorepo/infra'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import { global as GlobalStyle } from '@monorepo/theme'
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Registration from './pages/registration'
import { Routes } from './routes'

const App: React.FC = () => {
  const apolloClient = initializeApollo()
  const client = useApollo(apolloClient.cache.extract())
  const [impersonatedUser, setImpersonatedUser] = useState()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeycloakOnEvent = (eventType: any, error: any) => {
    if (eventType !== 'onTokenExpired') {
      return
    }

    const tokenExchangeParsed =
      KeycloakHelper.getTokenExchangeParsed(keycloakClient)

    if (tokenExchangeParsed) {
      setImpersonatedUser(tokenExchangeParsed.email)
      KeycloakHelper.clearTokenExchange(keycloakClient)
    } else if (!!impersonatedUser) {
      setImpersonatedUser(undefined)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeycloakOnTokens = async (tokens: any) => {
    if (!tokens || !impersonatedUser) {
      return
    }

    await KeycloakHelper.impersonate(keycloakClient, impersonatedUser, tokens.token)

    setImpersonatedUser(undefined)
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloakClient}
      onEvent={handleKeycloakOnEvent}
      onTokens={handleKeycloakOnTokens}
    >
      <BrowserRouter>
        <AppInsightsProvider>
          <AppInsightsErrorBoundary
            onError={() => <h1>Hello world</h1>}
            appInsights={reactPlugin}
          >
            <ApolloProvider client={client}>
              <GlobalStyle />
              <CartProvider>
                <ChannelProvider>
                  <ImpersonatedUserContextProvider>
                    <Switch>
                      <Route
                        exact
                        path="/registration"
                        component={Registration}
                      />
                      <Routes />
                    </Switch>
                  </ImpersonatedUserContextProvider>
                </ChannelProvider>
              </CartProvider>
            </ApolloProvider>
          </AppInsightsErrorBoundary>
        </AppInsightsProvider>
      </BrowserRouter>
    </ReactKeycloakProvider>
  )
}

export default App
