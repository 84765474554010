import * as S from './styles'

import { Carousel, Icon } from '@monorepo/components'
import React, { useEffect, useState } from 'react'

import { Product } from '@monorepo/interfaces'
import { colors } from '@monorepo/theme'
import { http, useChannel } from '@monorepo/infra'

type SectionPopularProps = {
  detailsPage?: boolean
  featuredTitle: string
  marginBottom?: string
}

interface CarouselProduct {
  title: string
  images: string[]
  link: string
  isNew: boolean
  subtitle: string
  aditionalInfo: string
  showInfo?: boolean
}

const SectionPopular: React.FC<SectionPopularProps> = ({
  detailsPage,
  featuredTitle,
  marginBottom = '135px',
}) => {
  const maxFeaturedProducts = 4
  const [products, setProducts] = useState<CarouselProduct[]>([])
  const { selectedChannel } = useChannel()
  useEffect(() => {
    async function fetchProducts() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = await http.get<any>({
        url: `/product?channel=${selectedChannel}&pageNum=1&pageSize=${maxFeaturedProducts}&isStorefront=true&status=active&isFeatured=true`,
      })
      setProducts(
        response.data.data.map((product: Product) => {
          const carouselProduct: CarouselProduct = {
            title: product.title,
            subtitle: '',
            aditionalInfo: '',
            images: product.variants[0].media,
            link: product._id as string,
            isNew: false,
          }
          return carouselProduct
        })
      )
    }
    fetchProducts()
  }, [selectedChannel])
  return (
    <S.Section marginBottom={marginBottom}>
      <S.Featured>
        <S.FeaturedTitleContainer>
          {detailsPage ? (
            <S.FeaturedTitleDetails>You may also like</S.FeaturedTitleDetails>
          ) : (
            <S.FeaturedTitle>{featuredTitle}</S.FeaturedTitle>
          )}
        </S.FeaturedTitleContainer>
      </S.Featured>
      <Carousel products={products} paddingBottom="25px" />
    </S.Section>
  )
}

export default SectionPopular
