/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import '@reach/checkbox/styles.css'

import * as S from './styles'

import { Icon } from '@monorepo/components'
import { Menu } from '@reach/menu-button'
import { useEffect, useState } from 'react'
import { colors } from '@monorepo/theme'

export interface CategoryItem {
  id: string
  name: string
  count: number
  isSelected?: boolean
}

interface SelectFilterProps {
  name: string
  items: CategoryItem[]
  initialItems: CategoryItem[]
  selectItem?: (list: string[]) => void
  clearable?: boolean
}

const SelectFilter: React.FC<SelectFilterProps> = ({
  name,
  items,
  initialItems,
  selectItem = () => {},
  clearable = false,
}) => {
  const [selectedItems, setSelectedItems] = useState<any>(
    items.map((item) => {
      if (initialItems.some((initialItem) => initialItem.name === item.name)) {
        return { ...item, isSelected: true }
      }
      return { ...item, isSelected: false }
    })
  )

  const setCategory = (name: string) => {
    const itemIndex = items.findIndex((item) => item.name === name)

    const newSelectedItems = selectedItems.reduce(
      (acc: any, item: { isSelected: boolean }, index: number) => {
        if (index === itemIndex) {
          return [
            ...acc,
            {
              ...item,
              isSelected: !item.isSelected,
            },
          ]
        }

        return [...acc, item]
      },
      []
    )

    setSelectedItems(newSelectedItems)
  }

  useEffect(() => {
    if (items) {
      const newItems = items.map((item) => {
        if (
          initialItems.some((initialItem) => initialItem.name === item.name)
        ) {
          return { ...item, isSelected: true }
        }
        return { ...item, isSelected: false }
      })

      setSelectedItems(newItems)
    }
  }, [initialItems, items])

  useEffect(() => {
    if (selectedItems) {
      const list = selectedItems.reduce(
        (acc: any, item: { isSelected: any; name: any }, index: number) => {
          if (!item.isSelected) {
            return [...acc]
          }

          return [...acc, item.name]
        },
        []
      )
      selectItem(list)
    }
  }, [selectItem, selectedItems])

  const handleClearFilter = () => {
    if (
      !Array.isArray(selectedItems) ||
      !selectedItems.some((item: CategoryItem) => item.isSelected)
    ) {
      return
    }

    selectedItems.forEach((item) => {
      item.isSelected = false
    })

    setSelectedItems([...selectedItems])
    selectItem([])
  }

  return (
    <Menu>
      <S.Button>
        {name} <Icon icon="ArrowDown" hasStroke={false} size={24} color={colors.darkBlue} />
      </S.Button>
      <S.List>
        {clearable && (
          <div>
            <S.ClearFilterOption onClick={handleClearFilter}>
              Clear filter
            </S.ClearFilterOption>
          </div>
        )}
        {items.map((item, index) => {
          return (
            <div key={index}>
              <label>
                <input
                  type="checkbox"
                  name={`${name}-filter`}
                  value={item.name}
                  checked={
                    selectedItems.find(
                      (selectedItem: CategoryItem) =>
                        selectedItem.name === item.name
                    ).isSelected
                  }
                  onChange={(e) => setCategory(e.target.value)}
                />
                {item.name}
              </label>
              <span>{item.count || null}</span>
            </div>
          )
        })}
      </S.List>
    </Menu>
  )
}

export { SelectFilter }
