"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkoutMachine = void 0;
var xstate_1 = require("xstate");
exports.checkoutMachine = 
/** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOhwHtzYwBJfXAF13QBsBiRUABysd3PycQAD0QBGMQAYAbCUkAmAMzLp8iYvkBOADQgAnonnTFJTWc3SA7AA4rkgCxKAvk91oseQqUzkA8lwBRFlwoXAAjXGCGPTYAWV8ANQCAFV9U5IBBABkAJQCEmgCAdSEeWD4BIVEECRk5JRU1MQ0dfUQLe1Nze0sjSUlFSxc3DBwCYhIGcgZWHLAAN1wwAHc4xJTfAIARGmSAZQAJGgAFY5oAOQBxUt4mSqQRREsxaxIbazMxTR75STEAVl0BgQ1ksmhI9nMll6mksiisYmGIHcYy8JFgeC4XAIUDWSVS212xwyAE1YgFzskbuU7oIHtU4Z1rNZ5NY-hplF95EDEP9-iZIeYjNJ+QN7EiUZ4JhjcFicWw8skAKo5Sm+ADCvl8x2pFTpoGqtVkCmU8KaLR5CEs-1kln6fwsWgcqgloylpC46D0qDA+AYeI2eQKxV1tKqiEUHxIGmsYnkds0Nvjlnslv+LwhYmk2fs0lBucUiNcyLd4w9Xp9foVKRVasJ+yOpwu1weZT14atGfen2+vT+gLaCGk9kkEKh-1+Yjh31dHjLJE93t9-sVtdSmu1of4+sende3c0Xx+-bTkn+Y-MZ+kFmtQ2LkvnACcFktVvF8RrfOcAGI0HKxDJkhoL8t3uA0I0UUdh2seFNAcVktEtON+gvCwPjEexrBtRRZ1RCZn0WFZq2VVV1y1HVW1ubcO2UeQ3g+M9YTPFlNGsJCATo-57X+exmjtTC7xGOc0QI19iLXTYdgbE4ziuUCd2qDRLHouD-iY-4WLYwdrXBcxYVUSE+Q0XD3RIUSiNXUjJKJUlyUpeSaNZEgjD5UUuIkVRLWMTpeJzLNFFc2wTLLNhNWOElDhk5sHPpRBr3BZ4ek0Qsr2HAdgVYrozBZflhwC1jgq8Ng9hSA4Mj2DcKO4KiwN3fiSBgjTE2ShEwSQ+RCuIAMyJ-P8AKAkDKJpajYqHSwkMjZS9KdWFkocTqiG6zYchyXwchi8Cxva4wsusEdI0g+Q+RcYt8HICA4CEB80QoKhaHoJhWA23dHQhQZ7EwyCbC48bB0LcFBVhGD7HTNlBJLYSJh8fwghCcJIkYYFquG2rDWUV5WtUflc0wy0Uwx8dVEkWx-gWyZplmF8Vme6obVeCdlH+Z4rGkK9LVY2RTRS2wAp6awyZlOV8CgGn2meZy7SMOMjH07ltPTEgvjMa0idsTCycXSsGFFhAmYSyRoXkSEFEsYwvLUN5BRTAFFE0I7pDJ8zlh1r4BTUUFjCYrCvnYmxFezNSZF4xMszJnx8AAM1wR8MDDIb21Gm1ZHsd7WXxkVmQmuESGzEV0ydPtweuiYwEfR9yEfHXaJUxi7Q0rQtOBOMNIvZlYOFSMFp1lOkLZOR7WJuEpxZIsXCAA */
(0, xstate_1.createMachine)({
    context: {
        canRequestCoop: false,
        isFreeOrder: false,
        hasCoop: true,
        isCoopRequest: false,
        coopAmount: 0,
        isUsingCoop: false,
        shippingAddress: {
            firstName: '',
            lastName: '',
            address1: '',
            city: '',
            state: '',
            zipCode: '',
            contactName: '',
            email: '',
            phoneNumber: '',
            asmName: '',
            asmEmail: '',
        },
        billingAddress: {
            firstName: '',
            lastName: '',
            address1: '',
            city: '',
            state: '',
            zipCode: '',
        },
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        CCV: '',
    },
    id: '(machine)',
    initial: 'chooseInitial',
    on: {
        COPYSHIPPING: {
            actions: 'copyShipping',
        },
        SETHASCOOP: {
            actions: 'setHasCoop',
        },
        MOVETOCONFIRMATION: {
            target: '.confirmation',
        },
        MOVETOERROR: {
            target: '.error',
        },
    },
    states: {
        chooseInitial: {
            always: [
                {
                    cond: 'isFree',
                    target: 'shipping',
                },
                {
                    cond: 'canSeeCoop',
                    target: 'coOpEligibility',
                },
                {
                    target: 'shipping',
                },
            ],
        },
        coOpEligibility: {
            on: {
                MOVETOTOTALREVIEW: {
                    actions: ['setUsingCoop', 'setCoopAmount', 'setCoopRequest'],
                    target: 'totalReview',
                },
            },
        },
        totalReview: {
            on: {
                MOVETOEDITSHIPPING: {
                    target: 'shipping',
                },
                RETURNTOCOOP: {
                    target: 'coOpEligibility',
                },
            },
        },
        shipping: {
            on: {
                MOVETOEDITPAYMENT: {
                    actions: 'setShipping',
                    target: 'payment',
                },
                RETURNTOCOOP: {
                    target: 'coOpEligibility',
                },
                SAVEORDER: {
                    actions: 'saveOrder',
                    target: 'review',
                },
            },
        },
        payment: {
            on: {
                MOVETOREVIEW: {
                    actions: ['setBillingAddress', 'setPayment'],
                    target: 'review',
                },
                RETURNTOEDITSHIPPING: {
                    target: 'shipping',
                },
                RETURNTOCOOP: {
                    target: 'coOpEligibility',
                },
            },
        },
        review: {
            on: {
                MOVETOCONFIRMATION: {
                    target: 'confirmation',
                },
                RETURNTOCOOP: {
                    target: 'coOpEligibility',
                },
                RETURNTOEDITSHIPPING: {
                    target: 'shipping',
                },
                RETURNTOEDITPAYMENT: {
                    target: 'payment',
                },
            },
        },
        confirmation: {},
        error: {
            on: {
                RETURNTOEDITPAYMENT: {
                    target: 'payment',
                },
            },
        },
    },
}, {
    guards: {
        canSeeCoop: function (context) { return context.hasCoop || context.canRequestCoop; },
        isFree: function (context) { return context.isFreeOrder; },
    },
    actions: {
        setHasCoop: (0, xstate_1.assign)({
            hasCoop: function (_context, event) {
                if (event.type !== 'SETHASCOOP')
                    return;
                return event.hasCoop || event.canRequestCoop;
            },
        }),
        setUsingCoop: (0, xstate_1.assign)({
            isUsingCoop: function (_context, event) {
                if (event.type !== 'MOVETOTOTALREVIEW')
                    return;
                return event.isUsingCoop;
            },
        }),
        setCoopRequest: (0, xstate_1.assign)({
            isCoopRequest: function (_context, event) {
                if (event.type !== 'MOVETOTOTALREVIEW')
                    return;
                return event.isCoopRequest;
            },
        }),
        setCoopAmount: (0, xstate_1.assign)({
            coopAmount: function (_context, event) {
                if (event.type !== 'MOVETOTOTALREVIEW')
                    return;
                return event.coopAmount;
            },
        }),
        setShipping: (0, xstate_1.assign)({
            shippingAddress: function (_context, event) {
                if (event.type !== 'MOVETOEDITPAYMENT')
                    return;
                return event.shippingAddress;
            },
        }),
        saveOrder: (0, xstate_1.assign)({
            shippingAddress: function (_context, event) {
                if (event.type !== 'SAVEORDER')
                    return;
                return event.shippingAddress;
            },
        }),
        setBillingAddress: (0, xstate_1.assign)({
            billingAddress: function (_context, event) {
                if (event.type !== 'MOVETOREVIEW')
                    return;
                return event.payment.billingAddress;
            },
        }),
        setPayment: (0, xstate_1.assign)(function (_context, event) {
            if (event.type !== 'MOVETOREVIEW')
                return;
            return {
                cardNumber: event.payment.cardNumber,
                expirationMonth: event.payment.expirationMonth,
                expirationYear: event.payment.expirationYear,
                CCV: event.payment.CCV,
            };
        }),
        copyShipping: (0, xstate_1.assign)({
            billingAddress: function (context, event) {
                if (event.type !== 'COPYSHIPPING')
                    return;
                return context.shippingAddress;
            },
        }),
    },
});
