import * as S from './styles'
import { Spinner } from '@monorepo/components'
import { Button, CardEvent } from '@monorepo/components'
import { Event, Ticket } from '@monorepo/interfaces'
import { EventHelper, KeycloakHelper, http } from '@monorepo/infra'
import React, { useCallback, useState } from 'react'

import SectionCustomEvent from '../SectionCustomEvent'
import { format } from 'date-fns'
import { useKeycloak } from '@react-keycloak/web'

interface EventListProps {
  events: Event[],
  loading: boolean | undefined
}

function getDayName(dateStr: Date) {
  return format(new Date(dateStr), 'EEEE MMMM d, yyyy')
}

const EventList: React.FC<EventListProps> = ({ events, loading = false }) => {
  const [showDialog, setShowDialog] = useState(false)
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)

  const open = () => {
    setShowDialog(true)
  }

  const close = () => {
    setShowDialog(false)
  }

  const onSubmitTicket = useCallback(
    async (subject: string, message: string) => {
      const newTicket: Ticket = {
        number: 0,
        subject,
        priority: 1,
        status: 1,
        tags: [],
        messages: [
          {
            message,
            createdBy: user?.email,
            createdOn: new Date().toUTCString(),
          },
        ],
        createdBy: user?.email,
        createdOn: new Date().toUTCString(),
      }
      http
        .post({
          url: `/ticket`,
          data: newTicket,
        })
        .then(() => {
          close()
          alert(
            'Ticket submitted successfully, you will be hearing from our team!'
          )
        })
    },
    [user?.email]
  )

  const getDialog = () => {
    return (
      <S.Dialog
        aria-label="Build Your Event"
        isOpen={showDialog}
        onDismiss={close}
      >
        <S.CloseButton className="close-button" onClick={close}>
          x
        </S.CloseButton>
        <S.DialogContainer>
          <SectionCustomEvent onSubmit={onSubmitTicket} />
        </S.DialogContainer>
      </S.Dialog>
    )
  }

  return (
    <>
      <S.ProductList>
        {getDialog()}
        <S.PromotionalArea>
          <S.Container>
            <S.Title>
              Build your own event
            </S.Title>
            <S.Content>
              Don't see your event listed here, get in touch and we can set up
              the details.
            </S.Content>
            <Button colorOption="blue"
              label="GET STARTED"
              iconColor={"white"}
              icon={"Pen"}
              size={"small"}
              iconPosition={"left"}
              onClick={open} />
          </S.Container>
        </S.PromotionalArea>
        {events.length > 0 &&
            (events.map((ev: Event, index: number) => (
              <CardEvent
                key={index}
                link={ev._id || ''}
                image={EventHelper.getImageFile(EventHelper.getEventTypeDescription(ev.type))}
                title={ev.title}
                isNew={false}
                subtitle={getDayName(ev.startDate as Date)}
                aditionalInfo={`${ev.city}, ${ev.state}`}
                startDate={ev?.startDate}
                endDate={ev?.endDate}
              />
            )))}
      </S.ProductList>
    </>
  )
}

export default EventList
