import styled from 'styled-components'

import { TextInput as MTextInput } from '@monorepo/components'

export const Container = styled.main`
  background-color: #e5e5e5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 1064px;
  height: 765px;
`

export const Wrapper = styled.section`
  & h1 {
    font-size: 42px;
  }
`

interface RowProps {
  marginBetween?: number
}

export const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;

  margin: 15px 0;
  > * {
    &:first-child {
      margin-right: ${({ marginBetween }) => marginBetween ?? '16'}px;
    }
  }
`

export const Form = styled.form`
  margin-top: 25px;
`

export const ButtonWrapper = styled.button`
  background-color: transparent;
  border: none;

  position: absolute;
  top: 25px;
  right: 21px;

  cursor: pointer;
`

export const TextInput = styled(MTextInput)`
  flex: 1;
  max-width: 416px;
`
