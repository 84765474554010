import * as S from './styles'

import { Link } from 'react-router-dom'
import React from 'react'

const Footer: React.FC = () => {
  return (
    <S.StyledFooter>
      <S.Container>
        <S.FooterRow>
          <S.FooterLogo src="/images/directv-logo.svg" alt="DIRECTV Logo" />
          <S.FooterList>
            <S.FooterListItem>
              <Link to={`/terms`}>Terms & Conditions</Link>
            </S.FooterListItem>
            <S.FooterListItem>
              <Link to={`/contact`}>Help</Link>
            </S.FooterListItem>
          </S.FooterList>
        </S.FooterRow>
        <S.FooterLine />
        <S.FooterCopyright className="footer__copyright">
          &copy;{new Date().getFullYear()} DIRECTV. DIRECTV and all other
          DIRECTV marks are trademarks of DIRECTV, LLC. All other marks are the
          property of their respective owners.
        </S.FooterCopyright>
      </S.Container>
    </S.StyledFooter>
  )
}

export default Footer
