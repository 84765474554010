"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_1 = require("react-router");
const core_1 = require("@material-ui/core");
const icons_1 = require("@material-ui/icons");
const __1 = require("..");
const infra_1 = require("@monorepo/infra");
const S = (0, tslib_1.__importStar)(require("./styles"));
const ChangeAddresses = ({ title, userId, addressesList, dispatchList, onSubmit, className, }) => {
    const history = (0, react_router_1.useHistory)();
    const [isEditing, setIsEditing] = (0, react_1.useState)(false);
    const [currentAddress, setCurrentAddress] = (0, react_1.useState)({
        city: '',
        address: '',
        zipCode: '',
        state: '',
    });
    const [newAddress, setNewAddress] = (0, react_1.useState)({
        city: '',
        address: '',
        zipCode: '',
        state: '',
    });
    const [currentAddressIndex, setCurrentAddressIndex] = (0, react_1.useState)(0);
    const handleEditAddressButton = (address, index) => {
        setCurrentAddress(address);
        setCurrentAddressIndex(index);
        setIsEditing(true);
    };
    const handleDeleteAddressButton = (index) => {
        dispatchList({
            type: infra_1.ActionAdressType.DeleteAddress,
            payload: { addressIndex: index },
        });
    };
    const handleToggleDefaultAddress = (index) => {
        dispatchList({
            type: infra_1.ActionAdressType.ToggleDefaultAddressByIndex,
            payload: { index: index },
        });
    };
    const handleCancelButton = () => {
        if (!isEditing)
            history.goBack();
        setCurrentAddress({ city: '', address: '', zipCode: '', state: '' });
        setNewAddress({ city: '', address: '', zipCode: '', state: '' });
        setIsEditing(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (title.toLowerCase() === 'billing address') {
            if (!isEditing) {
                dispatchList({
                    type: infra_1.ActionAdressType.AddAddress,
                    payload: { address: newAddress },
                });
            }
            else {
                dispatchList({
                    type: infra_1.ActionAdressType.ReplaceAddress,
                    payload: { index: currentAddressIndex, newValue: currentAddress },
                });
            }
            onSubmit({
                _id: userId,
                billingAddresses: addressesList,
            }, 'billing');
        }
        else {
            if (!isEditing) {
                dispatchList({
                    type: infra_1.ActionAdressType.AddAddress,
                    payload: { address: newAddress },
                });
            }
            else {
                dispatchList({
                    type: infra_1.ActionAdressType.ReplaceAddress,
                    payload: { index: currentAddressIndex, newValue: currentAddress },
                });
            }
            onSubmit({
                _id: userId,
                shippingAddresses: addressesList,
            }, 'shipping');
        }
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(S.Form, Object.assign({ onSubmit: handleSubmit, className: className }, { children: (0, jsx_runtime_1.jsxs)(core_1.Grid, Object.assign({ container: true, spacing: 2 }, { children: [(0, jsx_runtime_1.jsx)(__1.Address, { title: `${isEditing ? 'Edit' : 'Add new'} ${title}`, address: isEditing ? currentAddress : newAddress, addressIndex: isEditing ? currentAddressIndex : -1, changeAddress: false, dispatchAddress: dispatchList, setCurrentAddress: setCurrentAddress, setNewAddress: setNewAddress }, void 0), (0, jsx_runtime_1.jsx)(core_1.Grid, Object.assign({ item: true }, { children: (0, jsx_runtime_1.jsx)(core_1.Button, Object.assign({ variant: "outlined", type: "button", onClick: handleCancelButton }, { children: "Cancel" }), void 0) }), void 0), (0, jsx_runtime_1.jsx)(core_1.Grid, Object.assign({ item: true }, { children: (0, jsx_runtime_1.jsx)(core_1.Button, Object.assign({ variant: "contained", endIcon: (0, jsx_runtime_1.jsx)(icons_1.Save, {}, void 0), type: "submit" }, { children: "Save" }), void 0) }), void 0)] }), void 0) }), void 0), (0, jsx_runtime_1.jsx)(core_1.TableContainer, { children: (0, jsx_runtime_1.jsx)(core_1.Table, Object.assign({ "aria-label": "simple table" }, { children: (0, jsx_runtime_1.jsx)(core_1.TableBody, { children: addressesList &&
                            addressesList.map((address, index) => ((0, jsx_runtime_1.jsxs)(core_1.TableRow, { children: [(0, jsx_runtime_1.jsx)(S.TableCell, Object.assign({ component: "th", scope: "row" }, { children: address.address }), void 0), (0, jsx_runtime_1.jsx)(S.TableCell, Object.assign({ component: "th", scope: "row" }, { children: address.city }), void 0), (0, jsx_runtime_1.jsx)(S.TableCell, Object.assign({ component: "th", scope: "row" }, { children: address.state }), void 0), (0, jsx_runtime_1.jsx)(S.TableCell, Object.assign({ component: "th", scope: "row" }, { children: address.zipCode }), void 0), (0, jsx_runtime_1.jsxs)(S.TableCell, Object.assign({ align: "right" }, { children: [(0, jsx_runtime_1.jsx)(core_1.Tooltip, Object.assign({ title: "Default" }, { children: (0, jsx_runtime_1.jsx)(core_1.Switch, { color: "default", checked: address.default, onClick: () => handleToggleDefaultAddress(index) }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(core_1.IconButton, Object.assign({ disableRipple: true, onClick: () => handleEditAddressButton(addressesList[index], index) }, { children: (0, jsx_runtime_1.jsx)(icons_1.Edit, {}, void 0) }), void 0), (0, jsx_runtime_1.jsx)(core_1.IconButton, Object.assign({ disableRipple: true, onClick: () => handleDeleteAddressButton(index) }, { children: (0, jsx_runtime_1.jsx)(icons_1.Delete, {}, void 0) }), void 0)] }), void 0)] }, index))) }, void 0) }), void 0) }, void 0)] }, void 0));
};
exports.default = ChangeAddresses;
