import { Button as MuiButton } from '@material-ui/core'
import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Button = styled(MuiButton).attrs({ disableRipple: true })`
  &.MuiButton-root {
    border: 2px solid ${colors.grey};
  }

  .MuiButton-label {
    color: ${colors.grey};
    font-size: 0.9em;
    font-family: 'ATTAleckSansBold';
    text-transform: none;
  }
`
export const PageList = styled.ul`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-family: PFDinText;
`

export const PageListItem = styled.li`
  &.active {
    background: #102641;
    border-radius: 4px;
    color: ${colors.white};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 5px 5px 5px;
  margin-right: 9px;
  cursor: pointer;
`
