"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Container = void 0;
const tslib_1 = require("tslib");
const styled_components_1 = (0, tslib_1.__importStar)(require("styled-components"));
const theme_1 = require("@monorepo/theme");
const xs = `
  font-size: 12px;
`;
const small = `
  font-size: 20px;
`;
const medium = `
font-size: 32px;
`;
const large = `
  font-size: 58px;
`;
const xl = `
  font-size: 62px;
`;
const sizes = {
    xs,
    small,
    medium,
    large,
    xl,
};
exports.Container = styled_components_1.default.p `
  margin: 0;
  font-family: ${({ family }) => (family ? family : 'ATTAleckSans')};

  font-style: ${({ font_style }) => (font_style ? font_style : 'normal')};

  font-weight: ${({ weight }) => (weight ? weight : '200')};

  font-size: ${({ size }) => (size ? size : '15px')};

  color: ${({ color }) => (color ? color : theme_1.colors.black)};

  text-align: ${({ align }) => (align ? align : 'center')};

  text-decoration: ${({ decoration }) => (decoration ? decoration : 'none')};

  text-transform: ${({ transform }) => (transform ? transform : 'none')};

  ${({ size }) => {
    if (size)
        return (0, styled_components_1.css) `
        ${sizes[size] ? sizes[size] : `font-size: ${size}`}
      `;
}}
`;
