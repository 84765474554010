"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.httpResponseErrorHandler = void 0;
var httpResponseErrorHandler = function (error) {
    if (error.status === 401) {
        window.location.href = '/login'; // maybe we need changes this
    }
    var errorData = error;
    return Promise.reject(errorData);
};
exports.httpResponseErrorHandler = httpResponseErrorHandler;
