import ReachDialog, { DialogOverlay } from '@reach/dialog'

import { Button as ButtonComponent } from '@monorepo/components'
import { IconButton as MuiIconButton } from '@material-ui/core'
import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`

export const Title = styled.h1`
  font-family: PFDINText;
  font-weight: 700;
  font-size: 36px;
  color: ${colors.black};
  padding: 25px;
`

export const Line = styled.hr`
  margin: 0 20px 25px 20px;
  border: 0;
  border-top: 0.5px solid ${colors.black};
`

export const ProductList = styled.ul`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`

export const ProductListItem = styled.li`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 15px;
  position: relative;
`

export const ItemImageContainer = styled.div`
  padding: 5px;
  background-color: ${colors.lighterGrey};
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemImage = styled.img`
  max-width: 160px;
  max-height: 150px;
  width: auto;
  height: auto;
`

export const ItemInfoContainer = styled.div`
  flex: 8;
  position: relative;
`

export const ItemTitle = styled.p`
  font-family: PFDINText;
  font-weight: 500;
  font-size: 18px;
  color: ${colors.black};
  margin-bottom: 20px;
`

export const ItemCoopInfo = styled.p`
  font-family: PFDINText;
  font-weight: 400;
  font-size: 18px;
  color: ${colors.black};
`

export const ItemInfo = styled.p`
  font-family: PFDINText;
  font-weight: 400;
  font-size: 18px;
  color: ${colors.lightGrey};
`

export const ItemPriceContainer = styled.div``

export const ItemPrice = styled.p`
  flex: 1;
  justify-self: flex-end;
  margin-bottom: 20px;
`

export const ItemCoopPercentage = styled.p`
  flex: 1;
  justify-self: flex-end;
`

export const ItemAmountAfterCoop = styled.p`
  flex: 1;
  justify-self: flex-end;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`

export const SubtotalInfo = styled(ItemTitle)`
  text-transform: uppercase;
  flex: 11;
  margin-bottom: 0;
`

export const SubtotalPrice = styled(ItemPrice)`
  font-size: 20px;
`

export const TotalInfo = styled(SubtotalInfo)`
  font-size: 20px;
  font-weight: 700;
`

export const IconButton = styled(MuiIconButton)`
  width: 26px;
  height: 26px;

  &.MuiButtonBase-root {
    position: absolute;
    top: 20px;
    right: 20px;
    border: 0.3px solid ${colors.black};
  }
`

export const CheckoutButton = styled(ButtonComponent)`
  width: max-content;
`

export const CartButton = styled.button`
  background-color: ${colors.grey};
  color: ${colors.white};
  text-transform: uppercase;
  height: 54.5px;
  font-family: PFDINText;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 20px;
`

export const TextButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 0;
  appearance: none;
  border: none;
  background-color: unset;
  color: ${colors.black};
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: unset;
  margin: unset;
  line-height: 15px;
`
export const DialogButton = styled(ButtonComponent)`
  width: 162px;
  display: flex;
  justify-content: center;
`

export const Overlay = styled(DialogOverlay)`
  div& {
    z-index: 1302;
  }
`

export const Dialog = styled(ReachDialog)`
  overflow: auto;
  position: relative;
  padding: 50px;
  width: fit-content;
`
export const CloseModalButton = styled(DialogButton)`
  height: 30px;
  width: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 32px;
  top: 32px;
`
