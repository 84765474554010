"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledButton = exports.StyledLabel = exports.buttons = exports.IconPropsSize = void 0;
const tslib_1 = require("tslib");
const styled_components_1 = (0, tslib_1.__importStar)(require("styled-components"));
const theme_1 = require("@monorepo/theme");
const grey = `
  background-color: ${theme_1.colors.grey};
  color: ${theme_1.colors.white};
`;
const turquoise = `
  background-color: ${theme_1.colors.turquoise};
  color: ${theme_1.colors.white};
`;
const blue = `
  background-color: ${theme_1.colors.blue};
  color: ${theme_1.colors.white}; 
`;
const white = `
  background-color: ${theme_1.colors.white};
  color: ${theme_1.colors.black};
`;
const stroke = `
  background-color: transparent;
  color: ${theme_1.colors.black};
  border: 1px solid ${theme_1.colors.black};
`;
const black = `
  background-color: ${theme_1.colors.black};
  color: ${theme_1.colors.white};
`;
const transparent = `
  background-color: transparent;
  color: ${theme_1.colors.black};
`;
const delicateGrey = `
  background-color: ${theme_1.colors.delicateGrey};
  color: ${theme_1.colors.black};
`;
const lapis = `
  background-color: ${theme_1.colors.lapis};
  color: ${theme_1.colors.white};
`;
var IconPropsSize;
(function (IconPropsSize) {
    IconPropsSize[IconPropsSize["small"] = 16] = "small";
    IconPropsSize[IconPropsSize["medium"] = 20] = "medium";
    IconPropsSize[IconPropsSize["large"] = 24] = "large";
})(IconPropsSize = exports.IconPropsSize || (exports.IconPropsSize = {}));
exports.buttons = {
    grey: { style: grey, iconColor: theme_1.colors.white },
    turquoise: { style: turquoise, iconColor: theme_1.colors.white },
    blue: { style: blue, iconColor: theme_1.colors.white },
    white: { style: white, iconColor: theme_1.colors.black },
    stroke: { style: stroke, iconColor: theme_1.colors.black },
    black: { style: black, iconColor: theme_1.colors.white },
    transparent: { style: transparent, iconColor: theme_1.colors.black },
    delicateGrey: { style: delicateGrey, iconColor: theme_1.colors.black },
    lapis: { style: lapis, iconColor: theme_1.colors.white },
};
exports.StyledLabel = styled_components_1.default.span `
  grid-area: label;
  line-height: initial;
`;
exports.StyledButton = styled_components_1.default.button `
  font-family: PFDINText;
  border-radius: ${({ borderRadius }) => (borderRadius ? `3em` : `0`)};
  cursor: pointer;
  line-height: 0;
  letter-spacing: 1px;
  display: grid;
  align-items: center;
  border: 0;
  figure {
    grid-area: icon;
  }

  ${({ colorOption }) => {
    var _a;
    return (0, styled_components_1.css) `
      ${(_a = exports.buttons[colorOption]) === null || _a === void 0 ? void 0 : _a.style}
    `;
}}

  ${({ size, padding }) => {
    if (size === 'small') {
        return (0, styled_components_1.css) `
        font-size: 10pt;
        padding: ${padding || '16px'};
      `;
    }
    else if (size === 'medium') {
        return (0, styled_components_1.css) `
        font-size: 11pt;
        padding: ${padding || '20px'};
      `;
    }
    else {
        return (0, styled_components_1.css) `
        font-size: 12pt;
        padding: ${padding || '24px'};
      `;
    }
}}

  ${({ iconPosition, icon, label }) => {
    if (label && label.length > 0 && icon) {
        if (iconPosition === 'left') {
            return (0, styled_components_1.css) `
          span {
            margin-left: 1em;
          }
          grid-template-areas: 'icon label';
        `;
        }
        else {
            return (0, styled_components_1.css) `
          span {
            margin-right: 1em;
          }
          grid-template-areas: 'label icon';
        `;
        }
    }
}}

`;
