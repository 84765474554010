import { useChannel } from "@monorepo/infra"
import { useEffect, useState } from "react"

interface UseDemoAppURL {
    demoAppUrl: string
    error: string | null | undefined
}

export const useDemoAppURL = (): UseDemoAppURL => {
    const channel = useChannel()
    const [demoAppUrl, setdemoAppUrl] = useState('')
    const [error, seterror] = useState('')

    const getDemoAppUrl = () => {
        const { selectedChannel } = channel
        if(!selectedChannel||selectedChannel==null || selectedChannel==''){
            seterror('An error encountered while trying to get your channel.')
        }
        setdemoAppUrl(`https://${selectedChannel?.toLowerCase()}.directvdemoapp.com`)
    }

    useEffect(()=>{
        getDemoAppUrl()
    },[demoAppUrl, getDemoAppUrl, channel])
    
    return { demoAppUrl, error }
}