import axios from 'axios'

export enum LogType {
  LOGIN = 1,
  CLICK,
  USER_ACTIVITY,
}

export enum LogItemType {
  PRODUCT = 1,
  EVENT,
  RESOURCE,
  CHECKOUT,
  DIRECTV_DEMO,
}

export interface UserActivityLog {
  currentValue: string | boolean
  previusValue: string | boolean
  key: string
}

export interface LogData {
  type: LogType
  itemType?: LogItemType
  itemId?: string
  masterDealerId?: string
  channel?: string
  userActivity?: UserActivityLogData
}

export interface UserActivityLogData {
  userId: string
  changes: UserActivityLog[]
}

export const log = (data: LogData, token?: string) => {
  const config = {
    baseURL: process.env.REACT_APP_REST_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    },
  }

  axios.post('/log', data, config).catch((error) => console.log(error))
}
