"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressesContext = void 0;
var react_1 = require("react");
exports.AddressesContext = (0, react_1.createContext)({
    billingAddresses: [],
    shippingAddresses: [],
    defaultBillingAddress: {},
    defaultShippingAddress: {},
    dispatchBillingAddresses: function () { return undefined; },
    dispatchShippingAddresses: function () { return undefined; },
    dispatchDefaultBillingAddress: function () { return undefined; },
    dispatchDefaultShippingAddress: function () { return undefined; },
});
