"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatus = exports.OrderPaymentType = exports.OrderItemStatus = exports.OrderPaymentStatus = void 0;
var OrderPaymentStatus;
(function (OrderPaymentStatus) {
    OrderPaymentStatus[OrderPaymentStatus["UNPAID"] = 1] = "UNPAID";
    OrderPaymentStatus[OrderPaymentStatus["PARTIAL"] = 2] = "PARTIAL";
    OrderPaymentStatus[OrderPaymentStatus["PAID"] = 3] = "PAID";
})(OrderPaymentStatus = exports.OrderPaymentStatus || (exports.OrderPaymentStatus = {}));
var OrderItemStatus;
(function (OrderItemStatus) {
    OrderItemStatus[OrderItemStatus["ACTIVE"] = 1] = "ACTIVE";
    OrderItemStatus[OrderItemStatus["INACTIVE"] = 2] = "INACTIVE";
    OrderItemStatus[OrderItemStatus["PRICE_UPDATED"] = 3] = "PRICE_UPDATED";
})(OrderItemStatus = exports.OrderItemStatus || (exports.OrderItemStatus = {}));
var OrderPaymentType;
(function (OrderPaymentType) {
    OrderPaymentType[OrderPaymentType["CREDIT_CARD"] = 1] = "CREDIT_CARD";
    OrderPaymentType[OrderPaymentType["COOP"] = 2] = "COOP";
})(OrderPaymentType = exports.OrderPaymentType || (exports.OrderPaymentType = {}));
var OrderStatus;
(function (OrderStatus) {
    OrderStatus[OrderStatus["IN_PROGRESS"] = 1] = "IN_PROGRESS";
    OrderStatus[OrderStatus["COMPLETE"] = 2] = "COMPLETE";
    OrderStatus[OrderStatus["CANCELED"] = 3] = "CANCELED";
    OrderStatus[OrderStatus["ARCHIVED"] = 4] = "ARCHIVED";
    OrderStatus[OrderStatus["DELETED"] = 5] = "DELETED";
    OrderStatus[OrderStatus["COOP_REQUEST"] = 6] = "COOP_REQUEST";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
