"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_router_dom_1 = require("react-router-dom");
const core_1 = require("@material-ui/core");
const infra_1 = require("@monorepo/infra");
const S = (0, tslib_1.__importStar)(require("./styles"));
const Address = ({ title, address, userId, addresses, addressIndex, changeAddress = true, setNewAddress, setCurrentAddress, dispatchAddress, className, }) => {
    const { t } = (0, react_i18next_1.useTranslation)('forms');
    const history = (0, react_router_dom_1.useHistory)();
    const [zipCode, setZipCode] = (0, react_1.useState)('');
    const [addressField, setAddressField] = (0, react_1.useState)('');
    const [state, setState] = (0, react_1.useState)('');
    const [city, setCity] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        setZipCode(address === null || address === void 0 ? void 0 : address.zipCode);
        setAddressField(address === null || address === void 0 ? void 0 : address.address);
        setState(address === null || address === void 0 ? void 0 : address.state);
        setCity(address === null || address === void 0 ? void 0 : address.city);
    }, [address === null || address === void 0 ? void 0 : address.zipCode, address === null || address === void 0 ? void 0 : address.address, address === null || address === void 0 ? void 0 : address.state, address === null || address === void 0 ? void 0 : address.city]);
    const handleAddressButton = () => {
        history.push({
            pathname: `${history.location.pathname}/addresses`,
            state: { addresses, title, userId: userId },
        });
    };
    return ((0, jsx_runtime_1.jsxs)(S.Container, Object.assign({ "data-testid": "address-component", className: className }, { children: [(0, jsx_runtime_1.jsxs)(S.TitleContainer, { children: [title && (0, jsx_runtime_1.jsx)(S.Typography, Object.assign({ paragraph: true }, { children: title }), void 0), changeAddress && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(S.Button, Object.assign({ onClick: () => handleAddressButton(), variant: "outlined" }, { children: "Add address" }), void 0), (0, jsx_runtime_1.jsx)(S.Link, Object.assign({ onClick: () => handleAddressButton() }, { children: "Edit addresses" }), void 0)] }, void 0))] }, void 0), (0, jsx_runtime_1.jsxs)(core_1.Grid, Object.assign({ container: true, spacing: 2 }, { children: [(0, jsx_runtime_1.jsx)(core_1.Grid, Object.assign({ item: true, xs: 12, sm: 4 }, { children: (0, jsx_runtime_1.jsx)(S.Input, { id: "zipcode", name: "zipcode", onChange: (event) => {
                                setZipCode(event.target.value);
                                if (!addressIndex) {
                                    dispatchAddress({
                                        type: infra_1.ActionAdressType.UpdatetZipCode,
                                        payload: { value: event.target.value },
                                    });
                                }
                            }, onBlur: () => {
                                if (setCurrentAddress)
                                    if (addressIndex !== -1) {
                                        const currentAddressCopy = Object.assign(Object.assign({}, address), { zipCode: zipCode });
                                        setCurrentAddress(currentAddressCopy);
                                    }
                                    else if (addressIndex === -1) {
                                        const newAddressCopy = Object.assign(Object.assign({}, address), { zipCode: zipCode });
                                        setNewAddress(newAddressCopy);
                                    }
                            }, value: zipCode, label: t('profile.inputs.zipCode.label'), placeholder: t('profile.inputs.zipCode.placeholder') }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(core_1.Grid, Object.assign({ item: true, xs: 12, sm: 8 }, { children: (0, jsx_runtime_1.jsx)(S.Input, { id: "address", name: "address", onChange: (event) => {
                                setAddressField(event.target.value);
                                if (!addressIndex) {
                                    dispatchAddress({
                                        type: infra_1.ActionAdressType.UpdateAddress,
                                        payload: { value: event.target.value },
                                    });
                                }
                            }, onBlur: () => {
                                if (setCurrentAddress)
                                    if (addressIndex !== -1) {
                                        const currentAddressCopy = Object.assign(Object.assign({}, address), { address: addressField });
                                        setCurrentAddress(currentAddressCopy);
                                    }
                                    else if (addressIndex === -1) {
                                        const newAddressCopy = Object.assign(Object.assign({}, address), { address: addressField });
                                        setNewAddress(newAddressCopy);
                                    }
                            }, value: addressField, label: t('profile.inputs.address.label'), placeholder: t('profile.inputs.address.placeholder') }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(core_1.Grid, Object.assign({ item: true, xs: 12, sm: 4 }, { children: (0, jsx_runtime_1.jsx)(S.Input, { id: "state", name: "state", onChange: (event) => {
                                setState(event.target.value);
                                if (!addressIndex) {
                                    dispatchAddress({
                                        type: infra_1.ActionAdressType.UpdateState,
                                        payload: { value: event.target.value },
                                    });
                                }
                            }, onBlur: () => {
                                if (setCurrentAddress)
                                    if (addressIndex !== -1) {
                                        const currentAddressCopy = Object.assign(Object.assign({}, address), { state: state });
                                        setCurrentAddress(currentAddressCopy);
                                    }
                                    else if (addressIndex === -1) {
                                        const newAddressCopy = Object.assign(Object.assign({}, address), { state: state });
                                        setNewAddress(newAddressCopy);
                                    }
                            }, value: state, label: t('profile.inputs.state.label'), placeholder: t('profile.inputs.state.placeholder') }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(core_1.Grid, Object.assign({ item: true, xs: 12, sm: 8 }, { children: (0, jsx_runtime_1.jsx)(S.Input, { id: "city", name: "city", onChange: (event) => {
                                setCity(event.target.value);
                                if (!addressIndex) {
                                    dispatchAddress({
                                        type: infra_1.ActionAdressType.UpdateCity,
                                        payload: { value: event.target.value },
                                    });
                                }
                            }, onBlur: () => {
                                if (setCurrentAddress)
                                    if (addressIndex !== -1) {
                                        const currentAddressCopy = Object.assign(Object.assign({}, address), { city: city });
                                        setCurrentAddress(currentAddressCopy);
                                    }
                                    else if (addressIndex === -1) {
                                        const newAddressCopy = Object.assign(Object.assign({}, address), { city: city });
                                        setNewAddress(newAddressCopy);
                                    }
                            }, value: city, label: t('profile.inputs.city.label'), placeholder: t('profile.inputs.city.placeholder') }, void 0) }), void 0)] }), void 0)] }), void 0));
};
exports.default = Address;
