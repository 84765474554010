"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePaginationSearchParams = void 0;
var react_1 = require("react");
var _1 = require(".");
var react_router_dom_1 = require("react-router-dom");
function usePaginationSearchParams(_a) {
    var searchTerm = _a.searchTerm, pageURLKey = _a.pageURLKey, searchURLKey = _a.searchURLKey, pageAPIKey = _a.pageAPIKey, searchAPIKey = _a.searchAPIKey;
    var queryParams = (0, _1.useQueryParams)();
    var history = (0, react_router_dom_1.useHistory)();
    var debouncedSearchTerm = (0, _1.useDebounce)(searchTerm, 500);
    var _b = (0, react_1.useState)(''), apiSearchParams = _b[0], setAPISearchParams = _b[1];
    var handlePageChange = (0, react_1.useCallback)(function (newPage) {
        newPage === 1
            ? queryParams.delete(pageURLKey)
            : queryParams.set(pageURLKey, newPage.toString());
        history.push({ search: queryParams.toString() });
    }, [history, pageURLKey, queryParams]);
    var handlePrevPage = (0, react_1.useCallback)(function () {
        var currentPageNumber = Number(queryParams.get(pageURLKey));
        handlePageChange(currentPageNumber - 1);
    }, [handlePageChange, pageURLKey, queryParams]);
    var handleNextPage = (0, react_1.useCallback)(function () {
        var currentPageNumber = queryParams.get(pageURLKey);
        if (!currentPageNumber)
            return handlePageChange(2);
        handlePageChange(Number(currentPageNumber) + 1);
    }, [handlePageChange, pageURLKey, queryParams]);
    var handleUpdateSearchQuery = (0, react_1.useCallback)(function (searchQuery) {
        if (!searchQuery) {
            queryParams.delete(searchURLKey);
            history.push({ search: queryParams.toString() });
            return;
        }
        queryParams.has(pageURLKey) && queryParams.delete(pageURLKey);
        queryParams.set(searchURLKey, searchQuery);
        history.push({ search: queryParams.toString() });
    }, [history, pageURLKey, queryParams, searchURLKey]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (!(debouncedSearchTerm === null || debouncedSearchTerm === void 0 ? void 0 : debouncedSearchTerm.trim()) && queryParams.has(searchURLKey)) {
            queryParams.delete(searchURLKey);
            queryParams.has(pageURLKey) && queryParams.delete(pageURLKey);
            history.push({ search: queryParams.toString() });
            return;
        }
        if ((debouncedSearchTerm === null || debouncedSearchTerm === void 0 ? void 0 : debouncedSearchTerm.trim()) !== ((_a = queryParams.get(searchURLKey)) === null || _a === void 0 ? void 0 : _a.trim())) {
            handleUpdateSearchQuery(debouncedSearchTerm === null || debouncedSearchTerm === void 0 ? void 0 : debouncedSearchTerm.trim());
        }
    }, [
        debouncedSearchTerm,
        handleUpdateSearchQuery,
        history,
        pageURLKey,
        queryParams,
        searchURLKey,
    ]);
    (0, react_1.useEffect)(function () {
        var searchTerm = queryParams.get(searchURLKey);
        var pageNum = Number(queryParams.get(pageURLKey));
        var newAPISearchParams = new URLSearchParams(queryParams.toString());
        searchTerm
            ? newAPISearchParams.set(searchAPIKey, searchTerm)
            : newAPISearchParams.delete(searchAPIKey);
        pageNum
            ? newAPISearchParams.set(pageAPIKey, pageNum.toString())
            : newAPISearchParams.delete(pageAPIKey);
        setAPISearchParams(newAPISearchParams.toString());
    }, [pageAPIKey, pageURLKey, queryParams, searchAPIKey, searchURLKey]);
    return {
        queryParams: queryParams,
        apiSearchParams: apiSearchParams,
        handlePageChange: handlePageChange,
        handlePrevPage: handlePrevPage,
        handleNextPage: handleNextPage,
    };
}
exports.usePaginationSearchParams = usePaginationSearchParams;
