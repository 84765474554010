"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cartReducer = void 0;
var actions_1 = require("./actions");
// TODO: Add in controller.ts this actions: incrementProduct, incrementEvent, decrementProduct,decrementEvent
var cartReducer = function (items, action) {
    switch (action.type) {
        case actions_1.CartActionType.AddProduct:
            return addProduct(items, action.payload.item);
        case actions_1.CartActionType.AddEvent:
            return addEvent(items, action.payload.item);
        case actions_1.CartActionType.IncrementProduct:
            return incrementProduct(items, action.payload.index);
        case actions_1.CartActionType.IncrementEvent:
            return incrementEvent(items, action.payload.index);
        case actions_1.CartActionType.RemoveProduct:
            return removeProduct(items, action.payload.index);
        case actions_1.CartActionType.RemoveEvent:
            return removeEvent(items, action.payload.index);
        case actions_1.CartActionType.DecrementProduct:
            return decrementProduct(items, action.payload.index);
        case actions_1.CartActionType.DecrementEvent:
            return decrementEvent(items, action.payload.index);
        case actions_1.CartActionType.ClearItems:
            return {
                products: [],
                events: [],
            };
        case actions_1.CartActionType.UpdateItemsFromDB:
            return updateItemsFromDB(action.payload.items);
        default:
            return items;
    }
};
exports.cartReducer = cartReducer;
var addProduct = function (state, product) {
    var copy = JSON.parse(JSON.stringify(state));
    copy.products.push(product);
    return copy;
};
var addEvent = function (state, event) {
    var copy = JSON.parse(JSON.stringify(state));
    copy.events.push(event);
    return copy;
};
var incrementProduct = function (state, index) {
    var copy = JSON.parse(JSON.stringify(state));
    copy.products[index].quantity += 1;
    return copy;
};
var incrementEvent = function (state, index) {
    var copy = JSON.parse(JSON.stringify(state));
    copy.events[index].quantity += 1;
    return copy;
};
var removeProduct = function (state, index) {
    var copy = JSON.parse(JSON.stringify(state));
    copy.products.splice(index, 1);
    return copy;
};
var removeEvent = function (state, index) {
    var copy = JSON.parse(JSON.stringify(state));
    copy.events.splice(index, 1);
    return copy;
};
var decrementProduct = function (state, index) {
    var copy = JSON.parse(JSON.stringify(state));
    if (copy.products[index].quantity === 1) {
        copy.products.splice(index, 1);
    }
    else {
        copy.products[index].quantity -= 1;
    }
    return copy;
};
var decrementEvent = function (state, index) {
    var copy = JSON.parse(JSON.stringify(state));
    if (copy.events[index].quantity === 1) {
        copy.events.splice(index, 1);
    }
    else {
        copy.events[index].quantity -= 1;
    }
    return copy;
};
var updateItemsFromDB = function (items) {
    return items;
};
