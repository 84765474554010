import { colors } from '@monorepo/theme'
import BaseSearch from '../../atoms/Search'
import { SearchProps } from '../..'
import styled from 'styled-components'
import { StylesConfig } from 'react-select'
import { Menu, MenuButton, MenuList } from '@reach/menu-button'

interface HeroCreativeProps {
  background?: {
    mobile?: string
    desk?: string
  }
  color?: string
  backgroundColor?: string
  flexFlow?: string
  resetMinHeight?: boolean
}

const Hero = styled.section<HeroCreativeProps>`
  align-items: center;
  background-color: ${(props) => props.backgroundColor || '#000'};
  ${(props) =>
    !!props.background?.mobile &&
    `background-image: url(${props.background?.mobile});
    background-position: center;
    background-size: cover;
    `}
  color: ${(props) => props.color || colors.darkBlue};
  display: flex;
  ${(props) =>
    props.flexFlow === 'row'
      ? 'flex-flow: row nowrap; justify-content: space-between; padding: 34px 34px 22px 34px;'
      : 'flex-flow: column wrap; justify-content: center;'}
  text-transform: uppercase;
  width: 100%;
  gap: 40px;

  & > h1 {
    font-family: 'Jubilat';
    font-size: 36px;
    font-style: italic;
    font-weight: 700;
    justify-content: center;
    
    @media (min-width: 1024px) {
      margin-right: 125px;
      min-width: 165px;
    }
    
    @media (min-width: 1440px) {
      margin-right: 145px;
    }

    @media (min-width: 1920px) {
      margin-right: 108px;
      min-width: unset;
    }
  }

  & > p {
    font-family: 'PFDINText';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.005em;
    margin-top: 28px;
    max-width: 600px;

  }

  @media screen and (min-width: 767px) {
    ${(props) =>
    !!props.background?.desk &&
    `background-image: url(${props.background?.desk});
      `}
    font-size: 36px;
  }
  
  @media (max-width: 1440px) {
    gap: 20px;

  }
`

const Search = styled(BaseSearch).attrs(({ color, backgroundColor }) => ({
  backgroundColor: backgroundColor || '#ffffff',
  color: color || '#ffffff',
  iconColor: colors.darkBlue,
  width: 325,
  height: 40,
  fontSize:18,
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  alignItems: 'baseline',
  justifyContent: 'start',
  border:'none',
  borderBottom: '1px solid grey',
  focusVisible:{outline:'none'}
})) <SearchProps>``

const FilterWrap = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SelectStyle: StylesConfig<SortType> = {
  container: (base) => ({
    ...base,
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
    border: 'none',
    minHeight: 35,
    paddingLeft: 20,
    paddingRight: 20,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  multiValueLabel: (base) => ({
    ...base,
    backgroundColor: '#21528C',
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'PFDINText',
    fontSize: 18,
    letterSpacing: -0.2,
    fontWeight: 'normal',
    color: colors.mediumGrey,
  }),
}

export type SortType = {
  label: string
  value: string
}

type IsMulti = false

const MultiSelectStyle: StylesConfig<SortType, IsMulti> = {
  indicatorsContainer:(base)=>({
    ...base,
color: colors.darkBlue,
  }),
  dropdownIndicator:(base)=>({
    ...base,
color: colors.darkBlue,
  }),
  container: SelectStyle.container,
  control: (base)=>({
    ...base,
    font:'PFDINText',
    fontSize: '18px',
    textTransform: 'none',
    color:colors.darkBlue,
    borderColor: 'none',
    borderRadius: '0px',
    borderStyle: 'none', 
    borderWidth: '0px',
  }),
  placeholder: (base)=>({
    ...base,
  color:colors.darkBlue,
  textTransform: 'none',
  fontSize:'18px',
  fontWeight: 500,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: colors.white,
    padding: 0,
    marginRight: 10,
    fontSize:'18px',
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    padding: 0,
    height: 20,
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: '.2s ease-in-out background-color',
    backgroundColor: state.isFocused ? 'black' : 'transparent',
    '&:hover': {
      backgroundColor: 'black',
      color: colors.white,
      cursor: 'pointer',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#21528C',
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 5,
    borderRadius: 18,
    color: colors.white,
    padding: '0 10px',
    fontSize:'18px',
  }),
  menu: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
    fontSize:'18px',
  }),
  menuList: (base, state) => ({
    ...base,
    fontSize:'18px',
    display: !!state.options.length ? 'block' : 'none',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    display: 'none',
  }),
}

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px; 
  flex-grow: 1;
  flex-wrap: nowrap;

  & > p.date {
    color: #fff;
    text-decoration: solid;
  }
  
  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`

const SelectDate = styled(Menu)`
  color: ${colors.delicateGrey};
  cursor: pointer;
  font-family: PFDINText;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2;
  margin-right: 10px;
  text-align: left;
`

const SelectDateButton = styled(MenuButton)`
  align-items: center;
  background: none;
  border: 0;
  color: #58677a;
  cursor: pointer;
  display: flex;
  font-family: PFDINText;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2;
  text-align: left;
  width: auto;
  border-bottom: 1px solid ${colors.grey};
  height: 42px;
  align-self: center;

  & > p {
  }

  & > label {
    margin: 0px 18px 0px 0px;
    font-weight: 300;
  }
`

const SelectDateContent = styled(MenuList)``

const Arrow = styled.p`
  color: #9fa8b3;
  cursor: pointer;
`

const DateText = styled.p`
    font-size: 16px;
    color: rgb(88, 103, 122);
`

export {
  Hero,
  Search,
  FilterWrap,
  MultiSelectStyle,
  Filter,
  SelectDate,
  SelectDateButton,
  SelectDateContent,
  Arrow,
  DateText
}
