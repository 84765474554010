import { useCallback, useState } from 'react'

import { http } from '@monorepo/infra'

export interface CompsPayload {
  uri: string
  channel: string
  isPreferred: string
  dealerName?: string
  dealerAddress?: string
  dealerAddress2?: string
  dealerPhone?: string
  dealerImage?: File|string
}

const fetchStatuses = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}
interface UseComps {
  dealerImage: string
  previewImages: string[]
  fetchStatuses: typeof fetchStatuses
  fetchStatus: string
  getComps: (payload: Partial<CompsPayload>) => Promise<unknown | undefined>
  uploadDealerImage: (payload: File) => Promise<unknown | undefined>
}

export const useComps = (): UseComps => {
  const [previewImages, setPreviewImages] = useState<string[]>([])
  const [dealerImage, setDealerImage] = useState<string>('')

  const [fetchStatus, setFetchStatus] = useState<UseComps['fetchStatus']>(
    fetchStatuses.INITIAL
  )

  const uploadDealerImage = useCallback(async (payload: File) => {
    try {
      setFetchStatus(fetchStatuses.FETCHING)
      const formData = new FormData()
      formData.append('file', payload)
      const dealerImageResponse = await http.post<{ result: string }>({
        url: '/indesign/dealer-image',
        data: formData,
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      console.log(dealerImageResponse)
      if (dealerImageResponse.data.result) {
        setDealerImage(dealerImageResponse.data.result)
      }
    } catch (error) {
      setFetchStatus(fetchStatuses.ERROR)
    }
  }, [])

  const getComps = useCallback(async (payload: Partial<CompsPayload>) => {
    if (!payload.uri || !payload.channel) {
      setPreviewImages([])
      setFetchStatus(fetchStatuses.INITIAL)
      return
    }

    try {
      setFetchStatus(fetchStatuses.FETCHING)

      console.log(process.env.REACT_APP_REST_BASE_URL)

      const formData = new FormData()
      payload.channel && formData.append('channel', payload.channel)
      payload.isPreferred && formData.append('isPreferred', payload.isPreferred)
      payload.uri && formData.append('uri', payload.uri)
      payload.dealerName && formData.append('dealerName', payload.dealerName)
      payload.dealerAddress &&
        formData.append('dealerAddress', payload.dealerAddress)
      payload.dealerAddress2 &&
        formData.append('dealerAddress2', payload.dealerAddress2)
      payload.dealerPhone && formData.append('dealerPhone', payload.dealerPhone)
      payload.dealerImage && formData.append('file', payload.dealerImage)

      const compsResponse = await http.post<{ result: [] }>({
        url: '/indesign/comps',
        data: formData,
        headers: {
          'content-type': 'multipart/form-data',
        },
      })

      const result = compsResponse?.data?.result?.shift?.() as unknown as {
        [key: string]: string
      }

      const previewImages = Object.values(result).map(
        (base64Img) => `data:image/png;base64,${base64Img}`
      )

      setPreviewImages(previewImages)
      setFetchStatus(fetchStatuses.SUCCESS)

      return compsResponse
    } catch (error) {
      setFetchStatus(fetchStatuses.ERROR)
    }
  }, [])

  return {
    previewImages,
    fetchStatus,
    fetchStatuses,
    getComps,
    uploadDealerImage,
    dealerImage,
  }
}
