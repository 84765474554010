"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = (0, tslib_1.__importStar)(require("react"));
const molecules_1 = require("../../molecules");
const S = (0, tslib_1.__importStar)(require("./styles"));
const Carousel = ({ products, paddingBottom = '80px', }) => {
    const ref = react_1.default.useRef(null);
    const [isScrolling, setIsScrolling] = (0, react_1.useState)(false);
    const [clientX, setClientX] = (0, react_1.useState)(0);
    const [scrollX, setScrollX] = (0, react_1.useState)(0);
    const onMouseDown = (e) => {
        console.log('mouseDown');
        setIsScrolling(true);
        setClientX(e.clientX);
    };
    const onMouseUp = () => {
        setIsScrolling(false);
    };
    const onMouseMove = (e) => {
        if (isScrolling) {
            ref.current.scrollLeft = scrollX + e.clientX - clientX;
            setScrollX(scrollX + e.clientX - clientX);
            setClientX(e.clientX);
        }
    };
    return ((0, jsx_runtime_1.jsx)(S.MediaScroller, Object.assign({ ref: ref, paddingBottom: paddingBottom, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseMove: onMouseMove }, { children: products.map((product, index) => {
            var _a;
            return ((0, jsx_runtime_1.jsx)(molecules_1.CardProduct, { link: product === null || product === void 0 ? void 0 : product.link, images: product === null || product === void 0 ? void 0 : product.images, title: product === null || product === void 0 ? void 0 : product.title, isNew: product === null || product === void 0 ? void 0 : product.isNew, subtitle: product === null || product === void 0 ? void 0 : product.subtitle, aditionalInfo: product === null || product === void 0 ? void 0 : product.aditionalInfo, showInfo: (_a = product === null || product === void 0 ? void 0 : product.showInfo) !== null && _a !== void 0 ? _a : true }, index));
        }) }), void 0));
};
exports.default = Carousel;
