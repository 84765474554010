"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.httpRequestSetup = void 0;
var tslib_1 = require("tslib");
var keycloak_1 = require("../../infra/keycloak");
var keycloak_2 = require("../../helpers/keycloak");
var httpRequestSetup = function (config) { return (0, tslib_1.__awaiter)(void 0, void 0, void 0, function () {
    var token;
    return (0, tslib_1.__generator)(this, function (_a) {
        token = (0, keycloak_2.getToken)(keycloak_1.keycloakClient);
        if (!!token) {
            config.headers.Authorization = "Bearer ".concat(token);
        }
        return [2 /*return*/, config];
    });
}); };
exports.httpRequestSetup = httpRequestSetup;
