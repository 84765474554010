import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import { SectionHomeProps } from '@monorepo/interfaces'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 800px;
  height: 100vh;
`

export const Container = styled.div<SectionHomeProps>`
  gap: 30px;
  padding: 10vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(${(props) => (props.bgImage ? props.bgImage : 'none')});
  background-color: ${colors.dark};
  background-repeat: no-repeat;
  background-size: cover;
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
`

export const Heading = styled.span`
  color: ${colors.white};
  font-size: 27pt;
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: 426px) {
    font-size: 2rem;
  }
`

export const Title = styled.h1`
  color: ${colors.white};
  font-size: 41.25pt;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  font-family: Jubilat;

  @media (max-width: 426px) {
    font-size: 2rem;
  }
`

export const Content = styled.p`
  font-size: 15pt;
  font-weight: 400;
  color: ${colors.white};
  margin-bottom: 165px;

  @media (max-width: 426px) {
    font-size: 1rem;
  }
`
export const Buttons = styled.div`
  display: flex;
  gap: 20px;
`
export const Featured = styled.div`
  height: 150px;
  display: flex;
  background: ${colors.white};
`

export const FeaturedTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 50px;
  place-items: center;
  gap: 45px;
`

export const FeaturedTitle = styled.div`
  color: ${colors.black};
  font-size: 27pt;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  font-family: Jubilat;
`

export const Arrows = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: auto;
  margin-right: 100px;
`
