"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCartContext = exports.CartProvider = void 0;
var tslib_1 = require("tslib");
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var context_1 = require("./context");
var controller_1 = require("./controller");
var CartProvider = function (_a) {
    var children = _a.children;
    return ((0, jsx_runtime_1.jsx)(context_1.CartContext.Provider, (0, tslib_1.__assign)({ value: (0, tslib_1.__assign)({}, (0, controller_1.useCartContextController)()) }, { children: children }), void 0));
};
exports.CartProvider = CartProvider;
function useCartContext() {
    var context = (0, react_1.useContext)(context_1.CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider.');
    }
    return context;
}
exports.useCartContext = useCartContext;
