import React, {useState, useEffect } from 'react'
import {
  SectionBanner,
  SectionZoners,
  SectionNewUserPopUp,
  SectionFeaturedTitleAndText
} from '../../organisms'
import { http } from '@monorepo/infra'
import { PageSpinner } from '@monorepo/components'

const Home: React.FC = () => {
  const [homepageImage, setHomePageImage] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(()=>{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    http.get({url:'asset/homepage-image'}).then((result:any)=>{
      if(result.data && result.data?.path != "")
        setHomePageImage(result.data?.path)
      setLoading(false)
    })
  },[])

  if (loading) {
    return <PageSpinner />
  }

  return (
    <>
      <main>
        <SectionBanner bgImage={homepageImage || "/images/homepage/image-placeholder.png"} />
        <SectionFeaturedTitleAndText />
        <SectionZoners />
        <SectionNewUserPopUp />
      </main>
    </>
  )
}

export default Home
