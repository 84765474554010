import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`

export const CheckboxContainer = styled.div`
  padding: 0 10px;
`

export const LinkContainer = styled.div`
  width: 100%;
`

type NotificationCardProps = {
  isSelected: boolean
}

export const NotificationCard = styled.div<NotificationCardProps>`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ isSelected }) => (isSelected ? '#F4F6F8' : '')};
  box-shadow: 0.5px 0.5px 0px rgb(0 0 0 / 25%);
`
interface TitleProps {
  fontWeight?: string
}
export const Title = styled.h1<TitleProps>`
  font-size: 15px;
  font-weight: ${(props) => props.fontWeight ?? '500'};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const Image = styled.img`
  width: 130px;
  height: 130px;
  object-fit: contain;
`

export const Actions = styled.div``

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const Text = styled.h1`
  font-size: 20px;
  font-weight: 500;
`
export const Price = styled.h2`
  font-size: 18px;
  font-weight: 500;
`
export const Type = styled.h3`
  font-size: 18px;
`

export const UserProfileBadge = styled.span`
  user-select: none;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  font-family: Jubilat;
  font-size: 20px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
  background-color: #102641;
  color: #fff;

  > div {
    position: relative;
    top: 50%;
  }
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
`

export const Subject = styled.p`
  padding-left: 56px;
  color: #707d8d;
`

export const Hours = styled.p`
  font-weight: 500;
  font-size: 15px;
  margin-left: auto;
`
