import * as S from './styles'
import { colors } from '@monorepo/theme'

import { CategoryItem } from '../../molecules'
import { HeroCreative, ProductList } from '../../organisms'
import React, { useCallback, useEffect, useState } from 'react'
import { SingleValue } from 'react-select'

import InfiniteScroll from 'react-infinite-scroll-component'
import { Product } from '@monorepo/interfaces'
import { Spinner } from '@monorepo/components'
import { http, useChannel } from '@monorepo/infra'

interface AllProductsProps {
  result: Product[]
  incrementPage: () => void
  hasMore: boolean
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  clearSearchTerm: () => void
  setCategoriesFilter: (filter: string) => void
  initialCategories: string[]
  setSortTitleFilter: (sort: string) => void
}


const AllProducts: React.FC<AllProductsProps> = ({
  result,
  searchTerm,
  incrementPage,
  setSearchTerm,
  hasMore,
  clearSearchTerm,
  setCategoriesFilter,
  initialCategories,
  setSortTitleFilter,
}) => {
  const [categoryList, setCategoryList] = useState<CategoryItem[]>()
  const [sortItem, setSortItem] = useState<SingleValue<S.SortType>>()
  const { selectedChannel } = useChannel()

  const setCategories = useCallback(
    (list: string[]) => {
      if (!list.length && !initialCategories.length) {
        setCategoriesFilter('')
      } else if (JSON.stringify(list) !== JSON.stringify(initialCategories)) {
        setCategoriesFilter(list.join())
      }
    },
    [initialCategories, setCategoriesFilter]
  )

  const getCategories = useCallback(async () => {
    const result = await http.get<string[]>({
      url: `/product/categories/${selectedChannel}`,
    })
    const categories = result?.data?.map(
      (item: string, index: number): CategoryItem => ({
        id: `${index + 1}`,
        name: item,
        count: 0,
      })
    )
    setCategoryList(categories)
  }, [selectedChannel])

  useEffect(() => {
    if (!categoryList) {
      getCategories()
    }
  }, [categoryList, getCategories])

  useEffect(() => {
    if (sortItem) {
      setSortTitleFilter(sortItem.value)
    }
  }, [setSortTitleFilter, sortItem])

  return (
    <>
      <HeroCreative
        title="ad materials"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder={'Search products'}
        clearSearchTerm={clearSearchTerm}
        color={colors.darkBlue}
        backgroundColor={colors.white}
        flexFlow="row"
        resetMinHeight={true}
        isAdPage={true}
        adProps={{
          categoryList,
          initialCategories, 
          sortItem, 
          setCategories, 
          setSortItem,
        }}
      />
      <S.InfiniteScrollContainer>
        {searchTerm && searchTerm !== '' && (
          <S.SearchInfo>{`Search results for "${searchTerm}"`}</S.SearchInfo>
        )}
        <InfiniteScroll
          dataLength={result?.length} //This is important field to render the next data
          next={incrementPage}
          hasMore={hasMore}
          loader={
            <div style={{ padding: '1rem' }}>
              <Spinner />
            </div>
          }
        >
          <ProductList products={result} />
        </InfiniteScroll>
      </S.InfiniteScrollContainer>
    </>
  )
}

export default AllProducts
