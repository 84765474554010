import React from 'react'
import * as S from './styles'
import { Button } from '@monorepo/components'
import { colors } from '@monorepo/theme'

interface IBgImage{
bgImage:string,

}

const SectionBanner: React.FC<IBgImage> = ({bgImage}) => {

  return (
    <S.Section>
      <S.ImageContainer>
      <S.ImageBanner src={bgImage}/>
      </S.ImageContainer>
      <S.Container>
        <S.BlueTitle>THE MARKETING ZONE</S.BlueTitle>
        <S.Title>
          <span className="title_italic">customized to your success</span>
        </S.Title>
        <S.Text>Explore this one-stop shop for customizable advertisements, personalized social media campaigns and strategic event planning. </S.Text>
        <S.Arrow>
        <Button
          icon="DottedArrowDown"
          iconColor={colors.yellow}
          customIconSize={66.5}
          colorOption="transparent"
          padding="none"
          />
          </S.Arrow>
      </S.Container>
    </S.Section>
  )
}

export default SectionBanner
