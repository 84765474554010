import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const CarouselVContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: larger;
  padding: 20px 0;
`

export const CarouselVImageContainer = styled.div`
  width: 50%;
  padding: 20px;
`

export const CarouselVImageContainerImage = styled.img`
  height: 100%;
  width: 100%;
`

export const CarouselVTextContainer = styled.div`
  width: 50%;
`

export const CarouselVTextContainerTitle = styled.h2`
  font-weight: bold;
  text-transform: uppercase;
  font-size: larger;
  padding: 20px 0;
`

export const CarouselVTextContainerItems = styled.div``

export const CarouselVTextContainerItem = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const CarouselVTextContainerItemArrow = styled.div`
  padding-right: 10px;
`

export const CarouselVTextContainerItemDescription = styled.div``

export const CarouselVTextContainerButtons = styled.div`
  margin-top: 20px;
`

export const ButtonLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  width: 140.99px;
  height: 49.09px;
  left: 136.28px;
  top: 168px;
  background: #337ace;
  text-transform: uppercase;
`
