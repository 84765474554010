"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useApollo = exports.initializeApollo = void 0;
var client_1 = require("@apollo/client");
var react_1 = require("react");
var apolloClient;
function createApolloClient() {
    return new client_1.ApolloClient({
        link: new client_1.HttpLink({ uri: process.env.REACT_APP_GRAPHQL_BASE_URL }),
        cache: new client_1.InMemoryCache(),
    });
}
function initializeApollo(initialState) {
    var _apolloClient = apolloClient !== null && apolloClient !== void 0 ? apolloClient : createApolloClient();
    if (initialState) {
        _apolloClient.cache.restore(initialState);
    }
    return apolloClient !== null && apolloClient !== void 0 ? apolloClient : _apolloClient;
}
exports.initializeApollo = initializeApollo;
function useApollo(initialState) {
    var store = (0, react_1.useMemo)(function () { return initializeApollo(initialState); }, [initialState]);
    return store;
}
exports.useApollo = useApollo;
