"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_1 = require("@monorepo/theme");
const S = (0, tslib_1.__importStar)(require("./styles"));
const Spinner = (_a) => {
    var { spinnerColor = theme_1.colors.purple, spinnerSize = 15 } = _a, props = (0, tslib_1.__rest)(_a, ["spinnerColor", "spinnerSize"]);
    return ((0, jsx_runtime_1.jsxs)(S.DotsContainer, Object.assign({ spinnerSize: spinnerSize, spinnerColor: spinnerColor }, props, { children: [(0, jsx_runtime_1.jsx)(S.Dot, { "data-dot": "1" }, void 0), (0, jsx_runtime_1.jsx)(S.Dot, { "data-dot": "2" }, void 0), (0, jsx_runtime_1.jsx)(S.Dot, { "data-dot": "3" }, void 0), (0, jsx_runtime_1.jsx)(S.Dot, { "data-dot": "4" }, void 0)] }), void 0));
};
exports.default = Spinner;
