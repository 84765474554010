import styled from 'styled-components'

import {
  Typography as MuiTypography,
  InputBase as MuiInputBase,
  Button as MuiButton,
} from '@material-ui/core'

import { ExpandMore as MuiExpandMoreIcon } from '@material-ui/icons'

export const Section = styled.section`
  margin: 25px 14px 0 25px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
`

export const TextContainer = styled.div`
  display: inline-flex;
  gap: 20px;
`

export const Title = styled(MuiTypography).attrs({ variant: 'h5' })`
  font-weight: bold;
  line-height: 1;
`

export const Paragraph = styled(MuiTypography)`
  font-size: 13px;
  font-family: 'ATTAleckSansBold';
`

export const InputBase = styled(MuiInputBase)`
  color: inherit;
  padding: 3px 10px;
  background-color: #f7f7f7;
  border-radius: 2px;

  .MuiInputBase-input::placeholder {
    opacity: 1;
    font-weight: bold;
    font-size: 0.85em;
  }
`

export const ButtonDropdown = styled(MuiButton)`
  justify-content: flex-start;
  background-color: white;
  padding: 0;
  border-width: 0;
  min-width: fit-content;
  margin-left: 3px;
  font-size: 14px;

  .MuiButton-label {
    color: black;
    font-size: 0.85em;
    width: fit-content;
    font-family: 'ATTAleckSansBold';
    text-transform: none;
    align-items: center;
    gap: 5px;
  }

  .MuiButton-endIcon {
    margin: 0;
  }
`

export const ExpandMoreIcon = styled(MuiExpandMoreIcon)``
