"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const S = (0, tslib_1.__importStar)(require("./styles"));
const FAQ = () => {
    const [clicked, setClicked] = (0, react_1.useState)(-1);
    const toggle = (index) => {
        if (clicked === index)
            return setClicked(-1);
        setClicked(index);
    };
    return ((0, jsx_runtime_1.jsx)(S.AccordionSection, Object.assign({ "data-testid": "faq" }, { children: (0, jsx_runtime_1.jsxs)(S.Container, { children: [(0, jsx_runtime_1.jsx)("h1", { children: "FAQs" }, void 0), DataExample.map((item, index) => {
                    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)(S.Wrap, Object.assign({ onClick: () => toggle(index), "data-testid": "faq-question" }, { children: [(0, jsx_runtime_1.jsx)("h2", { children: item.question }, void 0), (0, jsx_runtime_1.jsx)("span", { children: clicked === index ? ' - ' : ' + ' }, void 0)] }), void 0), clicked === index ? ((0, jsx_runtime_1.jsx)(S.Dropdown, Object.assign({ "data-testid": "faq-answer" }, { children: (0, jsx_runtime_1.jsx)("p", { children: item.answer }, void 0) }), void 0)) : null] }, index));
                })] }, void 0) }), void 0));
};
exports.default = FAQ;
const DataExample = [
    {
        question: 'How do I download a file?',
        answer: 'A',
    },
    {
        question: 'Can I cancel an order?',
        answer: 'B',
    },
    {
        question: 'Can I customize an order?',
        answer: 'C',
    },
    {
        question: 'What if I want to speak to someone?',
        answer: 'D',
    },
];
