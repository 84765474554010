"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledTag = exports.StyledLabel = exports.buttons = void 0;
const tslib_1 = require("tslib");
const styled_components_1 = (0, tslib_1.__importStar)(require("styled-components"));
const theme_1 = require("@monorepo/theme");
const black = `
  background-color: ${theme_1.colors.black};
  color: ${theme_1.colors.white};
`;
const white = `
  background-color: ${theme_1.colors.white};
  color: ${theme_1.colors.black};
`;
exports.buttons = {
    black: { style: black, iconColor: theme_1.colors.white },
    white: { style: white, iconColor: theme_1.colors.black },
};
exports.StyledLabel = styled_components_1.default.span `
  grid-area: label;
  line-height: 1rem;
`;
exports.StyledTag = styled_components_1.default.button `
  font-family: PFDINText;
  border-radius: 1.75em;
  cursor: pointer;
  font-size: 9pt;
  padding: 5px 10px;
  text-transform: uppercase;
  display: grid;
  align-items: center;
  line-height: 0;
  background-color: ${theme_1.colors.black};
  color: ${theme_1.colors.white};
  border: 0;
  figure {
    grid-area: icon;
  }

  ${({ colorOption }) => {
    var _a;
    return (0, styled_components_1.css) `
      ${(_a = exports.buttons[colorOption]) === null || _a === void 0 ? void 0 : _a.style}
    `;
}}

  ${({ iconPosition, showIcon, label }) => {
    if (label && label.length > 0 && showIcon) {
        if (iconPosition === 'left') {
            return (0, styled_components_1.css) `
          span {
            margin-left: 10px;
          }

          grid-template-areas: 'icon label';
        `;
        }
        else {
            return (0, styled_components_1.css) `
          span {
            margin-right: 10px;
          }

          grid-template-areas: 'label icon';
        `;
        }
    }
}}
`;
