"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var styled_components_1 = require("styled-components");
var media = {
    xs: function (args) { return (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = (0, tslib_1.__makeTemplateObject)(["\n    @media (-webkit-min-device-pixel-ratio: 2.2) and (max-device-width: 490px),\n      (min-width: 320px) and (max-width: 968px) and (orientation: landscape),\n      (max-width: 480px) {\n      ", ";\n    }\n  "], ["\n    @media (-webkit-min-device-pixel-ratio: 2.2) and (max-device-width: 490px),\n      (min-width: 320px) and (max-width: 968px) and (orientation: landscape),\n      (max-width: 480px) {\n      ", ";\n    }\n  "])), (0, styled_components_1.css)(args)); },
    md: function (args) { return (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = (0, tslib_1.__makeTemplateObject)(["\n    @media (min-width: 481px) and (max-width: 1024px) {\n      ", ";\n    }\n  "], ["\n    @media (min-width: 481px) and (max-width: 1024px) {\n      ", ";\n    }\n  "])), (0, styled_components_1.css)(args)); },
    lg: function (args) { return (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = (0, tslib_1.__makeTemplateObject)(["\n    @media (min-width: 1024px) {\n      ", ";\n    }\n  "], ["\n    @media (min-width: 1024px) {\n      ", ";\n    }\n  "])), (0, styled_components_1.css)(args)); },
    xl: function (args) { return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = (0, tslib_1.__makeTemplateObject)(["\n    @media (min-width: 1200px) {\n      ", ";\n    }\n  "], ["\n    @media (min-width: 1200px) {\n      ", ";\n    }\n  "])), (0, styled_components_1.css)(args)); },
    xxl: function (args) { return (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = (0, tslib_1.__makeTemplateObject)(["\n    @media (min-width: 1366px) {\n      ", ";\n    }\n  "], ["\n    @media (min-width: 1366px) {\n      ", ";\n    }\n  "])), (0, styled_components_1.css)(args)); },
    xxxl: function (args) { return (0, styled_components_1.css)(templateObject_6 || (templateObject_6 = (0, tslib_1.__makeTemplateObject)(["\n    @media (min-width: 1600px) {\n      ", ";\n    }\n  "], ["\n    @media (min-width: 1600px) {\n      ", ";\n    }\n  "])), (0, styled_components_1.css)(args)); },
};
exports.default = media;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
