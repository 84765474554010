"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appInsights = exports.reactPlugin = void 0;
var applicationinsights_web_1 = require("@microsoft/applicationinsights-web");
var applicationinsights_react_js_1 = require("@microsoft/applicationinsights-react-js");
var reactPlugin = new applicationinsights_react_js_1.ReactPlugin();
exports.reactPlugin = reactPlugin;
/**
 *
 * @param history - Is browser history
 * @param instrumentationKey - Is your app insights instrumentation key API key.
 */
var appInsights = function (_a) {
    var _b;
    var history = _a.history, instrumentationKey = _a.instrumentationKey;
    return new applicationinsights_web_1.ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: (_b = {},
                _b[reactPlugin.identifier] = { history: history },
                _b),
        },
    });
};
exports.appInsights = appInsights;
