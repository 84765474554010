"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Filter = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const S = (0, tslib_1.__importStar)(require("./styles"));
const react_i18next_1 = require("react-i18next");
const molecules_1 = require("../../molecules");
const infra_1 = require("@monorepo/infra");
const core_1 = require("@material-ui/core");
const useStyles = (0, core_1.makeStyles)((theme) => (0, core_1.createStyles)({
    root: {
        width: 256,
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
    },
}));
const Filter = ({ filterOpen }) => {
    const classes = useStyles();
    const { t } = (0, react_i18next_1.useTranslation)('productsList');
    const { selectedItems, setState } = (0, react_1.useContext)(infra_1.ItemsContext);
    const clearFilters = () => {
        setState({
            type: infra_1.ActionType.ClearItems,
        });
    };
    const handleDeleteChip = (item) => {
        setState({
            type: infra_1.ActionType.SetItem,
            payload: { item },
        });
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(core_1.Slide, Object.assign({ "data-testid": "filter", direction: "right", in: filterOpen, mountOnEnter: true, unmountOnExit: true }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: classes.root }, { children: (0, jsx_runtime_1.jsxs)(core_1.List, Object.assign({ component: "nav", "aria-label": "main mailbox folders" }, { children: [(0, jsx_runtime_1.jsxs)(S.Item, { children: [(0, jsx_runtime_1.jsx)(S.Subtitle, { children: t('filter.applied_filters') }, void 0), (0, jsx_runtime_1.jsx)(S.Link, Object.assign({ onClick: clearFilters }, { children: t('filter.clear_button') }), void 0)] }, void 0), (0, jsx_runtime_1.jsx)(S.ChipContainer, { children: selectedItems &&
                                selectedItems.items.map((item, key) => {
                                    return ((0, jsx_runtime_1.jsx)(S.Chip, { label: item, onDelete: () => {
                                            handleDeleteChip(item);
                                        }, deleteIcon: (0, jsx_runtime_1.jsx)(core_1.IconButton, { children: (0, jsx_runtime_1.jsx)(S.Icon, { icon: "Close", size: 18 }, void 0) }, void 0) }, key));
                                }) }, void 0), (0, jsx_runtime_1.jsx)(molecules_1.FilterColapse, { title: t('filter.category'), dropdownItems: t('filter.category_expanded', {
                                returnObjects: true,
                            }) }, void 0), (0, jsx_runtime_1.jsx)(molecules_1.FilterColapse, { title: t('filter.ad_type'), dropdownItems: t('filter.ad_expanded', { returnObjects: true }) }, void 0), (0, jsx_runtime_1.jsx)(molecules_1.FilterColapse, { title: t('filter.main_message'), dropdownItems: t('filter.main_expanded', { returnObjects: true }) }, void 0), (0, jsx_runtime_1.jsx)(molecules_1.FilterColapse, { title: t('filter.media_type'), dropdownItems: t('filter.media_expanded', {
                                returnObjects: true,
                            }) }, void 0), (0, jsx_runtime_1.jsx)(molecules_1.FilterColapse, { title: t('filter.size_length'), dropdownItems: t('filter.size_expanded', { returnObjects: true }) }, void 0), (0, jsx_runtime_1.jsx)(molecules_1.FilterColapse, { title: t('filter.language'), dropdownItems: t('filter.language_expanded', {
                                returnObjects: true,
                            }) }, void 0)] }), void 0) }), void 0) }), void 0) }, void 0));
};
exports.Filter = Filter;
exports.default = exports.Filter;
