import styled from 'styled-components'

const ProductList = styled.section`
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;

  @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
  }
  
  @media (min-width: 1440px) {
      grid-template-columns: repeat(4, 1fr);
  }
  
  @media (min-width: 1920px) {
      grid-template-columns: repeat(6, 1fr);
`

const Card = styled.article`
  font-family: PFDINText;
  font-style: normal;
  font-weight: 400;
  height: 361px;
  letter-spacing: -0.20000000298023224px;
  overflow: hidden;
  text-align: center;
  width: 1fr;
  padding: 5px;

  & > h2 {
    font-size: 20px;
    line-height: 20px;
    margin-top: 16px;
    text-transform: uppercase;
  }

  & > p.expires {
    font-size: 12px;
    line-height: 12px;
    margin-top: 6px;
  }

  & > p.sizes {
    color: #337ace;
    font-size: 12px;
    line-height: 12px;
    margin-top: 6px;
  }
`

const CustomCarousel = styled.div`
  background-color: #f1f2f4;
  height: 362px;
  overflow: hidden;

  & > img {
    height: 100%;
  }
`

const HighlightCard = styled.article`
  width: 2fr;
`

const NoData = styled.h2`
  text-align: center;
  width: 100%;
`

export { Card, HighlightCard, ProductList, CustomCarousel, NoData }
