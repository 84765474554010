import { StylesConfig } from 'react-select'
import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export type SortType = {
  label: string
  value: string
}


const InfiniteScrollContainer = styled.div`
  & div.infinite-scroll-component {
    overflow-y: hidden !important;
  }
`

const SearchInfo = styled.div`
  padding: 47px 0 47px 39px;
  font-family: PFDINText;
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
  color: ${colors.black};
`

export {
  InfiniteScrollContainer,
  SearchInfo,
}
