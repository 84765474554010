import { SectionHomeProps } from '@monorepo/interfaces'
import { colors } from '@monorepo/theme'
import styled from 'styled-components'

interface SectionProps {
  marginBottom: string
}

export const Section = styled.section<SectionProps>`
  display: grid;
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom};
`

export const Container = styled.div<SectionHomeProps>`
  gap: 30px;
  padding: 10vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(${(props) => (props.bgImage ? props.bgImage : 'none')});
  background-color: ${colors.dark};
  background-repeat: no-repeat;
  background-size: cover;
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
`

export const Heading = styled.span`
  color: ${colors.white};
  font-size: 27pt;
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: 426px) {
    font-size: 2rem;
  }
`

export const Title = styled.h1`
  color: ${colors.white};
  font-size: 41.25pt;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  font-family: Jubilat;

  @media (max-width: 426px) {
    font-size: 2rem;
  }
`

export const Content = styled.p`
  font-size: 15pt;
  font-weight: 400;
  color: ${colors.white};
  margin-bottom: 165px;

  @media (max-width: 426px) {
    font-size: 1rem;
  }
`
export const Buttons = styled.div`
  display: flex;
  gap: 20px;
`
export const Featured = styled.div`
  display: flex;
  background: ${colors.white};
  padding: 10px 0;
`

export const FeaturedTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 50px;
  place-items: center;
  gap: 45px;
  width: 100%;
`

export const FeaturedTitle = styled.div`
  color: ${colors.black};
  font-size: 27pt;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  font-family: Jubilat;
`

export const FeaturedTitleDetails = styled.h2`
  font-family: Jubilat;
  font-size: 36px;
  font-style: italic;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0px;
  text-align: center;
  width: 100%;
`

export const Arrows = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: auto;
  margin-right: 100px;
`
