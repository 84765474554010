"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterProvider = void 0;
var tslib_1 = require("tslib");
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var context_1 = require("./context");
var reducer_1 = require("./reducer");
var state_1 = require("./state");
var FilterProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useReducer)(reducer_1.itemsReducer, state_1.initialState), selectedItems = _b[0], dispatch = _b[1];
    return ((0, jsx_runtime_1.jsx)(context_1.ItemsContext.Provider, (0, tslib_1.__assign)({ value: { selectedItems: selectedItems, setState: dispatch } }, { children: children }), void 0));
};
exports.FilterProvider = FilterProvider;
