import styled from 'styled-components'

// import { TextInput as MTextInput } from '@monorepo/components'
import { Field } from 'formik'
import Select from 'react-select'

export const Container = styled.main`
  background-color: #e5e5e5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 100px;
  position: relative;
  max-width: 1064px;
`

export const MainContainer = styled.main`
  background-color: #102641;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 140px;
  position: relative;
  width: 100%;
`

export const Wrapper = styled.section`
  & h1 {
    font-size: 42px;
  }
`

interface RowProps {
  marginBetween?: number
}

export const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;

  margin: 15px 0;
  > * {
    &:first-child {
      margin-right: ${({ marginBetween }) => marginBetween ?? '16'}px;
    }
  }
`

export const Form = styled.form`
  margin-top: 25px;
`
export const TextInput = styled(Field)`
  flex: 1;
  max-width: 845px;
  width: 845px;
  height: 71px;
  background: #ffffff;
  border: 1px solid #dcdfe2;
  box-sizing: border-box;
  font-family: 'PFDINText';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  padding: 24px;
  margin: 0px !important;
`

export const SelectInput = styled(Select)`
  display: flex;
  flex: 1;
  width: 100%;
  & -control {
    flex: 1;
  }

  & > div {
    width: 100%;
    min-height: 70px;
    border-radius: 0;
  }
`

export const TextInputArea = styled.textarea`
  flex: 1;
  max-width: 845px;
  width: 845px;
  height: 300px;
  background: #ffffff;
  border: 1px solid #dcdfe2;
  box-sizing: border-box;
  font-family: 'PFDINText';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  padding: 24px;
  margin: 0px !important;
  resize: none;
`

export const Title = styled.h1`
  font-family: PFDINText;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px;
`

export const WrapperOptions = styled.section`
  display: flex;
  gap: 16px;
  margin: 16px 0;
`

export const SubmitButton = styled.button`
  background: #337ace;
  border: none;
  color: #f5f5f5;
  cursor: pointer;
  font-family: PFDINText;
  font-size: 16px;
  height: 50px;
  text-transform: uppercase;
  width: 123px;

  &:hover {
    background: black;
  }
`

export const ButtonWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  margin: 35px 0;
`
