"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var web_1 = require("@react-keycloak/web");
function PrivateRoute(_a) {
    var component = _a.component, keyCloakNotInitializedChildren = _a.keyCloakNotInitializedChildren, notAuthenticatedChildren = _a.notAuthenticatedChildren, rest = (0, tslib_1.__rest)(_a, ["component", "keyCloakNotInitializedChildren", "notAuthenticatedChildren"]);
    var _b = (0, web_1.useKeycloak)(), keycloak = _b.keycloak, initialized = _b.initialized;
    var location = (0, react_router_dom_1.useLocation)();
    (0, react_1.useEffect)(function () {
        if (initialized && !keycloak.authenticated) {
            window.location.href = keycloak.createLoginUrl();
        }
    }, [location, initialized, keycloak]);
    if (!initialized)
        return keyCloakNotInitializedChildren !== null && keyCloakNotInitializedChildren !== void 0 ? keyCloakNotInitializedChildren : null;
    return keycloak.authenticated ? ((0, jsx_runtime_1.jsx)(react_router_dom_1.Route, (0, tslib_1.__assign)({}, rest, { component: component }), void 0)) : (notAuthenticatedChildren !== null && notAuthenticatedChildren !== void 0 ? notAuthenticatedChildren : null);
}
exports.default = PrivateRoute;
