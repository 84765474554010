import * as S from './styles'

import React, { useEffect, useState } from 'react'
import { CategoryItem, SelectFilter } from '../../molecules'
import Select, { SingleValue } from 'react-select'
import { DateRange } from 'react-date-range'
import { colors } from '@monorepo/theme'
import { Icon } from '@monorepo/components'

interface EventsHeroCreative{
  setSearchByDate:(date:string)=>void
  clearSearchByDate: ()=> void
}

interface AdHeroCreative{
  categoryList: CategoryItem[] | undefined
  initialCategories: string[] |undefined
  sortItem: SingleValue<S.SortType> | undefined
  setSortItem: (item: SingleValue<S.SortType>) => void | undefined
  setCategories: (list: string[]) => void |undefined
}

interface HeroCreativeProps {
  background?: {
    desk?: string
    mobile?: string
  }
  title: string
  description?: string
  placeholder?: string
  searchTerm?: string
  setSearchTerm?: (searchTerm: string) => void
  clearSearchTerm?: () => void
  color?: string
  backgroundColor?: string
  flexFlow?: string
  resetMinHeight?: boolean
  isEventPage?:boolean
  eventProps?: EventsHeroCreative
  isAdPage?:boolean
  adProps?:AdHeroCreative
}


const sortItemsList = [
  {
    value: 'asc',
    label: 'Title, A-Z',
  },
  {
    value: 'desc',
    label: 'Title, Z-A',
  },
]


function formatDate(date: Date) {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()

  return `${month} / ${day} / ${year}`
}


interface RangesProps {
  startDate?: Date | undefined
  endDate?: Date | undefined
  key?: string | undefined
}


const HeroCreative: React.FC<HeroCreativeProps> = ({
  background,
  title,
  description,
  placeholder,
  searchTerm,
  setSearchTerm,
  clearSearchTerm,
  color,
  backgroundColor,
  flexFlow,
  resetMinHeight,
  isAdPage=false,
  eventProps,
  isEventPage=false,
  adProps
}) => {

  const [ranges, setRanges] = useState<RangesProps[]>([
    {
      startDate: undefined,
      endDate: undefined,
      key: 'events',
    },
  ])

  const clearDate = () => {
    setRanges([
      {
        startDate: undefined,
        endDate: undefined,
        key: 'events',
      },
    ])
    isEventPage && eventProps?.clearSearchByDate()
  }

  useEffect(() => {
    if (ranges[0].startDate && ranges[0].endDate) {
      const startDate = encodeURIComponent(ranges[0].startDate.toISOString())
      const endDate = encodeURIComponent(ranges[0].endDate.toISOString())

      const qs = `&startDate=${startDate}&endDate=${endDate}`

      isEventPage && eventProps?.setSearchByDate(qs)
    }
  }, [ranges, eventProps?.setSearchByDate])


  return (
    <S.Hero
      color={color}
      backgroundColor={backgroundColor}
      flexFlow={flexFlow}
      resetMinHeight={resetMinHeight}
    >
      {!!title ? <h1>{title}</h1> : undefined}
      {!!description ? <p>{description}</p> : undefined}
      <S.Filter>
        {setSearchTerm && clearSearchTerm && (
          <S.Search
            placeholder={placeholder}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e:any) => setSearchTerm(e.target.value)}
            value={searchTerm}
            onClear={clearSearchTerm}
            color={colors.grey}
            backgroundColor={backgroundColor}
          />
        )}
        {isEventPage&&
          <S.SelectDate>
            <S.SelectDateButton>
              <label><Icon size={18} icon='Calendar' hasFill={false} color={colors.darkBlue} /></label>
              {ranges[0].startDate ? (
                <>
                  <S.DateText>{formatDate(ranges[0].startDate)}</S.DateText>
                  <S.DateText>-</S.DateText>
                </>
              ) : (
                <S.DateText className="date">Date</S.DateText>
              )}
              {ranges[0].endDate && (
                <>
                  <S.DateText>{formatDate(ranges[0].endDate)}</S.DateText>
                </>
              )}
              <label><Icon icon="ArrowDown" hasStroke={false} size={24} /></label>
            </S.SelectDateButton>
            <S.SelectDateContent>
              <DateRange
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                ranges={ranges}
                onChange={(newRanges) => setRanges([newRanges.events])}
                rangeColors={['#232425']}
              />
            </S.SelectDateContent>
          </S.SelectDate>}
      </S.Filter>
        {isAdPage &&
        (<S.FilterWrap>
          {adProps?.categoryList && (
            <SelectFilter
              name="Filter by category"
              items={adProps.categoryList}
              initialItems={adProps.categoryList.filter((category) =>
                (adProps?.initialCategories && adProps?.initialCategories.length > 0) &&
                adProps?.initialCategories.includes(category.name)
              )}
              selectItem={adProps?.setCategories}
              clearable={true}
            />
          )}
          <Select
            name="sort"
            placeholder="Sort by"
            options={sortItemsList}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={adProps?.sortItem}
            onChange={(newValue) => adProps?.setSortItem(newValue)}
            styles={S.MultiSelectStyle}
          />
          </S.FilterWrap>)}
    </S.Hero>
  )
}

export default HeroCreative
