import * as S from './styles'
import * as Yup from 'yup'

import { Button, Input, Select } from '@monorepo/components'
import { Field, Form, Formik, setNestedObjectValues } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'

import Arrow from '../../../assets/registration/arrow.svg'
import Image from '../../../assets/registration/left-side.png'
import {
  httpUserInfo,
  httpDialogDirect,
  ChannelHelper,
  KeycloakHelper,
} from '@monorepo/infra'
import { useHistory } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { DialogDirectAddCustomerRequest } from '@monorepo/interfaces'

interface MasterDealer {
  _id: string
  channels: string[]
  billingAddresses?: {
    address: string
    city: string
    state: string
    zipCode: string
    default: boolean
  }
  shippingAddresses?: {
    address: string
    city: string
    state: string
    zipCode: string
    default: boolean
  }
  corpId: string
  masterDealerId: string
  contractId: string
  name: string
  __v: number
}

interface AssociatedMasterDealer {
  masterDealerId: string
  masterDealer: MasterDealer[]
  channels: string[]
  dealerTypes: string[]
  corpId: string
}

interface Dealer {
  _id: string
  associatedMasterDealers: AssociatedMasterDealer[]
  userId: string[]
  __v: number
}

export interface RegistrationUserResponse {
  _id: string
  keycloakUserId: string
  groups: string[]
  phoneNumber?: string
  email: string
  contactEmail: string
  contactPhone: string
  preferredContactMethod: string
  lastName: string
  firstName: string
  __v: number
  dealer: Dealer
}

type RegistrationUserPayload = {
  _id: string
  userId: string
  firstNAme: string
  lastName: string
  password: string
  email: string
  associatedMasterDealers: {
    masterDealerId: string
  }[]
}

interface RegistrationUserForm {
  contactEmail: string
  contactPhone: string
  preferredContactMethod: string
  lastName: string
  password: string
  firstName: string
}

interface ValidateEmailForm {
  email: string
}

const contactMethods = [
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone' },
]

const ValidateEmailSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter your e-mail')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i, 'Invalid email.'),
})

const RegistrationUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name.'),
  lastName: Yup.string().required('Please enter your last name.'),
  password: Yup.string()
    .required('Please enter your password.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  contactEmail: Yup.string()
    .required('Please enter a contact email.')
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i,
      'Invalid contact email.'
    ),
  contactPhone: Yup.string()
    .required('Please enter a contact phone.')
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i,
      'Invalid contact phone number.'
    ),
  preferredContactMethod: Yup.string().required(
    'Please select a preferred contact method.'
  ),
})

const REGISTRATION_STEPS = {
  EMAIL: 'EMAIL',
  REGISTER: 'REGISTER',
}

const Registration: React.FC = () => {
  const [registrationStep, setRegistrationStep] = useState(
    REGISTRATION_STEPS.EMAIL
  )
  const [emailErrorMessage, setEmailErrorMessage] = useState('')

  const [registrationUserPayload, setRegistrationUserPayload] =
    useState<Partial<RegistrationUserPayload> | null>(null)

  const [dialogDirectAddCustomerPayload, setDialogDirectAddCustomerPayload] =
    useState<Partial<DialogDirectAddCustomerRequest> | null>(null)

  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    undefined
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { initialized, keycloak } = useKeycloak()
  const history = useHistory()

  const user = KeycloakHelper.getTokenParsed(keycloak)

  const getUserInfoByEmail = useCallback((formValues: ValidateEmailForm) => {
    const { email } = formValues

    if (!email) return

    setIsSubmitting(true)

    httpUserInfo
      .get({ url: `/dealer/${email}` })
      .then((response) => {
        const registrationUser = response.data as RegistrationUserResponse

        const billingAddresses =
          registrationUser?.dealer?.associatedMasterDealers?.[0]
            ?.masterDealer?.[0]?.billingAddresses

        const channels = ChannelHelper.getChannels(
          registrationUser?.dealer?.associatedMasterDealers
        )

        if (billingAddresses !== null || !channels.length) {
          setEmailErrorMessage('Error fetching information for this e-mail.')
          return
        }

        const { _id, email, dealer } = registrationUser

        setRegistrationUserPayload({
          _id,
          email: email.trim(),
          userId: dealer?.userId?.[0],
          associatedMasterDealers: [
            {
              masterDealerId: dealer.associatedMasterDealers[0].masterDealerId,
            },
          ],
        })
        setDialogDirectAddCustomerPayload({
          companyName: '',
          emailAddress: email.trim(),
          channel: channels?.[0]?.toUpperCase(),
          multiCorpId: channels
            .map((item) =>
              ChannelHelper.getMasterDealerId(
                dealer.associatedMasterDealers[0].masterDealerId,
                item.toUpperCase()
              )
            )
            .join('|'),
          corpId: ChannelHelper.getMasterDealerId(
            dealer.associatedMasterDealers[0].masterDealerId,
            channels?.[0]?.toUpperCase()
          ),
          address1: 'address1',
          addressee: '',
          city: 'Palo Alto',
          zip: '94301',
          state: 'CA',
          phoneNumber: '',
          attention: '',
        })
        setRegistrationStep(REGISTRATION_STEPS.REGISTER)
      })
      .catch()
      .finally(() => {
        setIsSubmitting(false)
      })
  }, [])

  const registerUser = useCallback(
    (formValues: RegistrationUserForm) => {
      const {
        password,
        firstName,
        lastName,
        contactEmail,
        contactPhone,
        preferredContactMethod,
      } = formValues

      const registrationPayload = {
        ...registrationUserPayload,
        password,
        firstName: firstName,
        lastName,
        contactEmail,
        contactPhone,
        preferredContactMethod,
        associatedMasterDealers: (
          registrationUserPayload?.associatedMasterDealers as RegistrationUserPayload['associatedMasterDealers']
        ).map(({ masterDealerId }) => ({
          masterDealerId,
        })),
      } as RegistrationUserPayload

      const dialogDirectAddCustomerRequest = {
        ...dialogDirectAddCustomerPayload,
        firstName,
        lastName,
      } as DialogDirectAddCustomerRequest

      setErrorMessage(undefined)
      setIsSubmitting(true)

      httpUserInfo
        .post({ url: `/registration`, data: registrationPayload })
        .then((response) => {
          httpDialogDirect
            .post({
              url: '/AddCustomer',
              data: dialogDirectAddCustomerRequest,
            })
            .then(() => {
              history.push('/')
            })
            .catch((error) => {
              console.error(error)
              setErrorMessage('AddCustomer error')
              setIsSubmitting(false)
            })
        })
        .catch((error) => {
          setErrorMessage('Registration error')
          setIsSubmitting(false)
        })
    },
    [history, registrationUserPayload, dialogDirectAddCustomerPayload]
  )

  const handleSubmit = useCallback(
    (values: ValidateEmailForm | RegistrationUserForm) => {
      if (registrationStep === REGISTRATION_STEPS.EMAIL) {
        getUserInfoByEmail(values as ValidateEmailForm)
        return
      }

      registerUser(values as RegistrationUserForm)
    },
    [getUserInfoByEmail, registerUser, registrationStep]
  )

  useEffect(() => {
    if (initialized && user) {
      history.push('/')
    }
  }, [history, initialized, user])

  if (!initialized) return null

  return (
    <S.Wrapper>
      <S.LeftSide background={Image}>
        <S.Arrow src={Arrow}></S.Arrow>
      </S.LeftSide>
      <S.RightSide>
        <S.TextContainer>
          <S.Title>Welcome to the Marketing ZONE!</S.Title>
          <S.Subtitle>
            With your business in mind, we've improved functionality,
            streamlined checkout and taken the guesswork out of growing your
            business.
            <br />
            <br />
            Complete your registration now to get in the ZONE!
          </S.Subtitle>
        </S.TextContainer>
        <S.Card>
          <Formik
            initialValues={
              registrationStep === REGISTRATION_STEPS.EMAIL
                ? { email: '' }
                : {
                    contactEmail: '',
                    contactPhone: '',
                    preferredContactMethod: 'email',
                    lastName: '',
                    password: '',
                    firstName: '',
                  }
            }
            validationSchema={
              registrationStep === REGISTRATION_STEPS.EMAIL
                ? ValidateEmailSchema
                : RegistrationUserSchema
            }
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
              handleSubmit(values)
              actions.setSubmitting(false)
            }}
          >
            {({
              errors,
              touched,
              values,
              submitForm,
              setFieldValue,
              setTouched,
              setFieldTouched,
              validateForm,
            }) => (
              <Form>
                <S.FormTitle>
                  {registrationStep === REGISTRATION_STEPS.EMAIL
                    ? 'Enter your e-mail'
                    : 'Complete your registration'}
                </S.FormTitle>
                <S.Wrap>
                  {registrationStep === REGISTRATION_STEPS.EMAIL ? (
                    <S.Column>
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        component={Input}
                        invalid={
                          emailErrorMessage || (touched.email && errors.email)
                        }
                        invalidMessage={emailErrorMessage || errors.email}
                        label="E-mail"
                        onBlur={() => setFieldTouched('email')}
                        onChange={(option: { target: { value: string } }) => {
                          setFieldValue('email', option.target.value)
                        }}
                      />
                    </S.Column>
                  ) : (
                    <S.Column>
                      <S.Row>
                        <Field
                          id="firstName"
                          name="firstName"
                          component={Input}
                          invalid={touched.firstName && errors.firstName}
                          invalidMessage={errors.firstName}
                          label="First Name"
                          onBlur={() => setFieldTouched('firstName')}
                          onChange={(option: { target: { value: string } }) => {
                            setFieldValue('firstName', option.target.value)
                          }}
                        />

                        <Field
                          id="lastName"
                          name="lastName"
                          component={Input}
                          invalid={touched.lastName && errors.lastName}
                          invalidMessage={errors.lastName}
                          label="Last Name"
                          onBlur={() => {
                            setFieldTouched('lastName')
                          }}
                          onChange={(option: { target: { value: string } }) => {
                            setFieldValue('lastName', option.target.value)
                          }}
                        />
                      </S.Row>

                      <S.Column>
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          component={Input}
                          invalid={touched.password && errors.password}
                          invalidMessage={errors.password}
                          label="Create Password"
                          onBlur={() => {
                            setFieldTouched('password')
                          }}
                          onChange={(option: { target: { value: string } }) => {
                            setFieldValue('password', option.target.value)
                          }}
                        />

                        <Field
                          id="contactEmail"
                          name="contactEmail"
                          component={Input}
                          invalid={touched.contactEmail && errors.contactEmail}
                          invalidMessage={errors.contactEmail}
                          label="Contact Email"
                          onBlur={() => {
                            setFieldTouched('contactEmail')
                          }}
                          onChange={(option: { target: { value: string } }) => {
                            setFieldValue('contactEmail', option.target.value)
                          }}
                        />

                        <Field
                          id="contactPhone"
                          name="contactPhone"
                          component={Input}
                          invalid={touched.contactPhone && errors.contactPhone}
                          invalidMessage={errors.contactPhone}
                          label="Contact Phone"
                          onBlur={() => {
                            setFieldTouched('contactPhone')
                          }}
                          onChange={(option: { target: { value: string } }) => {
                            setFieldValue('contactPhone', option.target.value)
                          }}
                        />

                        <Field
                          id="preferredContactMethod"
                          name="preferredContactMethod"
                          component={Select}
                          options={contactMethods}
                          invalid={
                            touched.preferredContactMethod &&
                            errors.preferredContactMethod
                          }
                          invalidMessage={errors.preferredContactMethod}
                          label="Preferred Contact Method"
                          value={values.preferredContactMethod}
                          onChange={(option: { target: { value: string } }) => {
                            setFieldValue(
                              'preferredContactMethod',
                              option.target.value
                            )
                          }}
                        />
                      </S.Column>
                    </S.Column>
                  )}
                </S.Wrap>
                <Button
                  colorOption={isSubmitting ? 'grey' : 'black'}
                  label={
                    registrationStep === REGISTRATION_STEPS.EMAIL
                      ? 'VERIFY YOUR E-MAIL'
                      : 'COMPLETE REGISTRATION'
                  }
                  onClick={() => {
                    validateForm().then((errors) => {
                      setTouched(setNestedObjectValues(errors, true))
                    })

                    submitForm()
                  }}
                  disabled={isSubmitting}
                />
                {!!errorMessage ? (
                  <S.ErrorContainer>
                    <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
                  </S.ErrorContainer>
                ) : undefined}
              </Form>
            )}
          </Formik>
        </S.Card>
        <S.Footer>
          <img src="/images/logo-dark.svg" alt="logo" />
        </S.Footer>
      </S.RightSide>
    </S.Wrapper>
  )
}

export default Registration
