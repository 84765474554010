"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEventTypeDescription = exports.getEventTypeOptions = exports.getImage = exports.getImageFile = exports.getLocation = void 0;
var interfaces_1 = require("@monorepo/interfaces");
var getLocation = function (event) {
    return "".concat(event.city, ", ").concat(event.state, " ").concat(event.zipCode);
};
exports.getLocation = getLocation;
var getImageFile = function (category) {
    switch (category) {
        //Food & beverage
        case 'Grocery':
        case 'Food Beverage':
        case 'Supermarkets':
        case 'Food and Beverage':
            return '/images/events/foodAndBeverage.jpg';
        //Outdoor festival
        case 'Home and Garden':
        case 'Home & Garden':
        case 'Farm and Agriculture':
        case 'Stock Show & Rodeo':
        case 'Festival':
            return '/images/events/outdoorFestival.jpg';
        //Fair
        case 'Fair and Festival':
        case 'State Fairs':
        case 'Trade Show':
        case 'Auto Show':
            return '/images/events/fair.jpg';
        //Kiosk/booth
        case 'HEB Events':
        case 'Kiosk':
        case 'Sponsorships':
            return '/images/events/kiosk.jpg';
        //Arts
        case 'Bridal Expos':
        case 'Music & Arts':
            return '/images/events/art.jpg';
        //Default
        default:
            return '/images/events/deafult.jpg';
    }
};
exports.getImageFile = getImageFile;
var getImage = function (event) {
    return Array.isArray(event === null || event === void 0 ? void 0 : event.media) && event.media.length
        ? event.media[0]
        : "".concat(process.env.PUBLIC_URL).concat((0, exports.getImageFile)(Array.isArray(event === null || event === void 0 ? void 0 : event.categories) ? event.categories[0] : undefined));
};
exports.getImage = getImage;
var getEventTypeOptions = function () { return [
    { value: interfaces_1.EventType.FAIR_AND_FESTIVAL.toString(), label: 'Festival' },
    { value: interfaces_1.EventType.GROCERY.toString(), label: 'Grocery' },
    { value: interfaces_1.EventType.KIOSK.toString(), label: 'Kiosk' },
    { value: interfaces_1.EventType.AUTO_SHOW.toString(), label: 'Auto Show' },
    { value: interfaces_1.EventType.BRIDAL_EXPOS.toString(), label: 'Bridal Expos' },
    {
        value: interfaces_1.EventType.FARM_AND_AGRICULTURE.toString(),
        label: 'Farm and Agriculture',
    },
    { value: interfaces_1.EventType.FOOD_BEVERAGE.toString(), label: 'Food Beverage' },
    { value: interfaces_1.EventType.HEB_EVENTS.toString(), label: 'HEB Events' },
    { value: interfaces_1.EventType.HOME_AND_GARDEN.toString(), label: 'Home & Garden' },
    { value: interfaces_1.EventType.MDU.toString(), label: 'MDU' },
    { value: interfaces_1.EventType.MISCELLANEOUS.toString(), label: 'Miscellaneous' },
    { value: interfaces_1.EventType.MUSIC_AND_ARTS.toString(), label: 'Music & Arts' },
    { value: interfaces_1.EventType.SPONSORSHIPS.toString(), label: 'Sponsorships' },
    { value: interfaces_1.EventType.STATE_FAIRS.toString(), label: 'State Fairs' },
    {
        value: interfaces_1.EventType.STOCK_SHOW_AND_RODEO.toString(),
        label: 'Stock Show & Rodeo',
    },
    { value: interfaces_1.EventType.SUPERMARKETS.toString(), label: 'Supermarkets' },
    { value: interfaces_1.EventType.TRADE_SHOW.toString(), label: 'Trade Show' },
]; };
exports.getEventTypeOptions = getEventTypeOptions;
var getEventTypeDescription = function (type) {
    if (!type) {
        return 'Unknown';
    }
    var option = (0, exports.getEventTypeOptions)().find(function (item) { return item.value === type.toString(); });
    return option === null || option === void 0 ? void 0 : option.label;
};
exports.getEventTypeDescription = getEventTypeDescription;
