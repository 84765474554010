import * as S from './styles'
import { Button, Icon, Messages, PageSpinner } from '@monorepo/components'
import {
  ChannelHelper,
  OrderHelper,
  http,
  orderPaymentMachine,
  useChannel,
  OrderItemStatus,
  OrderPaymentType,
  OrderStatus,
  KeycloakHelper,
  UserHelper,
  OrderPaymentStatus,
} from '@monorepo/infra'
import {
  IPayment,
  Order,
  OrderAddress,
  OrderItem,
  OrderItemMessage,
  Payment,
  History,
  TrackingInformation,
} from '@monorepo/interfaces'
import { Link, useHistory } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import {
  SectionCheckoutConfirmation,
  SectionCheckoutCoop,
  SectionCheckoutPaymentEdit,
  SectionCheckoutTotalReview,
  SectionCheckoutShippingEdit,
} from '../../organisms/SectionOrderPayment'
import { useKeycloak } from '@react-keycloak/web'
import { useMachine } from '@xstate/react'
import cloneDeep from 'lodash/cloneDeep'

interface ItemTrackingInformation extends TrackingInformation {
  item: OrderItem
}

type OrderDetailsProps = {
  order?: Order
  onAddMessage: (index: number, message: OrderItemMessage) => void
}

const getPaymentType = (status: number | undefined): string => {
  switch (status) {
    case OrderPaymentType.CREDIT_CARD:
      return 'Credit Card'
    case OrderPaymentType.COOP:
      return 'Co-Op'
  }
  return 'Unknown'
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const getCoopAmount = (order: Order): number => {
  const usedCoop = order?.payments.some(
    (payment: Payment) => payment.type === 2
  )
  return usedCoop
    ? order?.payments?.length > 0
      ? order?.payments
          .map((payment: Payment) =>
            payment.type === 2 ? parseFloat(payment.amount) : 0
          )
          .reduce((a: number, b: number) => a + b, 0)
      : 0
    : 0
}

const getCardAmount = (order: Order): number => {
  const cardPayments = order?.payments.map((payment: Payment) =>
    payment.type === 1 ? parseFloat(payment.amount) : 0
  )
  return cardPayments.reduce((a: number, b: number) => a + b, 0)
}

const getSubtotal = (orderItems: OrderItem[] | undefined): number => {
  if (!orderItems) {
    return 0
  }
  let subTotal = 0
  orderItems.forEach((item) => {
    subTotal += Number(item.price.replace(/[^0-9.-]+/g, ''))
  })
  return subTotal
}

function getFormattedDate(value: string) {
  const date = new Date(value)
  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month

  let day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  return month + '/' + day + '/' + year
}

const OrderDetails: React.FC<OrderDetailsProps> = ({
  order = undefined,
  onAddMessage,
}) => {
  const history = useHistory()
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)
  const { selectedChannel, selectedMasterDealerId, hasCoop, coop, groups } =
    useChannel()
  const [showHistory, setShowHistory] = useState<boolean>(false)
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [orderItemsMessages, setOrderItemsMessages] = useState<
    OrderItemMessage[][]
  >([])
  const [isCoopRequest, setIsCoopRequest] = useState<boolean>(false)

  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [state, send] = useMachine(orderPaymentMachine, {
    context: { hasCoop, isCoopRequest: false, isApprovedCoopRequest: false },
  })
  const [loading, setLoading] = useState(false)
  const [isUsingCoop, setIsUsingCoop] = useState(false)
  const [error, setError] = useState('')
  const [coopSubtotal, setCoopSubtotal] = useState<number>(0)
  const [loadingCoop, setLoadingCoop] = useState(true)
  const [hasError, setHasError] = useState(false)
  const isUserAdmin = UserHelper.isDealerAdmin(groups)
  const displayContactInfosInp = order
    ? order.items
      ? order?.items.some(
          (x: OrderItem) => x.product.selectedVariant?.deliveryMethod !== 1
        )
      : false
    : false
  const displayShippingAddressInp = order
    ? order.items
      ? order?.items.some(
          (x: OrderItem) => x.product.selectedVariant?.deliveryMethod == 1
        )
      : true
    : true
  useEffect(() => {
    const setHasCoop = async () => {
      send({ type: 'SETHASCOOP', hasCoop })
    }
    setHasCoop()
  }, [hasCoop, send])

  useEffect(() => {
    if (!state.context.isApprovedCoopRequest && state.context.isCoopRequest) {
      return
    }

    setLoadingCoop(true)
    OrderHelper.getCoopTotalForOrderV2(
      ChannelHelper.getMasterDealerId(selectedMasterDealerId, selectedChannel),
      order ? order.items : []
    )
      .then((itemsWithCoop) => {
        setCoopSubtotal(OrderHelper.getItemsCoopTotal(itemsWithCoop))
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoadingCoop(false)
      })
  }, [selectedMasterDealerId, selectedChannel, order])

  const processPayment = async (
    orderNumber: number,
    orderItems: OrderItem[],
    thePayment: IPayment,
    orderId?: string
  ) => {
    const masterDealerId = ChannelHelper.getMasterDealerId(
      selectedMasterDealerId,
      selectedChannel
    )
    let payments: Payment[] = []
    let localHasError = false

    try {
      if (orderItems.length > 0) {
        payments = await OrderHelper.processUnitedAPIOrderV2(
          orderNumber,
          user?.email,
          masterDealerId,
          orderItems,
          thePayment,
          isUsingCoop
        )
      }

      const paymentPromises = payments.map((payment) =>
        http.post({
          url: `order/${orderId}/payment`,
          data: {
            id: payment.id,
            amount: payment.amount,
            type: payment.type,
            date: payment.date,
          },
        })
      )
      await Promise.all(paymentPromises)

      if (payments.length > 0) {
        const totalCoopPayment = payments
          .filter((payment) => payment.type === OrderPaymentType.COOP)
          .reduce((total, payment) => total + parseFloat(payment.amount), 0)

        const totalCardPayment = payments
          .filter((payment) => payment.type === OrderPaymentType.CREDIT_CARD)
          .reduce((total, payment) => total + parseFloat(payment.amount), 0)

        const historyDescription = `Payment made for ${
          isUsingCoop ? `$${totalCoopPayment.toFixed(2)} of Co-Op and ` : ''
        }$${totalCardPayment.toFixed(2)} on Credit Card`
        const newHistory = {
          description: historyDescription,
          date: new Date().toISOString(),
        }
        await http.post({
          url: `order/${orderId}/history`,
          data: newHistory,
        })
        if (order) {
          order.payments = [...order.payments, ...payments]
          order.history = [...order.history, newHistory]
        }
      }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error) {
      const [errorDescription, errorTransactionDescription] = error as [
        string,
        string
      ]
      const errorStr =
        errorTransactionDescription && errorTransactionDescription.trim() !== ''
          ? errorTransactionDescription
          : errorDescription
      localHasError = true
      setHasError(true)
      setError(errorStr)
    }

    return { order, localHasError }
  }

  const updateOrder = useCallback(
    async (orderToSend: Order, payment: IPayment) => {
      setLoading(true)
      const itemsNeedingPayment =
        isCoopRequest ||
        (orderToSend.paymentStatus !== OrderPaymentStatus.PAID &&
          OrderHelper.isCoopRequestApproved(orderToSend))
          ? orderToSend.items
          : orderToSend.items.filter(
              (item) => item.status === OrderItemStatus.PRICE_UPDATED
            )

      const { order, localHasError } = await processPayment(
        orderToSend.number,
        itemsNeedingPayment,
        payment,
        orderToSend?._id
      )

      if (localHasError && orderToSend?._id) {
        orderToSend.history = [
          ...orderToSend.history,
          {
            description: `Error: ${error}`,
            date: new Date().toUTCString(),
          },
        ]

        const orderId = orderToSend._id
        const orderUpdateModel = OrderHelper.makeProperUpdateOrderModel(
          cloneDeep(orderToSend)
        )

        try {
          await OrderHelper.updateOrder(http, orderId, orderUpdateModel)
        } catch (error) {
          console.error(error)
        }
      }

      if (isCoopRequest && !localHasError) {
        const updateOrder = {
          number: orderToSend.number,
          status:
            orderToSend.status === OrderStatus.COOP_REQUEST &&
            orderToSend.payments.length > 0
              ? 1
              : orderToSend.status,
          paymentStatus: orderToSend.paymentStatus,
          customer: {
            user: orderToSend.customer.user,
            masterDealerId: orderToSend.customer.masterDealerId,
            billingAddress: orderToSend.customer.billingAddress,
            shippingAddress: state.context.shippingAddress,
            userInfo: {
              keycloakUserId: orderToSend.customer.userInfo?.keycloakUserId,
              groups: orderToSend.customer.userInfo?.groups,
              email: orderToSend.customer.userInfo?.email,
              lastName: orderToSend.customer.userInfo?.lastName,
              firstName: orderToSend.customer.userInfo?.firstName,
              contactEmail: orderToSend.customer.userInfo?.contactEmail,
              contactPhone: orderToSend.customer.userInfo?.contactPhone,
              preferredContactMethod:
                orderToSend.customer.userInfo?.preferredContactMethod,
              __v: orderToSend.customer?.userInfo?.__v,
              _id: orderToSend.customer?.userInfo?._id,
            },
          },
          items: orderToSend.items,
          payments: orderToSend.payments,
          history: orderToSend.history,
          createdBy: orderToSend.createdBy,
          createdOn: orderToSend.createdOn,
          updatedBy: user.email,
          updatedOn: new Date().toUTCString(),
        }
        try {
          await http.patch({ url: `order/${order?._id}`, data: updateOrder })
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } catch (err: any) {
          setHasError(true)
          setError(err)
        }
      }

      setLoading(false)
      send(
        localHasError
          ? { type: 'MOVETOERROR' }
          : { type: 'MOVETOCONFIRMATION', payment: payment }
      )
    },
    [
      setLoading,
      send,
      isCoopRequest,
      user.email,
      setHasError,
      setError,
      processPayment,
    ]
  )

  useEffect(() => {
    async function processFreeOrder() {
      if (order === null || order === undefined) return
      const subtotal = OrderHelper.getItemsSubtotal(order.items)
      const difference = Math.round((subtotal - coopSubtotal) * 100) / 100
      if (
        !loadingCoop &&
        showDialog &&
        state.matches('payment') &&
        difference === 0
      ) {
        await updateOrder(order, state.context)
      }
    }
    processFreeOrder()
  }, [
    coopSubtotal,
    loadingCoop,
    order,
    updateOrder,
    showDialog,
    state,
    state.context,
  ])

  useEffect(() => {
    setOrderItemsMessages(OrderHelper.getOrderItemsMessages(order))
    setIsCoopRequest(OrderHelper.isCoopRequestSent(order))

    const setIsCoop = () => {
      send({
        type: 'SETISCOOPREQUEST',
        isCoopRequest: OrderHelper.isCoopRequestSent(order),
      })
    }
    const setIsApproved = () => {
      send({
        type: 'SETCOOPREQUESTAPPROVED',
        isApprovedCoopRequest: OrderHelper.isCoopRequestApproved(order),
      })
    }

    setIsCoop()
    setIsApproved()
  }, [order])

  const handleOrderUpdate = () => {
    setLoading(true)
    const orderToSendMail = order as Order
    http
      .patch<Order>({
        url: `order/${orderToSendMail._id}/sendEmail`,
        data: {
          ...orderToSendMail,
          coopAmount: coopSubtotal,
        } as Order,
      })
      .then(() => {
        send({
          type: 'MOVETOCONFIRMATION',
          payment: {
            billingAddress: state.context.billingAddress,
            cardNumber: state.context.cardNumber,
            expirationMonth: state.context.expirationMonth,
            expirationYear: state.context.expirationYear,
            CCV: state.context.CCV,
          },
        })
        setLoading(false)
      })
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        setError(err)
        setHasError(true)
        setLoading(false)
      })
  }

  // Payment dialog
  const getDialog = (items: OrderItem[]) => {
    return (
      <S.Dialog
        aria-label="Build Your Event"
        isOpen={showDialog}
        onDismiss={() => {
          if (state.matches('confirmation') || state.matches('error')) {
            history.go(0)
          }
          send({
            type: 'RETURNTOCOOP',
          })
          setShowDialog(false)
        }}
      >
        <S.CloseButton
          className="close-button"
          onClick={() => {
            if (state.matches('confirmation') || state.matches('error')) {
              history.go(0)
            }
            send({
              type: 'RETURNTOCOOP',
            })
            setShowDialog(false)
          }}
        >
          x
        </S.CloseButton>
        <S.DialogContainer>
          {state.matches('error') ? (
            <S.ErrorContainer>
              <S.Error dangerouslySetInnerHTML={{ __html: error }}></S.Error>
              <S.TextButton
                onClick={() => send({ type: 'RETURNTOEDITPAYMENT' })}
              >
                Return to Payment
              </S.TextButton>
            </S.ErrorContainer>
          ) : (
            state.matches('confirmation') && (
              <S.Container>
                <S.InfoContainer>
                  <SectionCheckoutConfirmation
                    orderSent={order}
                    isCoopRequest={state.context.isNewCoopRequest}
                  />
                </S.InfoContainer>
              </S.Container>
            )
          )}
          {state.matches('coOpEligibility') && (
            <SectionCheckoutCoop
              items={items}
              loadingCoop={loadingCoop}
              coopSubtotal={
                hasCoop || coop.availableBalance > 0 ? coopSubtotal : 0
              }
              onUseCoop={(coopAmount, isNewCoopRequest) => {
                send({
                  type: 'MOVETOTOTALREVIEW',
                  isUsingCoop: true,
                  coopAmount,
                  isNewCoopRequest,
                })
                setIsUsingCoop(true)
              }}
              onIgnoreCoop={(coopAmount) => {
                send({
                  type: 'MOVETOTOTALREVIEW',
                  isUsingCoop: false,
                  coopAmount,
                  isNewCoopRequest: false,
                })
                setIsUsingCoop(false)
              }}
              status={order?.status}
              approvedCoopRequest={state.context.isApprovedCoopRequest}
              showCoopSummary={hasCoop || state.context.isApprovedCoopRequest}
              canRequestCoop={
                coop.availableBalance > 0 &&
                !hasCoop &&
                !state.context.isApprovedCoopRequest &&
                !OrderHelper.isCoopRequestSent(order) &&
                !OrderHelper.isCoopRequestDenied(order)
              }
              canApplyCoop={
                hasCoop ||
                (coop.availableBalance > 0 &&
                  OrderHelper.isCoopRequestApproved(order))
              }
            />
          )}
          {state.matches('totalReview') && (
            <SectionCheckoutTotalReview
              items={items}
              isUsingCoop={state.context.isUsingCoop}
              loadingCoop={loadingCoop}
              coopSubtotal={coopSubtotal}
              coopRequest={
                state.context.isCoopRequest || state.context.isNewCoopRequest
              }
              canRequestCoop={
                coop.availableBalance > 0 &&
                !hasCoop &&
                !state.context.isCoopRequest &&
                !state.context.isApprovedCoopRequest &&
                !OrderHelper.isCoopRequestSent(order) &&
                !OrderHelper.isCoopRequestDenied(order)
              }
              onReturn={() => send({ type: 'RETURNTOCOOP' })}
              onContinue={(sendCoopRequest) =>
                sendCoopRequest
                  ? handleOrderUpdate()
                  : state.context.isCoopRequest
                  ? send({ type: 'MOVETOEDITSHIPPING' })
                  : send({
                      type: 'MOVETOEDITPAYMENT',
                      shippingAddress: order?.customer
                        ?.shippingAddress as OrderAddress,
                    })
              }
            />
          )}
          {state.matches('payment') && (
            <SectionCheckoutPaymentEdit
              items={items}
              masterDealerId={ChannelHelper.getMasterDealerId(
                selectedMasterDealerId,
                selectedChannel
              )}
              isUsingCoop={isUsingCoop}
              payment={{
                billingAddress: state.context.billingAddress,
                cardNumber: state.context.cardNumber,
                expirationMonth: state.context.expirationMonth,
                expirationYear: state.context.expirationYear,
                CCV: state.context.CCV,
              }}
              onSubmit={(payment) => {
                order && updateOrder(order, payment)
              }}
              onCheckCopyShipping={() =>
                send({
                  type: 'COPYSHIPPING',
                  shippingAddress: state.context.isCoopRequest
                    ? (state.context.shippingAddress as OrderAddress)
                    : (order?.customer?.shippingAddress as OrderAddress),
                })
              }
              showCheckCopyShipping={
                state.context.isCoopRequest
                  ? state.context.shippingAddress.address1 !== ''
                  : order?.customer?.shippingAddress.address1 !== ''
              }
            />
          )}
          {state.matches('shipping') && (
            <SectionCheckoutShippingEdit
              items={items}
              masterDealerId={ChannelHelper.getMasterDealerId(
                selectedMasterDealerId,
                selectedChannel
              )}
              isUsingCoop={isUsingCoop}
              shipping={order?.customer.shippingAddress as OrderAddress}
              displayShippingAddressInp={displayShippingAddressInp}
              displayContactInfosInp={displayContactInfosInp}
              onSubmit={(address) =>
                send({
                  type: 'MOVETOEDITPAYMENT',
                  shippingAddress: address,
                })
              }
            />
          )}
          {loading && <PageSpinner />}
        </S.DialogContainer>
      </S.Dialog>
    )
  }

  const handleViewMessages = () => {
    if (!showDrawer && orderItemsMessages.length) {
      setShowDrawer(!showDrawer)
    }
  }

  const handleAddMessage = (index: number, message: string) => {
    if (!!message) {
      const newMessage = {
        message,
        createdBy: user?.email,
        createdOn: new Date().toUTCString(),
        updatedBy: user?.email,
        updatedOn: new Date().toUTCString(),
      }
      onAddMessage(index, newMessage)
      const tmp = [...orderItemsMessages]
      tmp[index].push(newMessage)
      setOrderItemsMessages(tmp)
    }
  }

  const handleContactSupport = () => {
    history.push('/contact')
  }

  const orderHasHistoryInfo = (order?: Order) => {
    return (
      order?.history?.length ||
      order?.items?.some((item) => item.trackingInformation?.length) ||
      order?.payments?.length
    )
  }

  const orderHasTrackingInfo = (order?: Order) => {
    const orderHasTrackingInfo = getOrderHasTrackingInfo(order)
    return Array.isArray(orderHasTrackingInfo) && orderHasTrackingInfo.length
  }

  const getOrderHasTrackingInfo = (order?: Order) => {
    return order?.items?.reduce((acc, item) => {
      return Array.isArray(item.trackingInformation) &&
        item.trackingInformation.length
        ? [
            ...acc,
            ...item.trackingInformation.map((info) => ({
              item: item,
              carrier: info.carrier,
              tracking: info.tracking,
            })),
          ]
        : acc
    }, [] as ItemTrackingInformation[])
  }

  const handleOrderApproval = (isApproved: boolean) => {
    http
      .post({
        url: `order/${order?._id}/${isApproved ? 1 : 0}/processCoopRequest`,
      })
      .then(() => {
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <S.Container>
      {order && order._id && !loadingCoop && (
        <>
          {getDialog(order.items)}
          <S.Header>
            <Link to="/profile/orders">
              <S.BackButtonContainer>
                <Icon icon="ChevronLeft" size={16} hasFill={false} />
                <S.BackButton>Back to all orders</S.BackButton>
              </S.BackButtonContainer>
            </Link>
            <S.Title>
              {order.customer.user != user?.email
                ? order.customer.user.toUpperCase()
                : 'YOUR'}{' '}
              ORDER IS {OrderHelper.getStatus(order?.status).toUpperCase()}{' '}
            </S.Title>
          </S.Header>
          <S.Main>
            <S.OrderContainer>
              <S.Order>
                <S.Column>
                  <S.Row>
                    <S.OrderTitle>
                      <strong>Order:</strong> #{order?.number}
                    </S.OrderTitle>
                  </S.Row>
                  <S.Text>
                    <strong>
                      Status: {OrderHelper.getStatus(order?.status)}
                    </strong>
                  </S.Text>
                </S.Column>
                <S.InfoSelectionContainer>
                  <S.InfoSection>
                    {order?.customer?.shippingAddress?.address1 &&
                    order?.customer?.shippingAddress?.address1 !== '' ? (
                      <>
                        <S.InfoTitle>SHIPPING ADDRESS</S.InfoTitle>
                        {order?.customer?.shippingAddress?.address1 &&
                        order?.customer?.shippingAddress?.address1 !== ''
                          ? `${
                              (order?.customer?.shippingAddress as OrderAddress)
                                .firstName
                            } ${
                              (order?.customer?.shippingAddress as OrderAddress)
                                .lastName
                            }\n${order?.customer?.shippingAddress?.address1}\n${
                              order?.customer?.shippingAddress?.city
                            }, ${order?.customer?.shippingAddress?.state} ${
                              order?.customer?.shippingAddress?.zipCode
                            }`
                          : 'None'}
                      </>
                    ) : (
                      <>
                        <S.InfoTitle>CONTACT INFORMATION</S.InfoTitle>
                        {order?.customer?.shippingAddress?.contactName &&
                        order?.customer?.shippingAddress?.contactName !== ''
                          ? `${
                              (order?.customer?.shippingAddress as OrderAddress)
                                .contactName
                            }\n${
                              (order?.customer?.shippingAddress as OrderAddress)
                                .email
                            }\n${
                              order?.customer?.shippingAddress?.phoneNumber
                            }\n${order?.customer?.shippingAddress?.asmName}\n ${
                              order?.customer?.shippingAddress?.asmEmail
                            }`
                          : 'None'}
                      </>
                    )}
                  </S.InfoSection>
                  <S.InfoSection>
                    <S.InfoTitle>PAYMENT METHOD </S.InfoTitle>
                    {order?.payments?.map((payment: Payment, index: number) => (
                      <S.Text key={index}>
                        {getPaymentType(payment.type)}
                      </S.Text>
                    ))}
                  </S.InfoSection>
                  <S.InfoSection>
                    <S.InfoTitle>TRACKING</S.InfoTitle>
                    {order?.items?.map((item: OrderItem) =>
                      item?.trackingInformation?.map((tracking, index) => (
                        <S.Text key={index}>
                          {`${tracking.carrier}: ${tracking.tracking}`}
                        </S.Text>
                      ))
                    )}
                  </S.InfoSection>
                </S.InfoSelectionContainer>
                {order.status === OrderStatus.COOP_REQUEST &&
                order.customer.user != user?.email ? (
                  <S.SubtotalSection>
                    <S.SubtotalItem>
                      <S.Text>Co-Op Funds Request</S.Text>
                      <S.Text>
                        {formatter.format(
                          order.coopAmount ? order.coopAmount : 0
                        )}
                      </S.Text>
                    </S.SubtotalItem>
                    <S.SubtotalItem>
                      <S.Text>Dealer Spend:</S.Text>
                      <S.Text>
                        {formatter.format(
                          OrderHelper.getOrderBalance(order) -
                            (order.coopAmount ? order.coopAmount : 0)
                        )}
                      </S.Text>
                    </S.SubtotalItem>
                  </S.SubtotalSection>
                ) : (
                  <S.SubtotalSection>
                    <S.SubtotalItem>
                      <S.Text>Subtotal:</S.Text>
                      <S.Text>
                        {formatter.format(getSubtotal(order?.items))}
                      </S.Text>
                    </S.SubtotalItem>
                    {(state.context.isCoopRequest &&
                      (state.context.isApprovedCoopRequest ||
                        order.status === OrderStatus.COOP_REQUEST)) ||
                    OrderHelper.isCoopRequestApproved(order) ? (
                      <>
                        <S.SubtotalItem>
                          <S.Text>Co-Op amount to apply:</S.Text>
                          <S.Text>
                            -{formatter.format(order?.coopAmount ?? 0)}
                          </S.Text>
                        </S.SubtotalItem>
                        <S.SubtotalItem>
                          <S.Text>Dealer Spend:</S.Text>
                          <S.Text>
                            {formatter.format(
                              getSubtotal(order?.items) -
                                (order?.coopAmount ?? 0)
                            )}
                          </S.Text>
                        </S.SubtotalItem>
                      </>
                    ) : (
                      <>
                        {hasCoop && (
                          <S.SubtotalItem>
                            <S.Text>Co-Op:</S.Text>
                            <S.Text>
                              -{formatter.format(getCoopAmount(order))}
                            </S.Text>
                          </S.SubtotalItem>
                        )}
                        <S.SubtotalItem>
                          <S.Text>Credit Card:</S.Text>
                          <S.Text>
                            -{formatter.format(getCardAmount(order))}
                          </S.Text>
                        </S.SubtotalItem>
                      </>
                    )}
                  </S.SubtotalSection>
                )}
                <S.TotalSection>
                  <S.Text>
                    <strong>Total:</strong>
                  </S.Text>
                  <S.Text>
                    {state.context.isApprovedCoopRequest || hasCoop ? (
                      <strong>
                        {formatter.format(
                          getSubtotal(order?.items) - (order?.coopAmount ?? 0)
                        )}
                      </strong>
                    ) : (
                      <strong>
                        {formatter.format(OrderHelper.getOrderBalance(order))}
                      </strong>
                    )}
                  </S.Text>
                </S.TotalSection>
              </S.Order>
              <S.Actions>
                {orderItemsMessages.length ? (
                  <S.ViewMessagesButton
                    label="VIEW MESSAGES"
                    colorOption="blue"
                    onClick={() => handleViewMessages()}
                  />
                ) : undefined}
                {order.customer.user == user?.email ? (
                  <>
                    <Button
                      label="GET HELP WITH ORDER"
                      onClick={() => handleContactSupport()}
                    />
                    {OrderHelper.getOrderBalance(order) > 0 && (
                      <Button
                        label="PAY ORDER BALANCE"
                        onClick={() => setShowDialog(true)}
                      />
                    )}
                  </>
                ) : (
                  isUserAdmin &&
                  order.status == 6 && (
                    <>
                      <Button
                        label="DENY CO-OP REQUEST"
                        onClick={() => handleOrderApproval(false)}
                      ></Button>
                      <Button
                        label="APPROVE CO-OP REQUEST"
                        onClick={() => handleOrderApproval(true)}
                      ></Button>
                    </>
                  )
                )}
                {((order.status === OrderStatus.ARCHIVED &&
                  order.history.length > 0) ||
                  orderHasHistoryInfo(order)) && (
                  <Button
                    colorOption="stroke"
                    label="VIEW HISTORY"
                    onClick={() => setShowHistory(true)}
                  />
                )}
              </S.Actions>
            </S.OrderContainer>

            <S.OrderItemsContainer>
              {order?.items?.map((item: OrderItem, itemIndex: number) => (
                <S.Item key={itemIndex}>
                  <S.Image
                    alt={item.product.title}
                    src={item.product.thumbnailUrl}
                  ></S.Image>
                  <S.InfoContainer>
                    <S.Info>
                      <S.InfoCardTitle>{item.product.title}</S.InfoCardTitle>
                      <S.InfoCardPrice>${item.price}</S.InfoCardPrice>
                    </S.Info>
                    <S.Info>
                      <S.Text>{item.product.description}</S.Text>
                    </S.Info>
                  </S.InfoContainer>
                  <S.ActionContainer>
                    <S.ActionTitle>
                      {OrderHelper.getStatus(order.status)}
                    </S.ActionTitle>
                  </S.ActionContainer>
                </S.Item>
              ))}
            </S.OrderItemsContainer>
            <S.MessagesDrawer
              anchor="right"
              open={showDrawer}
              onClose={() => setShowDrawer(false)}
            >
              {orderItemsMessages.length
                ? orderItemsMessages.map((messages, index) => (
                    <Messages
                      key={index}
                      messages={messages}
                      userEmail={user?.email}
                      subject={order?.items?.[index]?.product?.title}
                      onSendMessage={(message: string) =>
                        handleAddMessage(index, message)
                      }
                    />
                  ))
                : undefined}
            </S.MessagesDrawer>
          </S.Main>
          {orderHasHistoryInfo(order) && showHistory && (
            <S.HistoryContainer>
              <S.Button
                iconHasStroke={false}
                iconHasFill={false}
                icon="Close"
                customIconSize={15}
                borderRadius={true}
                colorOption="stroke"
                onClick={() => setShowHistory(false)}
              />
              {Array.isArray(order.history) && order.history.length ? (
                <S.HistorySection>
                  <S.HistorySectionTitle>History</S.HistorySectionTitle>
                  <S.HistoryWrapper>
                    {Array.isArray(order.history) && order.history.length
                      ? order.history.map((history: History, index: number) => (
                          <S.HistoryItem key={`history-${index}`}>
                            <S.HistoryStepContainer>
                              <S.HistoryStep>
                                <span>{index + 1}</span>
                              </S.HistoryStep>
                            </S.HistoryStepContainer>
                            <S.HistoryInformation>
                              <S.HistoryText>
                                <strong>{history.description}</strong>
                              </S.HistoryText>
                              <S.HistoryText>
                                {getFormattedDate(history.date)}
                              </S.HistoryText>
                            </S.HistoryInformation>
                          </S.HistoryItem>
                        ))
                      : undefined}
                  </S.HistoryWrapper>
                </S.HistorySection>
              ) : undefined}
              {orderHasTrackingInfo(order) ? (
                <S.HistorySection>
                  <S.HistorySectionTitle>Tracking</S.HistorySectionTitle>
                  <S.HistoryWrapper>
                    {orderHasTrackingInfo(order)
                      ? getOrderHasTrackingInfo(order)?.map(
                          (info: ItemTrackingInformation, index: number) => (
                            <S.HistoryItem key={`tracking-${index}`}>
                              <S.HistoryStepContainer>
                                <S.HistoryStep>
                                  <span>{index + 1}</span>
                                </S.HistoryStep>
                              </S.HistoryStepContainer>
                              <S.HistoryInformation>
                                <S.HistoryText>
                                  <strong>{`${info?.item?.product?.title} ${info.carrier} ${info.tracking}`}</strong>
                                </S.HistoryText>
                              </S.HistoryInformation>
                            </S.HistoryItem>
                          )
                        )
                      : undefined}
                  </S.HistoryWrapper>
                </S.HistorySection>
              ) : undefined}
              {Array.isArray(order.payments) && order.payments.length ? (
                <S.HistorySection>
                  <S.HistorySectionTitle>Payments</S.HistorySectionTitle>
                  <S.HistoryWrapper>
                    {Array.isArray(order.payments) && order.payments.length
                      ? order.payments.map(
                          (payment: Payment, index: number) => (
                            <S.HistoryItem key={`payment-${index}`}>
                              <S.HistoryStepContainer>
                                <S.HistoryStep>
                                  <span>{index + 1}</span>
                                </S.HistoryStep>
                              </S.HistoryStepContainer>
                              <S.HistoryInformation>
                                <S.HistoryText>
                                  <strong>{`${getPaymentType(
                                    payment.type
                                  )} ${formatter.format(
                                    parseFloat(payment.amount)
                                  )}`}</strong>
                                </S.HistoryText>
                                <S.HistoryText>
                                  {getFormattedDate(payment.date)}
                                </S.HistoryText>
                              </S.HistoryInformation>
                            </S.HistoryItem>
                          )
                        )
                      : undefined}
                  </S.HistoryWrapper>
                </S.HistorySection>
              ) : undefined}
            </S.HistoryContainer>
          )}
        </>
      )}
    </S.Container>
  )
}

export default OrderDetails
