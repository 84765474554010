"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePagination = exports.DOTS = void 0;
var tslib_1 = require("tslib");
var react_1 = require("react");
var range = function (start, end) {
    var length = end - start + 1;
    return Array.from({ length: length }, function (_, idx) { return idx + start; });
};
exports.DOTS = 999999;
var usePagination = function (_a) {
    var totalCount = _a.totalCount, totalPages = _a.totalPages, page = _a.page, pageSize = _a.pageSize, _b = _a.siblingCount, siblingCount = _b === void 0 ? 1 : _b;
    var paginationRange = (0, react_1.useMemo)(function () {
        var totalNumberedPages = siblingCount + 5;
        if (totalNumberedPages >= totalPages) {
            return range(1, totalPages);
        }
        var leftSiblingIndex = Math.max(page, 1);
        var rightSiblingIndex = Math.min(page, totalPages);
        var shouldShowLeftDots = leftSiblingIndex > 3;
        var shouldShowRightDots = rightSiblingIndex < totalPages - 3;
        var firstPageIndex = 1;
        var lastPageIndex = totalPages;
        if (!shouldShowLeftDots && shouldShowRightDots) {
            var leftItemCount = 3 + 2 * siblingCount;
            var leftRange = range(1, leftItemCount);
            return (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], leftRange, true), [exports.DOTS, totalPages], false);
        }
        if (shouldShowLeftDots && !shouldShowRightDots) {
            var rightItemCount = 3 + 2 * siblingCount;
            var rightRange = range(totalPages - rightItemCount + 1, totalPages);
            return (0, tslib_1.__spreadArray)([firstPageIndex, exports.DOTS], rightRange, true);
        }
        if (shouldShowLeftDots && shouldShowRightDots) {
            var middleRange = range(leftSiblingIndex - 2 * siblingCount, leftSiblingIndex + 2 * siblingCount);
            return (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([firstPageIndex, exports.DOTS], middleRange, true), [exports.DOTS, lastPageIndex], false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCount, pageSize, siblingCount, page]);
    return paginationRange;
};
exports.usePagination = usePagination;
