"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const S = (0, tslib_1.__importStar)(require("./styles"));
const Select = (_a) => {
    var { options, id = '', label = '', invalid = false, invalidMessage = '', onChange, onBlur, value = '' } = _a, props = (0, tslib_1.__rest)(_a, ["options", "id", "label", "invalid", "invalidMessage", "onChange", "onBlur", "value"]);
    return ((0, jsx_runtime_1.jsxs)(S.Container, { children: [(0, jsx_runtime_1.jsxs)(S.Wrapper, { children: [(0, jsx_runtime_1.jsxs)(S.StyledSelect, Object.assign({}, props, { value: value, label: label, placeholder: " ", onBlur: onBlur, invalid: invalid, id: `input-${id}`, onChange: onChange, invalidMessage: invalidMessage }, { children: [(0, jsx_runtime_1.jsx)(S.Option, Object.assign({ disabled: true, value: "" }, { children: `Please select ${label.toLowerCase()}` }), void 0), options === null || options === void 0 ? void 0 : options.map((option) => ((0, jsx_runtime_1.jsx)(S.Option, Object.assign({ value: option.value }, { children: option.label }), option.value)))] }), void 0), (0, jsx_runtime_1.jsx)(S.Label, Object.assign({ htmlFor: `input-${id}` }, { children: label }), void 0), invalid && ((0, jsx_runtime_1.jsx)(S.StyledExclamationIcon, { size: 18, icon: "ExclamationMark", hasFill: false, hasStroke: false }, void 0)), (0, jsx_runtime_1.jsx)(S.StyledArrowIcon, { size: 20, icon: "ArrowDown", hasFill: true, hasStroke: false }, void 0)] }, void 0), invalid && invalidMessage && (0, jsx_runtime_1.jsx)(S.Span, { children: invalidMessage }, void 0)] }, void 0));
};
exports.default = Select;
