import styled from 'styled-components'

export const Main = styled('div')`
  font-family: sans-serif;
`

export const DropDownContainer = styled('div')`
  width: 10.5em;
  margin: 0 auto;
`

export const DropDownHeader = styled('div')`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
`

export const DropDownListContainer = styled('div')``

export const DropDownList = styled('ul')`
  position: absolute;
  padding: 0;
  margin: 0.5em;
  padding-left: 1em;
  box-sizing: border-box;
  color: #0b0c0c;
  font-size: small;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
  background-color: #fff;
  width: 150px;
  z-index: 10;
`

export const ListItem = styled('li')`
  list-style: none;
  font-weight: 700;
  text-align: left;
  line-height: 170%;
  font-size: 11px;
  margin-bottom: 0.8em;
  z-index: 1;
  position: relative !important;
`
