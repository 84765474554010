/* eslint-disable @typescript-eslint/no-explicit-any */
import * as S from './styles'
import { TableBody } from '@material-ui/core'
import { ACBHistoryItem } from '@monorepo/interfaces'
import Dinero from 'dinero.js'
import moment from 'moment'
import { useMemo } from 'react'

const startWithReleaseWordRegex = /Release/i

export interface SectionCoOpListProps {
  data?: ACBHistoryItem[]
  errorMessage?: string
}

const SectionCoOpList: React.FC<SectionCoOpListProps> = ({
  data,
  errorMessage,
}) => {
  console.log({ firstItem: !!data && data.length > 0 && data[0] })

  const currentDate = moment(new Date())

  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) {
      return []
    }

    return data.filter(
      (x) =>
        x.AvailableBalance > 0 &&
        !startWithReleaseWordRegex.test(x.TransactionDescription)
    )
  }, [data])

  console.log({ filteredFirstItem: filteredData.length > 0 && filteredData[0] })

  return (
    <S.Container>
      {Array.isArray(filteredData) && filteredData.length ? (
        <S.TableContainer>
          <S.Table aria-label="Co-Op Table">
            <S.TableHead>
              <S.TableHeadRow>
                <S.TableHeadCell style={{ width: '70%' }}>
                  Description
                </S.TableHeadCell>
                <S.TableHeadCell>Expiration Date</S.TableHeadCell>
                <S.TableHeadCell>Amount</S.TableHeadCell>
              </S.TableHeadRow>
            </S.TableHead>
            <TableBody>
              {filteredData.map((item, index) => (
                <S.TbodyRow key={index}>
                  <S.TBodyCell scope="row" style={{ width: '70%' }}>
                    {item.TransactionDescription}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    <S.ExpirationTag
                      data-expired={currentDate.isAfter(
                        moment.utc(item.FundExpiryDate)
                      )}
                    >
                      {`Expires ${moment
                        .utc(item.FundExpiryDate)
                        .format('MMM D, YYYY')}`}
                    </S.ExpirationTag>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {Dinero({
                      amount: Math.round(item.AvailableBalance * 100),
                    }).toFormat('$0,0.00')}
                  </S.TBodyCell>
                </S.TbodyRow>
              ))}
            </TableBody>
          </S.Table>
        </S.TableContainer>
      ) : (
        <S.EmptyMessage>{errorMessage || 'There is no data'}</S.EmptyMessage>
      )}
    </S.Container>
  )
}

export default SectionCoOpList
