export var TicketPriority;
(function (TicketPriority) {
    TicketPriority[TicketPriority["LOW"] = 1] = "LOW";
    TicketPriority[TicketPriority["MEDIUM"] = 2] = "MEDIUM";
    TicketPriority[TicketPriority["HIGH"] = 3] = "HIGH";
})(TicketPriority || (TicketPriority = {}));
export var TicketStatus;
(function (TicketStatus) {
    TicketStatus[TicketStatus["NEW"] = 1] = "NEW";
    TicketStatus[TicketStatus["ESCALATION"] = 2] = "ESCALATION";
    TicketStatus[TicketStatus["APPROVAL_NEEDED"] = 3] = "APPROVAL_NEEDED";
    TicketStatus[TicketStatus["APPROVAL_COMPLETE"] = 4] = "APPROVAL_COMPLETE";
    TicketStatus[TicketStatus["AWAITING_DEALER"] = 5] = "AWAITING_DEALER";
    TicketStatus[TicketStatus["AWAITING_VENDOR"] = 6] = "AWAITING_VENDOR";
    TicketStatus[TicketStatus["AWAITING_ZONER"] = 7] = "AWAITING_ZONER";
    TicketStatus[TicketStatus["DELETED"] = 8] = "DELETED";
    TicketStatus[TicketStatus["CLOSED"] = 9] = "CLOSED";
})(TicketStatus || (TicketStatus = {}));
export var TicketCategoryProduct;
(function (TicketCategoryProduct) {
    TicketCategoryProduct[TicketCategoryProduct["HELP_WITH_MICROSITE"] = 1] = "HELP_WITH_MICROSITE";
    TicketCategoryProduct[TicketCategoryProduct["HELP_WITH_DOWNLOAD"] = 2] = "HELP_WITH_DOWNLOAD";
    TicketCategoryProduct[TicketCategoryProduct["HELP_WITH_MEDIA"] = 3] = "HELP_WITH_MEDIA";
    TicketCategoryProduct[TicketCategoryProduct["HELP_WITH_PRINT_AND_SHIP"] = 4] = "HELP_WITH_PRINT_AND_SHIP";
    TicketCategoryProduct[TicketCategoryProduct["OTHER"] = 5] = "OTHER";
})(TicketCategoryProduct || (TicketCategoryProduct = {}));
export var TicketCategoryEvent;
(function (TicketCategoryEvent) {
    TicketCategoryEvent[TicketCategoryEvent["HELP_WITH_EVENT_ORDER"] = 1] = "HELP_WITH_EVENT_ORDER";
    TicketCategoryEvent[TicketCategoryEvent["BUILD_MY_OWN_EVENT"] = 2] = "BUILD_MY_OWN_EVENT";
})(TicketCategoryEvent || (TicketCategoryEvent = {}));
var TicketPreferredContactMethodAdditional;
(function (TicketPreferredContactMethodAdditional) {
    TicketPreferredContactMethodAdditional["MESSAGE"] = "message";
})(TicketPreferredContactMethodAdditional || (TicketPreferredContactMethodAdditional = {}));
