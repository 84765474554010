import * as S from './styles'

import { MouseEvent, useMemo } from 'react'

import { Icon } from '@monorepo/components'
import { colors } from '@monorepo/theme'

interface PaginationDetailsProps {
  pageNumber: number
  itemsPerPage: number
  totalItems: number
  totalPages: number
  onClickPrev?: (event?: MouseEvent) => void
  onClickNext?: (event?: MouseEvent) => void
}

interface Pagination {
  start: number
  end: number
}

const PaginationDetails: React.FC<PaginationDetailsProps> = ({
  pageNumber,
  itemsPerPage,
  totalItems,
  totalPages,
  onClickPrev,
  onClickNext,
}) => {
  const pagination = useMemo(
    function getStartEnd(): Pagination {
      const start = (pageNumber - 1) * itemsPerPage + 1

      if (totalItems === 0) return { start: 0, end: 0 }

      if (itemsPerPage >= totalItems) return { start, end: totalItems }

      const end = itemsPerPage * pageNumber

      if (end > totalItems) return { start, end: totalItems }

      if (isNaN(start) || isNaN(end)) return { start: 0, end: 0 }

      return { start, end }
    },
    [itemsPerPage, pageNumber, totalItems]
  )

  const prevPage = Math.max(pageNumber - 1, 1)

  const isPrevDisabled = pageNumber <= prevPage,
    isNextDisabled = pageNumber >= totalPages

  return (
    <S.PaginationContainer>
      <S.PageInformation>
        {pagination?.start}-{pagination?.end} of{' '}
        {!pagination?.start && !pagination?.end ? 0 : totalItems}
      </S.PageInformation>
      <S.PageArrows>
        <S.PageButton left disabled={isPrevDisabled} onClick={onClickPrev}>
          <Icon
            icon="ChevronLeft"
            size={14}
            color={isPrevDisabled ? colors.highlightGrey : colors.black}
            hasFill={false}
          />
        </S.PageButton>
        <S.PageButton right disabled={isNextDisabled} onClick={onClickNext}>
          <Icon
            icon="ChevronRight"
            size={14}
            color={isNextDisabled ? colors.highlightGrey : colors.black}
            hasFill={false}
          />
        </S.PageButton>
      </S.PageArrows>
    </S.PaginationContainer>
  )
}

export default PaginationDetails
