import { SectionHomeProps } from '@monorepo/interfaces'
import { colors } from '@monorepo/theme'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Section = styled.section<SectionHomeProps>`
  display: grid;
  width: 100%;
  margin-bottom: 135px;
  background-color: ${colors.black};
  background-image: url(${(props) => (props.bgImage ? props.bgImage : 'none')});
  background-repeat: no-repeat;
  background-size: cover;
`

export const Featured = styled.div`
  height: 150px;
  display: flex;
`

export const Carousel = styled.div`
  display: grid;
  gap: 2px;
  overflow-x: auto;
  grid-auto-flow: column;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: 1rem;
  padding-bottom: 72px;
  margin-bottom: 72px;

  > * {
    scroll-snap-align: start;
  }

  ::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    border: 7px solid black;
    background-color: #b7bec6;
    margin: 0 50px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: white;
    border-top: 6px solid black;
    border-bottom: 6px solid black;
  }
`

export const CarouselItemLink = styled(NavLink)``

type CarouselItemType = { bgSrc: string }

export const CarouselItem = styled.div<CarouselItemType>`
  background-image: url(${(props) => process.env.PUBLIC_URL + props.bgSrc});
  background-repeat: no-repeat;
  background-size: 390px 493px;
  aspect-ratio: 390 / 493;
  height: 493px;
`

export const FeaturedTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 50px;
  place-items: center;
  gap: 45px;
  width: 100%;
`

export const FeaturedTitle = styled.div`
  color: ${colors.white};
  font-size: 27pt;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  font-family: Jubilat;
`
