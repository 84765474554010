import styled from 'styled-components'
import { colors } from '@monorepo/theme'

export const Section = styled.section`
  display: flex;
  flex-flow: row;
  background-color: #102641;
`

export const Container = styled.div`
  max-width: 350px;
  gap: 20px;
  margin: auto 10vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 80px 0px;
  
  @media (min-width: 1440px) {
    max-width: 414px;
  }
  
  @media (min-width: 1920px) {
    max-width: 429px;
  }
`

export const Title = styled.h1`
  color: ${colors.white};
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 36.8px;
  max-width: 277.49px;
  
  .title_italic {
    font-style: italic;
    font-family: Jubilat;
  }
  
  @media (min-width: 1440px) {
    max-width: 431px;
    line-height: 51.52px;
    font-size: 56px;
  }
  
  @media (min-width: 1920px) {
    max-width: 431px;
    line-height: 58.88px;
    font-size: 64px;
  }
`

export const BlueTitle = styled.h6`
  color: #76D4F8;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;

  @media (min-width: 1440px) {
    font-size: 24px;
  }
`

export const Text = styled.p`
  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.08px;

  @media (min-width: 1440px) {
    font-size: 18px;
    line-height: 24.84px;
  }
`

export const ImageBanner = styled.img`
width: 100%; 
height: 100%;
object-fit: cover;
`

export const Content = styled.p`
  max-width: 710px;
  margin: 1rem 0.5rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 400;
  color: ${colors.white};

  @media (max-width: 426px) {
    font-size: 1rem;
  }
`
export const ImageContainer = styled.div`
object-fit: cover;
flex: 1;
`

export const Arrow = styled.div`
  @media (max-width: 1024px) {
    display:none;
  }
`