import { useKeycloak } from '@react-keycloak/web'
import React from 'react'
import { Link } from 'react-router-dom'

import * as S from './styles'
import {
  ChannelHelper,
  useChannel,
  KeycloakHelper,
  useImpersonatedUser,
  UserHelper,
} from '@monorepo/infra'

export interface ProfileNavigationProps {
  show: boolean
}

const ProfileNavigation: React.FC<ProfileNavigationProps> = ({ show }) => {
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)
  const { selectedMasterDealerId, selectedChannel } = useChannel()
  const { setState: setImpersonatedUserName } = useImpersonatedUser()

  const navItems = [
    {
      title: 'My Account',
      url: '/profile',
    },
    {
      title: 'Orders',
      url: '/profile/orders',
    },
    ...(ChannelHelper.canSeeCoop(
      selectedMasterDealerId,
      selectedChannel,
      UserHelper.isImpersonatedUserDealerAdmin(keycloak) ||
        UserHelper.isDealerAdmin(user?.groups)
    )
      ? [
          {
            title: 'Co-Op',
            url: '/profile/co-op',
          },
        ]
      : []),
    {
      title: 'Notifications',
      url: '/profile/notifications',
    },
  ]

  return (
    <S.Main hidden={!show} className="navigation-profile">
      <S.DropDownContainer>
        <S.DropDownListContainer>
          <S.DropDownList>
            {navItems.map((item, index) => (
              <Link to={item.url} key={index}>
                <S.ListItem>{item.title}</S.ListItem>
              </Link>
            ))}

            <Link
              to=""
              onClick={() => {
                setImpersonatedUserName(undefined)
                KeycloakHelper.clearTokenExchange(keycloak)
                KeycloakHelper.clearCallbacks()
                keycloak.logout()
              }}
              key={navItems.length}
            >
              <S.ListItem>Sign Out</S.ListItem>
            </Link>
          </S.DropDownList>
        </S.DropDownListContainer>
      </S.DropDownContainer>
    </S.Main>
  )
}

export default ProfileNavigation
