import styled from 'styled-components'
import { colors } from '@monorepo/theme'

interface ImageProps {
  url: string
}

interface OptionProps {
  isSelected: boolean
}

export const Title = styled.h1`
  font-size: 41.25pt;
  font-weight: 700;
  text-transform: uppercase;

  > span {
    font-style: italic;
    font-family: Jubilat;
  }
`

export const Subtitle = styled.p`
  font-size: 11.25pt;
`

export const Container = styled.div`
  height: 100vh;
  min-height: 800px;
  display: flex;
  position: relative;
`

export const Counter = styled.div`
  display: flex;
  flex-direction: row;
  place-content: space-between;
  align-items: center;
  padding: 36px;
`

export const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 46px;
  border-right: 1px solid ${colors.black};
`

export const RightSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 46px;
  padding: 0 80px;
  margin: auto;
`
export const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`
export const Image = styled.div<ImageProps>`
  height: 100%;
  background-image: url('${({ url }) => url}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 65px 120px;
  gap: 20px;

  > a {
    border-bottom: 1px solid ${colors.black};
    line-height: 1.5rem;
  }
`

export const Selection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 46px;
`

export const Option = styled.span<OptionProps>`
  height: 38px;
  line-height: 45px;
  color: ${colors.black};
  font-size: 27pt;
  width: max-content;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  border-bottom: ${({ isSelected }) =>
    isSelected ? `3px solid black` : `unset`};
`
