import React, { useState } from 'react'
import { Filter, GridProducts, FilterButton } from '@monorepo/components'
import { InputAdornment, Menu, MenuItem } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import * as S from './styles'
import { useTranslation } from 'react-i18next'
import { FilterProvider } from '@monorepo/infra'

const SectionProducts: React.FC = () => {
  const { t } = useTranslation('productsList')
  const [clickedButtonFilter, setClickedButtonFilter] = useState<boolean>(false)
  const [filterOpen, setFilterOpen] = useState<boolean>(true)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleChange = () => {
    setClickedButtonFilter(!clickedButtonFilter)
    setFilterOpen(!filterOpen)
  }

  const handleClickDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  return (
    <S.Section>
      <S.Container>
        <S.Title>{t('section_products.products_label')}</S.Title>
        <S.InputBase
          placeholder={t('section_products.searchbar_placeholder')}
          inputProps={{ 'aria-label': 'search' }}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </S.Container>
      <S.Container>
        <FilterButton
          label={t('section_products.filter_label')}
          clicked={clickedButtonFilter}
          onClick={handleChange}
        />

        <S.TextContainer>
          <S.Paragraph>100 {t('section_products.products_label')}</S.Paragraph>
          <div>
            <S.ButtonDropdown
              aria-controls="simple-menu"
              aria-haspopup="true"
              endIcon={<S.ExpandMoreIcon />}
              onClick={handleClickDropdown}
            >
              {t('section_products.dropdown.title')}
            </S.ButtonDropdown>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseDropdown}
            >
              <MenuItem onClick={handleCloseDropdown}>
                {t('section_products.dropdown.item1')}
              </MenuItem>
              <MenuItem onClick={handleCloseDropdown}>
                {t('section_products.dropdown.item2')}
              </MenuItem>
              <MenuItem onClick={handleCloseDropdown}>
                {t('section_products.dropdown.item3')}
              </MenuItem>
            </Menu>
          </div>
        </S.TextContainer>
      </S.Container>
      <S.Container>
        <S.RowContainer>
          <FilterProvider>
            <Filter filterOpen={filterOpen} />
          </FilterProvider>
          <GridProducts gridProducts={gridProducts} />
        </S.RowContainer>
      </S.Container>
    </S.Section>
  )
}

export default SectionProducts

const gridProducts = [
  {
    title: 'AT&T TV Evergreen 1',
    image: '/images/admaterials/ad_materials_1.jpg',
    link: '/products',
  },
  {
    title: 'AT&T TV Evergreen 2',
    image: '/images/admaterials/ad_materials_1.jpg',
    link: '/products',
  },
  {
    title: 'AT&T TV Evergreen 3',
    image: '/images/admaterials/ad_materials_1.jpg',
    link: '/products',
  },
  {
    title: 'AT&T TV Evergreen 4',
    image: '/images/admaterials/ad_materials_1.jpg',
    link: '/products',
  },
  {
    title: 'AT&T TV Evergreen 5',
    image: '/images/admaterials/ad_materials_1.jpg',
    link: '/products',
  },
  {
    title: 'AT&T TV Evergreen 6',
    image: '/images/admaterials/ad_materials_1.jpg',
    link: '/products',
  },
  {
    title: 'AT&T TV Evergreen 7',
    image: '/images/admaterials/ad_materials_1.jpg',
    link: '/products',
  },
  {
    title: 'AT&T TV Evergreen 8',
    image: '/images/admaterials/ad_materials_1.jpg',
    link: '/products',
  },
  {
    title: 'AT&T TV Evergreen 9',
    image: '/images/admaterials/ad_materials_1.jpg',
    link: '/products',
  },
]
