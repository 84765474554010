"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSender = void 0;
var tslib_1 = require("tslib");
var service_bus_1 = require("@azure/service-bus");
var createSender = function (connection, topic) { return (0, tslib_1.__awaiter)(void 0, void 0, void 0, function () {
    var match, entityPath, sbClient, sender;
    return (0, tslib_1.__generator)(this, function (_a) {
        match = connection.match(/(?:EntityPath=)(.+?)(?=$|;)/i);
        entityPath = match === null || match === void 0 ? void 0 : match[1];
        sbClient = new service_bus_1.ServiceBusClient(connection);
        sender = sbClient.createSender(entityPath || topic);
        return [2 /*return*/, { sender: sender, sbClient: sbClient }];
    });
}); };
exports.createSender = createSender;
