import { colors } from '@monorepo/theme'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import arrow from '../.././../assets/microsites/arrow.png'

const SalesHighlight = styled.section`
  align-items: center;
  background-color: #000;
  background-image: url('/images/microsites/product-wrap.png');
  background-position: bottom;
  background-size: cover;
  color: ${colors.white};
  display: flex;
  flex-flow: column wrap;
  height: 215px;
  justify-content: center;
  mix-blend-mode: darken;

  @media screen and (min-width: 767px) {
    background-color: #102641;
    background-image: none;
    height: 100%;
    width: 38%;
  }
`

const HighlighText = styled.h1`
  font-family: 'PFDINText';
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  width: 80%;

  @media screen and (min-width: 767px) {
    font-size: 55px;
    line-height: 50.5px;
    margin-bottom: 15px;
    max-width: 435px;
    width: 100%;
  }
`

const HighlightWrap = styled.section`
  height: 100%;
  @media screen and (min-width: 767px) {
    display: flex;
    height: 715px;
  }
`

const HighlightImage = styled.div`
  background-image: url('/images/microsites/feat-desk.png');
  background-position: center;
  background-size: cover;
  height: 215px;
  width: 62%;

  @media screen and (min-width: 767px) {
    height: 100%;
  }
`

const OnlinePresenceWrap = styled.section`
  padding: 80px 10px;
  position: relative;
  width: 100%;

  & > h2 {
    font-family: PFDINText;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: -1.5px;
    text-align: left;
  }

  & > p {
    font-family: PFDINText;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.005em;
    padding: 20px 0;
    text-align: left;
  }

  @media screen and (min-width: 767px) {
    margin: 0 auto;
    max-width: 900px;

    &::before {
      content: url(${arrow});
      position: absolute;
      z-index: 100000;
      left: -100px;
      top: 80px;
    }
  }
`

const ButtonLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  width: 140.99px;
  height: 49.09px;
  left: 136.28px;
  top: 168px;
  background: #337ace;
  text-transform: uppercase;
`

export {
  SalesHighlight,
  HighlighText,
  HighlightWrap,
  HighlightImage,
  OnlinePresenceWrap,
  ButtonLink,
}
