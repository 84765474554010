import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import Cleave from 'cleave.js/react'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;
`

export const Title = styled.h3`
  font-size: 15px;
  font-family: PFDINText;
  text-transform: uppercase;
  font-weight: 600;
`

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  margin: 0 9px 0 0;
  font-size: 15px;
  font-family: PFDINText;
  font-weight: normal;
  font-style: normal;
`

export const CreditCard = styled(Cleave)`
  width: 100%;
  padding: 16px 38px 16px 20px;
  border: 1px solid #b7bec6;
  font-size: 15px;
  font-family: PFDINText;
  font-weight: normal;
  font-style: normal;
`

export const Input = styled.input.attrs({
  placeholderTextColor: '#9fa8b3',
})`
  width: 100%;
  padding: 16px 38px 16px 20px;
  border: 1px solid #b7bec6;
  font-size: 15px;
  font-family: PFDINText;
  font-weight: normal;
  font-style: normal;
`

export const Select = styled.select`
  width: 100%;
  padding: 16px 38px 16px 20px;
  border: 1px solid #b7bec6;
  font-size: 15px;
  font-family: PFDINText;
  font-weight: normal;
  font-style: normal;

  :required:invalid {
    color: #757575;
  }

  option[value=''][disabled] {
    display: none;
  }
  option {
    color: #000000;
  }
`
export const Text = styled.p<{ alignSelf?: string }>`
  font-family: PFDINText;
  font-size: 15px;
  font-weight: normal;
  color: ${(props) => props.color || '#000000'};
  font-style: normal;
  align-self: ${(props) => props.alignSelf || 'auto'};
`

export const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const ViewDetails = styled.div<{ flexBasis?: string }>`
  display: flex;
  flex-direction: column;
  flex-basis: ${(props) => props.flexBasis || 'auto'};
  gap: 9px;
`

export const EditButton = styled.button`
  all: revert;
  flex: 1;
  align-self: flex-end;
  justify-self: center;
  background-color: transparent;
  border: 0;
  text-decoration: underline;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`

export const Button = styled.button<{
  disabled?: boolean
  isSecondary?: boolean
}>`
  background-color: ${(props) =>
    props.isSecondary
      ? props.disabled
        ? '#cccccc'
        : '#000000'
      : props.disabled
      ? '#65a9fc'
      : '#337ace'};
  border: 1px solid
    ${(props) =>
      props.isSecondary
        ? props.disabled
          ? '#cccccc'
          : '#000000'
        : props.disabled
        ? '#65a9fc'
        : '#337ace'};
  color: #ffffff;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 16px;
  padding: 17px 30px 17px 30px;
`

export const ThumbnailContainer = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f4;
  margin-right: 24px;
`

export const Thumbnail = styled.img`
  max-width: 48px;
  max-height: 63px;
`

export const ProductList = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ProductItem = styled.li`
  flex: 1;
  flex-direction: row;
  display: flex;
  margin: 5px 0;
`

export const ProductDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ProductTitle = styled.h3`
  font-size: 15px;
  font-family: PFDINText;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 12px;
  flex: 2;
`

export const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const Line = styled.hr`
  margin: 0;
  border: 0;
  border-top: 1px solid #9fa8b3;
`

export const TotalTitle = styled.h2`
  font-size: 20px;
  font-family: PFDINText;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 12px;
  flex: 2;
`

export const TotalNumber = styled.h2`
  font-size: 20px;
  font-family: PFDINText;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 12px;
`

export const ConfirmationTitle = styled.h1`
  font-size: 36px;
  font-family: PFDINText;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 12px;
`

export const ConfirmationOrderNumber = styled.h4`
  font-size: 20px;
  font-family: PFDINText;
  text-transform: uppercase;
  font-weight: 400;
  color: #8793a0;
`

export const ConfirmationThankYou = styled(ConfirmationTitle)``

export const ConfirmationMessage = styled(ConfirmationOrderNumber)`
  text-transform: none;
  color: ${colors.black};
  white-space: pre-line;
`

export const ConfirmationRow = styled(ProductRow)``

export const ConfirmationRowItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const ConfirmationHistoryList = styled(ProductList)``

export const ConfirmationHistoryItem = styled(ProductItem)``

export const ConfirmationPaymentList = styled(ProductList)``

export const ConfirmationPaymentItem = styled(ProductItem)``

export const DownloadingButton = styled.a`
  background-color: #337ace;
  color: #ffffff;
  margin-top: 8px;
  margin-bottom: 16px;
  max-width: 180px;
  padding: 16px 16px;
  text-align: center;
  text-transform: uppercase;
`

export const PartialCoopDisclaimer = styled.h3``
