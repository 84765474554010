"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartContext = void 0;
var react_1 = require("react");
exports.CartContext = (0, react_1.createContext)({
    items: {
        products: null,
        events: null,
    },
    addProduct: function (product) { return undefined; },
    addEvent: function (event) { return undefined; },
    removeProduct: function (removeIndex) { return undefined; },
    removeEvent: function (removeIndex) { return undefined; },
    clearAllItems: function () { return undefined; },
});
