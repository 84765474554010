import { Menu, MenuButton, MenuList } from '@reach/menu-button'
import BaseSearch, { SearchProps } from '../../atoms/Search'
import { colors } from '@monorepo/theme'
import styled from 'styled-components'

const FilterWrap = styled.section`
  background-color: #102641;
  height: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Filter = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 1440px;

  & > p.date {
    color: #fff;
    text-decoration: solid;
  }
`

const SelectDate = styled(Menu)`
  color: #9fa8b3;
  cursor: pointer;
  font-family: PFDINText;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2;
  margin-right: 10px;
  text-align: left;
`

const SelectDateButton = styled(MenuButton)`
  align-items: center;
  background: none;
  border: 0;
  color: #9fa8b3;
  cursor: pointer;
  display: flex;
  font-family: PFDINText;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  height: 100%;
  line-height: 32px;
  letter-spacing: -0.2;
  margin-right: 10px;
  text-align: left;
  width: auto;

  & > p,
  & > label {
    margin-right: 10px;
  }

  & > label {
    font-weight: 300;
  }
`

const Search = styled(BaseSearch).attrs({
  backgroundColor: '#ffffff00',
  color: '#9fa8b3',
  width: 325,
  height: 36,
  borderBottom: true,
})<SearchProps>``

const SearchInfo = styled.div`
  padding: 0px 0 30px 44px;
  font-family: PFDINText;
  font-weight: 700;
  font-size: 23px;
  line-height: 32px;
  color: ${colors.darkBlue};
`

const SelectDateContent = styled(MenuList)``

const ClearDate = styled.p`
  color: #9fa8b3;
  cursor: pointer;
`

export const InfiniteScrollContainer = styled.div`
  & div.infinite-scroll-component {
    overflow-y: hidden !important;
  }
`

export {
  FilterWrap,
  Filter,
  Search,
  SelectDate,
  SelectDateButton,
  SelectDateContent,
  SearchInfo,
  ClearDate,
}
