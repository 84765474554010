import { Link } from 'react-router-dom'
import { colors } from '@monorepo/theme'
import styled from 'styled-components'

interface IsVisibleProps {
  isVisible: boolean
}

export const ListItem = styled.li`
  display: flex;
  color: ${colors.white};
  text-transform: uppercase;
`

export const ListItemWithoutLink = styled.a`
  position: relative;
  appearance: none;
  border: none;
  background-color: unset;
  color: ${colors.white};
  text-transform: uppercase;
  cursor: pointer;
  font-size: 16px;
  padding: unset;
  margin: unset;
`

export const ListItemWithChildren = styled.li`
  display: flex;
  color: ${colors.white};
  height: 100%;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 66px;
    top: 118px;
    z-index: 2;
    border-bottom: unset;
  }

  &:hover {
    & > div[data-list-items-container] {
      display: flex;
    }

    &:before {
      border-bottom: 3px solid ${colors.blue};
    }
  }
`
export const ListItemChildren = styled.li`
  text-transform: none;
  font-size: 21pt;

  ::after {
    content: ' ';
  }

  :hover::after {
    content: ' →';
  }

  a:hover {
    border-bottom: 3px solid ${colors.blue};
  }
`

export const ListMenu = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 200px;
`

export const ListItemsContainer = styled.div`
  color: ${colors.white};
  text-transform: uppercase;
  inset: 0;
  position: absolute;
  height: 312px;
  background: ${colors.black};
  top: 128px;
  justify-content: center;
  align-items: center;
  gap: 190px;
  z-index: 1;
  display: none;

  &:hover {
    display: flex;
  }
`

export const ListItemImage = styled.img<IsVisibleProps>`
  width: 150px;
  height: 200px;
  object-fit: contain;
  visibility: ${({ isVisible }) => (isVisible ? `visible` : `hidden`)};
`

export const MerchandiseLink = styled.a`
  display: flex;
  align-items: center;
`

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
`

export const MerchandiseError = styled.span`
  display: flex;
  align-items: center;
`
