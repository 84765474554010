import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  background-color: ${colors.delicateGrey};
  min-height: 224px;
  justify-content: center;
  width: 100%;
`

export const Title = styled.h2`
  color: #102641;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  font-style: italic;
  font-family: Jubilat;
  
  @media (min-width: 1440px) {
    font-size: 36px;
  }
`

export const Content = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #102641;
  text-align: center;
  
  @media (min-width: 1440px) {
    font-size: 16px;
    gap:16px
  }
`

export const FeaturedTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 60px 90px;
  gap: 20px;
  width: 423px;
  align-items: center;
  text-align: center;
    
    @media (min-width: 1440px) {
    width: 452.3px;
  }
  
  @media (min-width: 1920px) {
    width: 448px;
    gap:24px;
  }
`