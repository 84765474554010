import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 50px;
`

export const StyledFooter = styled.footer`
  width: 100%;
  padding: 1rem 0;
  background-color: #F2F2F2;
  font-size: 14px;
  bottom: 0;
  left: 0;

  .footer__links {
    text-align: right;
    margin: 4px 0;
  }

  .footer__links {
    font-weight: 700;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const FooterCopyright = styled.div`
  text-align: center;
  width: 100%;
  font-family: PFDINText;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.black};
`

export const FooterLine = styled.hr`
  margin: 50px 0 30px 0;
  border: 0;
  border-top: 0.5px solid #d3d3d3;
`

export const FooterLogo = styled.img`
  position: absolute;
  left: 0;
  width: 200px;
`

export const FooterList = styled.ul`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 50px;
  justify-content: center;
`

export const FooterListItem = styled.li`
  font-family: PFDINText;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.black};
`
