import { colors } from '@monorepo/theme'
import BaseSearch from '../../atoms/Search'
import { SearchProps } from '../..'
import styled from 'styled-components'

interface HeroCreativeProps {
  background?: {
    mobile?: string
    desk?: string
  }
  color?: string
  backgroundColor?: string
  flexFlow?: string
  resetMinHeight?: boolean
}

const Hero = styled.section<HeroCreativeProps>`
  align-items: center;
  background-color: ${(props) => props.backgroundColor || '#000'};
  ${(props) =>
    !!props.background?.mobile &&
    `background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props.background?.mobile});
    background-position: center;
    background-size: cover;
    `}
  color: ${(props) => props.color || colors.white};
  display: flex;
  ${(props) =>
    props.flexFlow === 'row'
      ? 'flex-flow: row nowrap; padding: 0 20px;'
      : 'flex-flow: column wrap; justify-content: center;'}
  ${(props) => !props.resetMinHeight && 'min-height: calc(50vh - 128px);'}
  width: 100%;
  gap:10px;
  
  & > div {
    max-width:370px;
    margin: auto 60px;
    gap: 24px;
    display: flex;
    flex-flow: column;
    
    @media (min-width: 1440px) {
      max-width:523.53px;
    }
    
    & > h1 {
      font-family: 'Jubilat';
      font-size: 40px;
      font-style: italic;
      font-weight: 700;
      justify-content: center;
      text-transform: uppercase;

      @media (min-width: 1440px) {
        font-size: 56px;
      }
    }
    
      & > p {
        font-family: 'PFDINText';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        text-align: start;
        justify-content: center;
        letter-spacing: -0.005em;
        max-width: 600px;

        @media (min-width: 1440px) {
          font-size: 18px;
          line-height: 24.8px;
        }
      }
  }

  @media screen and (min-width: 767px) {
    ${(props) =>
    !!props.background?.desk &&
    `background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props.background?.desk});
      `}
    font-size: 55px;
  }

  @media (min-width: 1024px) {
    min-height: 381.16px;
  }

  @media (min-width: 1440px) {
    min-height: 536px;
  }

  @media (min-width: 1920px) {
    min-height: 714.67px;
  }
`

const Search = styled(BaseSearch).attrs(({ color, backgroundColor }) => ({
  backgroundColor: backgroundColor || '#ffffff00',
  color: color || '#ffffff',
  width: 325,
  height: 36,
})) <SearchProps>``

export { Hero, Search }
