"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const S = (0, tslib_1.__importStar)(require("./styles"));
const GridProducts = ({ gridProducts }) => {
    return ((0, jsx_runtime_1.jsx)(S.Grid, Object.assign({ "data-testid": "grid-products" }, { children: gridProducts.map((item, key) => {
            return ((0, jsx_runtime_1.jsx)(S.ProductsList, { children: (0, jsx_runtime_1.jsx)(S.CardProduct, { title: item.title, images: [item.image], link: item.link, subtitle: "", aditionalInfo: "" }, void 0) }, key));
        }) }), void 0));
};
exports.default = GridProducts;
