import styled from 'styled-components'

// import { TextInput as MTextInput } from '@monorepo/components'
import { Field } from 'formik'
import Select from 'react-select'
import { Title as TitleComponent } from '@monorepo/components'
import { colors } from '@monorepo/theme'

export const Title = styled(TitleComponent)`
  color: ${colors.white};
  font-family: PFDINText;
`

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  flex: 1;
`

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
`

export const Wrapper = styled.section`
  & h1 {
    font-size: 42px;
  }
`

interface RowProps {
  marginBetween?: number
}

export const Row = styled.section<RowProps>`
  gap: 10px;
  display: flex;
  flex-direction: row;
  flex: 1;

  margin: 15px 0;
  > * {
    &:first-child {
      margin-right: ${({ marginBetween }) => marginBetween ?? '16'}px;
    }
  }
`

export const Form = styled.form`
  margin-top: 25px;
`

export const ButtonWrapper = styled.button`
  background-color: transparent;
  border: none;

  position: absolute;
  top: 25px;
  right: 21px;

  cursor: pointer;
`

export const TextInput = styled(Field)`
  flex: 1;
  height: 71px;
  background: #ffffff;
  border: 1px solid #dcdfe2;
  box-sizing: border-box;
  font-family: PFDINText;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  padding: 24px;
  margin: 0px !important;
`

export const SelectInput = styled(Select)`
  display: flex;
  flex: 1;
  & -control {
    flex: 1;
  }
`

export const TextInputArea = styled.textarea`
  display: flex;
  flex: 2;
  height: 142px;
  background: #ffffff;
  border: 1px solid #dcdfe2;
  box-sizing: border-box;
  font-family: PFDINText;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  padding: 24px;
  margin: 0 !important;
`

export const ErrorItem = styled.div`
  display: flex;
  flex: 1;
  font-family: PFDINText;
  font-size: 18px;
  color: ${colors.red};
`

export const Button = styled.button`
  font-family: PFDINText;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.white};
  background-color: ${colors.blue};
  padding: 17px 20px;
  border: 1px solid ${colors.blue};
  text-transform: uppercase;
`
