"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const S = (0, tslib_1.__importStar)(require("./styles"));
const atoms_1 = require("../../atoms");
const core_1 = require("@material-ui/core");
const Shop = ({ onClickProfile, onClickShopBag }) => {
    return ((0, jsx_runtime_1.jsxs)(S.ContainerShop, { children: [(0, jsx_runtime_1.jsx)(atoms_1.Text, Object.assign({ color: "white", size: "15px" }, { children: "Co-Op $0.00" }), void 0), (0, jsx_runtime_1.jsx)(core_1.IconButton, Object.assign({ onClick: onClickProfile }, { children: (0, jsx_runtime_1.jsx)(atoms_1.Icon, { icon: "Profile" }, void 0) }), void 0), (0, jsx_runtime_1.jsx)(core_1.IconButton, Object.assign({ onClick: onClickShopBag }, { children: (0, jsx_runtime_1.jsx)(atoms_1.Icon, { icon: "Shopbag" }, void 0) }), void 0)] }, void 0));
};
exports.default = Shop;
