import { Input } from '@monorepo/components'
import { Field, Formik, setNestedObjectValues } from 'formik'
import Select from 'react-select'
import React from 'react'
import * as S from '../styles'
import * as Yup from 'yup'
import { DealerForm as IDealerForm } from '../../../templates/TeamMembers'
import { Checkbox, FormControlLabel } from '@mui/material'
import { pink } from '@mui/material/colors'
// import { DealerHelper } from '@monorepo/infra'

export type InitialValuesParsed = {
  firstName: string
  lastName: string
  email: string
  channels: { value: string; label: string }[] | null
  types: { value: string; label: string }[] | null
  status: { value: string; label: string }
  hasCoop: boolean
  userId: string
  dealerId: string
}

type DealerFormProps = {
  close: () => void
  actionForm: (data: IDealerForm) => void
  initialValues?: InitialValuesParsed
  error: string
  availableChannelOptions: { value: string; label: string }[]
  isPrincipal?: boolean
}

const TeamMemberSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter with first name.'),
  lastName: Yup.string().required('Please enter with last name.'),
  email: Yup.string().required('Please enter with email address.'),
})

const DealerForm: React.FC<DealerFormProps> = ({
  initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    channels: null,
    types: null,
    status: { value: '', label: '' },
    hasCoop: false,
    userId: '',
    dealerId: '',
  },
  actionForm,
  close,
  error,
  availableChannelOptions,
  isPrincipal,
}) => {
  const dealerStatus = [
    {
      value: '/dealer',
      label: 'Member',
    },
    {
      value: '/dealerAdmin',
      label: 'Admin',
    },
  ]

  const isEditMode = !!initialValues.email

  return (
    <>
      {error !== '' && <S.ErrorMessage>{error}</S.ErrorMessage>}
      <Formik
        initialValues={initialValues}
        validationSchema={TeamMemberSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false)
          const valuesParsed = {
            ...values,
            email: values?.email?.trim(),
            channels: values?.channels?.map((data) => data.value) || [''],
            types: values?.types?.map((data) => data.value) || [''],
          }
          actionForm(valuesParsed)
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
          validateForm,
          submitForm,
          setTouched,
          handleChange,
        }) => (
          <S.DialogForm>
            <Field
              id="firstName"
              name="firstName"
              component={Input}
              value={values.firstName}
              invalid={touched.firstName && errors.firstName}
              invalidMessage={errors.firstName}
              label="First name"
              onBlur={() => setFieldTouched('firstName')}
              onChange={(option: { target: { value: string } }) => {
                setFieldValue('firstName', option.target.value)
              }}
            />

            <Field
              id="lastName"
              name="lastName"
              component={Input}
              value={values.lastName}
              invalid={touched.lastName && errors.lastName}
              invalidMessage={errors.lastName}
              label="Last name"
              onBlur={() => {
                setFieldTouched('lastName')
              }}
              onChange={(option: { target: { value: string } }) => {
                setFieldValue('lastName', option.target.value)
              }}
            />

            <Field
              id="email"
              name="email"
              component={Input}
              value={values.email}
              invalid={touched.email && errors.email}
              invalidMessage={errors.email}
              label="Email Address"
              disabled={isEditMode}
              onBlur={() => {
                setFieldTouched('email')
              }}
              onChange={(option: { target: { value: string } }) => {
                setFieldValue('email', option.target.value)
              }}
            />

            <Select
              name="channels"
              placeholder="Channel(s)"
              isMulti
              options={availableChannelOptions}
              value={values.channels}
              onChange={(newValue) => setFieldValue('channels', newValue)}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={S.MultiSelectStyle}
            />

            {/* <Select
            name="types"
            placeholder="Type(s)"
            isMulti
            options={DealerHelper.dealerTypes}
            value={DealerHelper.dealerTypes.filter(
              ({ value }: { value: string }) => {
                return values.types?.some((item) => {
                  return item.value === value
                })
              }
            )}
            onChange={(newValue) => setFieldValue('types', newValue)}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={S.MultiSelectStyle}
          /> */}

            <Select
              name="status"
              placeholder="Status"
              options={dealerStatus}
              components={{
                IndicatorSeparator: () => null,
              }}
              value={values.status || ''}
              onChange={(newValue) => setFieldValue('status', newValue)}
              styles={S.MultiSelectStyle}
              isDisabled={isPrincipal}
            />

            <FormControlLabel
              label="Allow payments with Co-Op"
              sx={{
                color: pink[800],
                '& .MuiFormControlLabel-label': { fontWeight: 600 },
              }}
              control={
                <Checkbox
                  id="hasCoop"
                  name="hasCoop"
                  checked={values.hasCoop}
                  onChange={(event, checked) => {
                    setFieldValue('hasCoop', checked)
                  }}
                  sx={{
                    color: pink[800],
                    '&.Mui-checked': { color: pink[600] },
                    '& .MuiSvgIcon-root': { fontSize: 28 },
                  }}
                  disabled={isPrincipal}
                />
              }
            />

            <S.DialogActions>
              <S.ModalButton
                colorOption="stroke"
                label="CANCEL"
                onClick={() => close()}
              />
              <S.ModalButton
                colorOption="black"
                label="SAVE"
                onClick={() => {
                  validateForm().then((errors) => {
                    setTouched(setNestedObjectValues(errors, true))
                  })
                  submitForm()
                }}
              />
            </S.DialogActions>
          </S.DialogForm>
        )}
      </Formik>
    </>
  )
}

export { DealerForm }
