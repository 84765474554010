import { Tab, TabList, TabPanels, Tabs } from '@reach/tabs'

import { Button as ButtonComponent } from '@monorepo/components'
import { Drawer as MuiDrawer } from '@material-ui/core'
import ReachDialog from '@reach/dialog'
import { colors } from '@monorepo/theme'
import styled from 'styled-components'

const Wrap = styled.div`
  padding: 20px;

  ::-webkit-scrollbar {
    display: none;
  }
`

const Header = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 25px;
  width: 100%;
`

const ProductTitle = styled.h1`
  font-family: 'PFDINText';
  font-size: 32px;
  font-weight: 700;
`

const VariantPrice = styled.h2`
  font-family: 'Jubilat';
  font-size: 32px;
  font-weight: 700;
  font-style: italic;
  padding-bottom: 14px;
`

const ProductDescription = styled.p`
  font-family: 'PFDINText';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.2;
  margin-bottom: 40px;
  text-align: left;
  overflow-wrap: normal;
`

const OrderTab = styled(Tabs)`
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
`

const Sizes = styled.div`
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  padding-bottom: 40px;
`

const TabTitleList = styled(TabList)`
  background: transparent;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const TabTitle = styled(Tab)`
  font-family: 'PFDINText';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 2px;
  padding: 25px 10px;
  text-align: left;
  text-transform: uppercase;
`

const Panels = styled(TabPanels)`
  padding: 32px 0;
`

const Panel = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  justify-content: flex-start;
`

const PanelSize = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

const PanelTitle = styled.h1`
  margin-bottom: 40px;
  font-size: 12pt;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
`

const PanelCustom = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  justify-content: flex-start;
`

const Card = styled.div<{ isActive?: boolean }>`
  align-items: center;
  border: ${(props) =>
    props.isActive ? '3px solid #102641' : '0.4px solid #405167'};
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  min-height: 86px;
  min-width: 153px;
`

const CardSize = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  border: ${(props) =>
    props.isActive ? '3px solid #102641' : '0.4px solid #405167'};
  cursor: pointer;
  min-height: 86px;
  width: 153px;
`

const SizeName = styled.p`
  text-align: center;
  line-height: 20px;
  word-wrap: break-word;
`

const TextArea = styled.textarea`
  padding: 18px;
  font-family: PFDINTEXT;
  font-size: 12pt;
  height: 86px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgb(202, 202, 202);
  resize: none;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const FormTitle = styled.h1`
  font-weight: 500;
  font-size: 24px;
  padding: 20px;
`

const Customize = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 100%;
  height: 100%;
`

const Customization = styled.div`
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  padding-bottom: 40px;
`

const CustomizationColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

const CustomizationCard = styled.div<{ hasCustomization?: boolean }>`
  flex-direction: ${(props) => (props.hasCustomization ? 'row' : 'column')};
  justify-content: ${(props) =>
    props.hasCustomization ? 'space-between' : 'center'};
  text-align: ${(props) => (props.hasCustomization ? 'unset' : 'center')};
  display: flex;
  align-items: center;
  padding: 24px;
  padding-left: 32px;
  gap: 24px;
  background: #f9f9f9;
  width: 100%;
  height: 100%;
  min-height: 175px;
`

const Form = styled.form`
  width: 100%;
  height: 100%;
  padding-top: 86px;
`

const Button = styled(ButtonComponent)`
  width: 162px;
  display: flex;
  justify-content: center;
`

const CustomizationCardTitle = styled.p`
  font-size: 22px;
  font-weight: 500;
`
const CustomizationAction = styled.button`
  width: min-content;
  height: min-content;
  font-weight: 700;
  border: 0;
  background: unset;
  border-bottom: 1px solid black;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

export const CustomizationButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`

export const PreviewButton = styled.button`
  background-color: ${colors.grey};
  color: ${colors.white};
  text-transform: uppercase;
  width: 162px;
  height: 54.5px;
  font-family: PFDINText;
  font-size: 11pt;
  outline: none;
  border: none;
  cursor: pointer;
`

export const CartDrawer = styled(MuiDrawer)`
  & .MuiDrawer-paper {
    width: 40%;
    overflow-x: hidden;
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const CaroselImage = styled.div`
  & img {
    margin: 0 auto;
    max-width: 476px;
    max-height: 616px;
  }
`

export const CloseModalButton = styled(Button)`
  height: 30px;
  width: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 32px;
  top: 32px;
`

export const Dialog = styled(ReachDialog)`
  height: 80%;
  min-height: 80%;
  overflow: auto;
  position: relative;
  padding: 50px;
`

export const Instructions = styled.p`
  font-size: 0.8em;
  color: ${colors.black};
`

export {
  Wrap,
  Header,
  ProductTitle,
  VariantPrice,
  ProductDescription,
  OrderTab,
  TabTitleList,
  TabTitle,
  Panels,
  Panel,
  PanelSize,
  PanelTitle,
  PanelCustom,
  Card,
  CardSize,
  SizeName,
  Sizes,
  TextArea,
  Column,
  FormTitle,
  Customize,
  Form,
  Button,
  Row,
  Customization,
  CustomizationColumn,
  CustomizationCard,
  CustomizationCardTitle,
  CustomizationAction,
}
