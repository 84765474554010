"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressesProvider = void 0;
var tslib_1 = require("tslib");
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var context_1 = require("./context");
var reducer_1 = require("./reducer");
var AddressesProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useReducer)(reducer_1.addressesReducer, []), billingAddresses = _b[0], dispatchBillingAddresses = _b[1];
    var _c = (0, react_1.useReducer)(reducer_1.addressesReducer, []), shippingAddresses = _c[0], dispatchShippingAddresses = _c[1];
    var _d = (0, react_1.useReducer)(reducer_1.defaultAddressReducer, {}), defaultBillingAddress = _d[0], dispatchDefaultBillingAddress = _d[1];
    var _e = (0, react_1.useReducer)(reducer_1.defaultAddressReducer, {}), defaultShippingAddress = _e[0], dispatchDefaultShippingAddress = _e[1];
    return ((0, jsx_runtime_1.jsx)(context_1.AddressesContext.Provider, (0, tslib_1.__assign)({ value: {
            billingAddresses: billingAddresses,
            shippingAddresses: shippingAddresses,
            defaultBillingAddress: defaultBillingAddress,
            defaultShippingAddress: defaultShippingAddress,
            dispatchBillingAddresses: dispatchBillingAddresses,
            dispatchShippingAddresses: dispatchShippingAddresses,
            dispatchDefaultBillingAddress: dispatchDefaultBillingAddress,
            dispatchDefaultShippingAddress: dispatchDefaultShippingAddress,
        } }, { children: children }), void 0));
};
exports.AddressesProvider = AddressesProvider;
