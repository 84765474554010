"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Messages = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const S = (0, tslib_1.__importStar)(require("./styles"));
const __1 = require("../..");
const date_fns_1 = require("date-fns");
const Messages = ({ messages, userEmail, subject, onSendMessage, }) => {
    const [newMessage, setNewMessage] = (0, react_1.useState)(null);
    const wasSent = (user) => {
        return user.toLowerCase() === userEmail.toLowerCase();
    };
    const handleSendMessage = () => {
        onSendMessage(newMessage);
        setNewMessage('');
    };
    return ((0, jsx_runtime_1.jsxs)(S.Container, { children: [(0, jsx_runtime_1.jsx)(S.Subject, { children: `Subject: ${subject}` }, void 0), messages.length ? ((0, jsx_runtime_1.jsx)(S.MessageList, { children: messages.map((message, index, arr) => {
                    const currentDate = new Date(message.createdOn);
                    const newDate = (0, date_fns_1.format)(currentDate, 'MMMM d, y');
                    const previousItem = index > 0 ? arr[index - 1] : { createdOn: '0' };
                    const previousDate = (0, date_fns_1.format)(new Date(previousItem.createdOn), 'MMMM d, y');
                    const sent = wasSent(message.createdBy);
                    return ((0, jsx_runtime_1.jsxs)(S.MessageItem, { children: [newDate !== previousDate && ((0, jsx_runtime_1.jsx)(S.DateDisplay, { children: newDate }, void 0)), (0, jsx_runtime_1.jsxs)(S.MessageContainer, Object.assign({ sent: sent }, { children: [(0, jsx_runtime_1.jsx)(S.TimeDisplay, { children: (0, date_fns_1.format)(currentDate, 'h:mm a') }, void 0), (0, jsx_runtime_1.jsx)(S.Message, Object.assign({ sent: sent }, { children: message.message }), void 0)] }), void 0)] }, `orderItemMessage-${index}`));
                }) }, void 0)) : ((0, jsx_runtime_1.jsx)("p", { children: "There are no messages currently; send one below." }, void 0)), (0, jsx_runtime_1.jsxs)(S.FormContainer, { children: [(0, jsx_runtime_1.jsx)(__1.Input, { id: 'newMessage', label: 'Message', value: newMessage, onChange: (event) => setNewMessage(event.target.value) }, void 0), (0, jsx_runtime_1.jsx)(S.SendMessageButton, Object.assign({ onClick: () => handleSendMessage() }, { children: "Send" }), void 0)] }, void 0)] }, void 0));
};
exports.Messages = Messages;
exports.default = exports.Messages;
