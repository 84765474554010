"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const S = (0, tslib_1.__importStar)(require("./styles"));
const Search = (_a) => {
    var { height, width, backgroundColor, placeholder = 'Search', iconSize, value, onChange } = _a, props = (0, tslib_1.__rest)(_a, ["height", "width", "backgroundColor", "placeholder", "iconSize", "value", "onChange"]);
    const searchInputProps = {
        height,
        width,
        backgroundColor,
        placeholder,
    };
    const isInputControlled = typeof value === 'string';
    return ((0, jsx_runtime_1.jsxs)(S.SearchContainer, { children: [(0, jsx_runtime_1.jsx)(S.StyledIcon, { size: 18, hasFill: false, icon: "Search" }, void 0), isInputControlled ? ((0, jsx_runtime_1.jsx)(S.SearchInput, Object.assign({}, searchInputProps, { onChange: onChange, value: value }), void 0)) : ((0, jsx_runtime_1.jsx)(S.SearchInput, Object.assign({}, searchInputProps), void 0))] }, void 0));
};
exports.default = Search;
