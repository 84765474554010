import styled from 'styled-components'
import { colors } from '@monorepo/theme'

export const Section = styled.section`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  gap: 140px;
  background-color: ${colors.white};
  justify-content: normal;
  align-items: center;
  
  @media (max-width: 1140px) {
    gap: 20px;
  }
  
  @media (min-width: 1920px) {
    justify-content: center;
  }
`

export const Container = styled.div`
  flex:1;
  align-self: center;
  max-width: 373px;
  margin: 0 0 0 8rem;

  @media (max-width: 1140px) {
    gap: 40px;
  }
  
  @media (min-width: 1400px) {
    margin: 0 8rem 0 10rem;
  }
  @media (min-width: 1680px) {
    margin: 0 15rem 0 10rem;
  }
  @media (min-width: 1780px) {
    margin: 0 20rem 0 10rem;
  }

  @media (min-width: 1920px) {
  margin: 0 0 0 10rem;
  }
`

export const Title = styled.h1`
  color: #102641;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;

  .title__letter-blue {
    color: ${colors.blue};
  }

  .title_italic {
    font-style: italic;
    font-family: Jubilat;
  }

  @media (max-width: 426px) {
    font-size: 2rem;
  }
`

export const Content = styled.p`
  margin: 1rem 0 1.5rem;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: #102641;

  @media (max-width: 426px) {
    font-size: 1rem;
  }
`


export const ImageContainer = styled.div`
  flex: 1 1 0%;
  padding-bottom: 2rem;
  padding-right: 0;
  max-width: 600px;
  
  @media (max-width: 1140px) {
    max-width: 100%;
  }
  
  @media (min-width: 1920px) {
    padding-right: 2rem;
  }
`

export const ImageBanner = styled.img`
  width: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`