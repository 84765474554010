import { Button as ButtonComponent } from '@monorepo/components'
import ReachDialog from '@reach/dialog'
import styled from 'styled-components'
import { colors } from '@monorepo/theme'

export const PageTitle = styled.h1`
  font-family: PFDINText;
  font-weight: 700;
  font-size: 36px;
  text-transform: uppercase;
`

export const Line = styled.hr`
  margin: 0;
  border: 0;
  border-top: 1px solid #9fa8b3;
`

export const Container = styled.div`
  margin: 50px;
  display: flex;
  flex-direction: column;
  gap: 35px;
`

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const ItemsListContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  margin-right: 18px;
`

export const ButtonsContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: row;
`

export const ItemsList = styled.ul`
  list-style-type: none;
  flex-direction: column;
`

export const ItemsListItem = styled.li`
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const ItemsListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
`

export const ItemTitle = styled.h2`
  font-family: PFDINText;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 26px;
`

export const ItemDeliveryAndSizeContainer = styled.div``

export const ItemText = styled.p`
  font-family: PFDINText;
  font-weight: normal;
  font-size: 24px;
  text-transform: none;
  color: #9fa8b3;
`

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 34px 38px 0 38px;
  gap: 26px;
  position: relative;
`

export const RemoveButton = styled.button`
  position: absolute;
  bottom: 0;
  all: revert;
  flex: 1
  align-self: flex-end;
  justify-self: center;
  background-color: transparent;
  border: 0;
  text-decoration: underline;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`

export const TextArea = styled.textarea`
  border: 1px solid #cacaca;
  border-radius: 10px;
  padding: 25px 19px;
  font-family: PFDINText;
  font-weight: 400;
  font-size: 15px;
  color: #868b93;
`

export const SummaryContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: #f6f8f9;
  padding: 32px 24px;
  flex-direction: column;
  gap: 32px;
  align-self: flex-start;
`

export const SummaryTitle = styled.h3`
  font-family: PFDINText;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 22px;
`

export const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const SummaryText = styled.p<{ flexBasis?: string }>`
  font-family: PFDINText;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  flex-basis: ${(props) => props.flexBasis || 'auto'};
`

export const Button = styled.button`
  background-color: #337ace;
  border: 1px solid #337ace;
  color: #ffffff;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 16px;
  padding: 17px 30px 17px 30px;
  align-self: flex-start;
`

export const DialogButton = styled(ButtonComponent)`
  width: 162px;
  display: flex;
  justify-content: center;
`

export const Dialog = styled(ReachDialog)`
  overflow: auto;
  position: relative;
  padding: 50px;
  width: fit-content;
`
export const CloseModalButton = styled(DialogButton)`
  height: 30px;
  width: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 32px;
  top: 32px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const TitleItem = styled.div``

export const CoopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CoopTag = styled.div`
  border-radius: 20px;
  height: 30px;
  width: 150px;
  font-family: PFDINText;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.green};
`
