"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceElement = exports.defaultAddressReducer = exports.addressesReducer = void 0;
var tslib_1 = require("tslib");
var actions_1 = require("./actions");
var addressesReducer = function (state, action) {
    var _a;
    switch (action.type) {
        case actions_1.ActionAdressType.SetAddressesList:
            return (_a = action.payload) === null || _a === void 0 ? void 0 : _a.addresses;
        case actions_1.ActionAdressType.AddAddress:
            return (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], state, true), [action.payload.address], false);
        case actions_1.ActionAdressType.DeleteAddress:
            return deleteAddress(state, action.payload.addressIndex);
        case actions_1.ActionAdressType.ReplaceAddress:
            return replaceAddress(state, action.payload.index, action.payload.newValue);
        case actions_1.ActionAdressType.ReplaceDefaultAddress:
            return (0, exports.replaceElement)(state, action.payload.address);
        case actions_1.ActionAdressType.UpdateAddressInList:
            return updateAddress(state, action.payload.elementIndex, action.payload.field, action.payload.value);
        case actions_1.ActionAdressType.ToggleDefaultAddressByIndex:
            return changeDefault(state, action.payload.index);
        default:
            return state;
    }
};
exports.addressesReducer = addressesReducer;
var defaultAddressReducer = function (state, action) {
    switch (action.type) {
        case actions_1.ActionAdressType.InitializeDefaultAddress:
            return findDefaultElement(action.payload.value);
        case actions_1.ActionAdressType.UpdatetZipCode:
            return (0, tslib_1.__assign)((0, tslib_1.__assign)({}, state), { zipCode: action.payload.value });
        case actions_1.ActionAdressType.UpdateCity:
            return (0, tslib_1.__assign)((0, tslib_1.__assign)({}, state), { city: action.payload.value });
        case actions_1.ActionAdressType.UpdateAddress:
            return (0, tslib_1.__assign)((0, tslib_1.__assign)({}, state), { address: action.payload.value });
        case actions_1.ActionAdressType.UpdateState:
            return (0, tslib_1.__assign)((0, tslib_1.__assign)({}, state), { state: action.payload.value });
        default:
            return state;
    }
};
exports.defaultAddressReducer = defaultAddressReducer;
var findDefaultElement = function (addresses) {
    var arrayCopy = (0, tslib_1.__spreadArray)([], addresses, true);
    return arrayCopy.filter(function (address) { return (address === null || address === void 0 ? void 0 : address.default) === true; }).pop();
};
var updateAddress = function (addresses, index, field, value) {
    var arrayCopy = (0, tslib_1.__spreadArray)([], addresses, true);
    var elementInArray = arrayCopy[index];
    if (field === 'zipCode')
        elementInArray.zipCode = value;
    else if (field === 'city')
        elementInArray.city = value;
    else if (field === 'address')
        elementInArray.address = value;
    else if (field === 'state')
        elementInArray.state = value;
    arrayCopy.splice(index, 1, elementInArray);
    return arrayCopy;
};
var deleteAddress = function (addresses, index) {
    var arrayCopy = (0, tslib_1.__spreadArray)([], addresses, true);
    if (arrayCopy.length === 1)
        return [];
    arrayCopy.splice(index, 1);
    return arrayCopy;
};
var replaceAddress = function (addresses, index, newValue) {
    var arrayCopy = (0, tslib_1.__spreadArray)([], addresses, true);
    arrayCopy[index] = newValue;
    return arrayCopy;
};
var changeDefault = function (addresses, index) {
    var arrayCopy = (0, tslib_1.__spreadArray)([], addresses, true);
    var defaultIndex = addresses.findIndex(function (address) { return address.default === true; });
    if (defaultIndex !== -1) {
        var address_1 = (0, tslib_1.__assign)((0, tslib_1.__assign)({}, addresses[defaultIndex]), { default: false });
        arrayCopy.splice(defaultIndex, 1, address_1);
    }
    var address = (0, tslib_1.__assign)((0, tslib_1.__assign)({}, addresses[index]), { default: !addresses[index].default });
    arrayCopy.splice(index, 1, address);
    return arrayCopy;
};
var replaceElement = function (addresses, address) {
    if (!addresses)
        return addresses;
    var arrayCopy = (0, tslib_1.__spreadArray)([], addresses, true);
    var index = addresses.findIndex(function (address) { return address.default === true; });
    arrayCopy.splice(index, 1, address);
    return arrayCopy;
};
exports.replaceElement = replaceElement;
