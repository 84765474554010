import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '@monorepo/theme'

interface HeroCreative2ContainerProps {
  background?: string
}

export const HeroCreative2Container = styled.div<HeroCreative2ContainerProps>`
  width: 100%;
  display: flex;
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const HeroCreative2TextContainer = styled.div`
  width: 50%;
  padding: 40px;
  font-size: larger;
`

export const HeroCreative2TextContainerUpTitle = styled.div`
  font-size: larger;
  font-weight: bold;
  text-transform: uppercase;
  color: ${colors.blue};
`

export const HeroCreative2TextContainerTitle = styled.div`
  font-family: Jubilat;
  font-style: italic;
  font-size: 52px;
  font-weight: 900;
  text-transform: uppercase;
  color: ${colors.white};
  padding: 10px 0;
`

export const HeroCreative2TextContainerDescription = styled.div`
  color: ${colors.white};
  padding: 10px 0;
`

export const HeroCreative2TextContainerButtons = styled.div`
  padding: 10px 0;
`

export const ButtonLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  width: 140.99px;
  height: 49.09px;
  left: 136.28px;
  top: 168px;
  background: #337ace;
  text-transform: uppercase;
`
