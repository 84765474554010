import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import * as S from './style'

import React from 'react'

import { Event } from '@monorepo/interfaces'
import { EventList } from '../..'
import HeroCreative from '../../organisms/HeroCreative'
import InfiniteScroll from 'react-infinite-scroll-component'
import { colors } from '@monorepo/theme'
import { Spinner } from '@monorepo/components'

interface AllEventsProps {
  result: Event[]
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  clearSearchByDate: () => void
  setSearchByDate: (searchTerm: string) => void
  clearSearchTerm: () => void
  incrementPage: () => void
  hasMorePages?: boolean
  loading?: boolean
}

const AllEvents: React.FC<AllEventsProps> = ({
  result,
  incrementPage,
  searchTerm,
  setSearchTerm,
  setSearchByDate,
  clearSearchByDate,
  clearSearchTerm,
  hasMorePages,
  loading,
}) => {
  

  return (
    <>
      <HeroCreative
      color={colors.darkBlue}
      backgroundColor={colors.white}
        title="events"
        flexFlow="row"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search city, event name, or keyword"
        clearSearchTerm={clearSearchTerm}
        isEventPage={true}
        eventProps={{
          setSearchByDate,
          clearSearchByDate,
        }}
      />
        {searchTerm && searchTerm !== '' && (
          <S.SearchInfo>{`Search results for "${searchTerm}"`}</S.SearchInfo>
        )}
      <S.InfiniteScrollContainer>
        <InfiniteScroll
          dataLength={result?.length} //This is important field to render the next data
          next={incrementPage}
          hasMore={hasMorePages || false}
          loader={
            <div style={{ padding: '1rem' }}>
              <Spinner />
            </div>
          }
        >
          <EventList events={result} loading={loading} />
        </InfiniteScroll>
      </S.InfiniteScrollContainer>
    </>
  )
}

export default AllEvents
