"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionAdressType = void 0;
var ActionAdressType;
(function (ActionAdressType) {
    ActionAdressType[ActionAdressType["ReplaceDefaultAddress"] = 0] = "ReplaceDefaultAddress";
    ActionAdressType[ActionAdressType["AddAddress"] = 1] = "AddAddress";
    ActionAdressType[ActionAdressType["DeleteAddress"] = 2] = "DeleteAddress";
    ActionAdressType[ActionAdressType["ReplaceAddress"] = 3] = "ReplaceAddress";
    ActionAdressType[ActionAdressType["SetAddressesList"] = 4] = "SetAddressesList";
    ActionAdressType[ActionAdressType["InitializeDefaultAddress"] = 5] = "InitializeDefaultAddress";
    ActionAdressType[ActionAdressType["UpdateAddressInList"] = 6] = "UpdateAddressInList";
    ActionAdressType[ActionAdressType["UpdatetZipCode"] = 7] = "UpdatetZipCode";
    ActionAdressType[ActionAdressType["UpdateCity"] = 8] = "UpdateCity";
    ActionAdressType[ActionAdressType["UpdateAddress"] = 9] = "UpdateAddress";
    ActionAdressType[ActionAdressType["UpdateState"] = 10] = "UpdateState";
    ActionAdressType[ActionAdressType["ToggleDefaultAddressByIndex"] = 11] = "ToggleDefaultAddressByIndex";
})(ActionAdressType = exports.ActionAdressType || (exports.ActionAdressType = {}));
