import * as S from './styles'

import { Link, useRouteMatch } from 'react-router-dom'

import { Notification } from '@monorepo/interfaces'
import { useState } from 'react'
import { NotificationType } from '@monorepo/infra'

export interface SelectableNotification extends Notification {
  selected?: boolean
}

export interface SectionNotificationsListProps {
  notifications: Notification[] | SelectableNotification[]
  page: number
  markAsRead: (notificationId: string) => void
  selectable?: boolean
  selectOne: (notificationId: string, selected: boolean) => void
}

function getFormattedDate(value: string) {
  if (!value) return

  const date = new Date(value)
  const today = new Date()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month

  let day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  const hour = date.getHours().toString()

  const minutes = date.getMinutes().toString()

  if (date > today) {
    return hour + ':' + minutes
  }

  return month + '/' + day
}

const SectionNotificationsList: React.FC<SectionNotificationsListProps> = ({
  notifications,
  page,
  markAsRead,
  selectable,
  selectOne,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const { path } = useRouteMatch()

  return (
    <S.Container>
      {notifications?.map(
        (notification: SelectableNotification, index: number) => (
          <S.Row>
            <S.CheckboxContainer hidden={!selectable}>
              <input
                type="checkbox"
                key={notification._id}
                checked={notification.selected}
                onChange={(e) =>
                  selectOne(notification._id as string, e.target.checked)
                }
              />
            </S.CheckboxContainer>
            <S.LinkContainer>
              <Link
                key={index}
                to={
                  notification.type === NotificationType.ORDER &&
                  !!notification.relation
                    ? `/profile/orders/details/${notification.relation}`
                    : `${path}/${notification._id}?p=${page}`
                }
                onClick={() => {
                  if (notification._id) {
                    markAsRead(notification._id)
                  }
                  setSelectedIndex(index)
                }}
              >
                <S.NotificationCard isSelected={selectedIndex === index}>
                  <S.Content>
                    <S.CardHeader>
                      <S.UserProfileBadge>
                        {notification?.from?.toUpperCase().charAt(0) ?? 'T'}
                      </S.UserProfileBadge>
                      <S.Title
                        fontWeight={notification?.status === 1 ? '600' : '400'}
                      >
                        {notification?.from}
                      </S.Title>
                      <S.Hours>
                        {getFormattedDate(notification?.createdOn)}
                      </S.Hours>
                    </S.CardHeader>
                    {/* <S.Subject>{notification.subject}</S.Subject> */}
                  </S.Content>
                </S.NotificationCard>
              </Link>
            </S.LinkContainer>
          </S.Row>
        )
      )}
    </S.Container>
  )
}

export default SectionNotificationsList
