import { MenuButton, MenuList } from '@reach/menu-button'
import { colors } from '@monorepo/theme'
import styled from 'styled-components'

const Button = styled(MenuButton)`
  align-items: center;
  background-color: #fff;
  border: 0;
  display: flex;
  font-family: 'PFDINText';
  font-size: 18px;
  font-weight: 26.5px;
  padding: 8px 40px;
  font-weight: 500;
  color:${colors.darkBlue};

  & > figure {
    margin-left: 5px;
  }
`

const List = styled(MenuList)`
  background-color: #fbfbfb;
  padding: 21px;
  font-family: PFDINText;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.2px;
  text-align: left;

  & > div {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    min-width: 213px;
    padding: 6px 0;

    & > label {
      align-items: center;
      display: flex;

      & > input {
        margin-right: 8px;
      }
    }

    & > span {
      color: #8793a0;
    }
  }
`

const ClearFilterOption = styled.label`
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.blue};
`

export { Button, List, ClearFilterOption }
