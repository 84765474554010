"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVendorName = exports.getVendorOptions = void 0;
var interfaces_1 = require("@monorepo/interfaces");
var utils_1 = require("../utils");
var getVendorOptions = function () {
    var keys = Object.keys(interfaces_1.Vendor);
    return Array.isArray(keys)
        ? keys
            .filter(function (item) { return !(0, utils_1.isNumeric)(item); })
            .map(function (item) { return ({
            value: interfaces_1.Vendor[item].toString(),
            label: item.toUpperCase(),
        }); })
        : [];
};
exports.getVendorOptions = getVendorOptions;
var getVendorName = function (vendor) {
    var _a;
    return (_a = (0, exports.getVendorOptions)().find(function (item) { return item.value === (vendor === null || vendor === void 0 ? void 0 : vendor.toString()); })) === null || _a === void 0 ? void 0 : _a.label;
};
exports.getVendorName = getVendorName;
