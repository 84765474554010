"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppInsightsErrorBoundary = exports.AppInsightsProvider = void 0;
var tslib_1 = require("tslib");
(0, tslib_1.__exportStar)(require("./setup"), exports);
var provider_1 = require("./provider");
Object.defineProperty(exports, "AppInsightsProvider", { enumerable: true, get: function () { return provider_1.AppInsightsProvider; } });
(0, tslib_1.__exportStar)(require("./useTracking"), exports);
var applicationinsights_react_js_1 = require("@microsoft/applicationinsights-react-js");
Object.defineProperty(exports, "AppInsightsErrorBoundary", { enumerable: true, get: function () { return applicationinsights_react_js_1.AppInsightsErrorBoundary; } });
