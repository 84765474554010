"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartActionType = void 0;
var CartActionType;
(function (CartActionType) {
    CartActionType[CartActionType["AddProduct"] = 0] = "AddProduct";
    CartActionType[CartActionType["AddEvent"] = 1] = "AddEvent";
    CartActionType[CartActionType["IncrementProduct"] = 2] = "IncrementProduct";
    CartActionType[CartActionType["IncrementEvent"] = 3] = "IncrementEvent";
    CartActionType[CartActionType["RemoveProduct"] = 4] = "RemoveProduct";
    CartActionType[CartActionType["RemoveEvent"] = 5] = "RemoveEvent";
    CartActionType[CartActionType["DecrementProduct"] = 6] = "DecrementProduct";
    CartActionType[CartActionType["DecrementEvent"] = 7] = "DecrementEvent";
    CartActionType[CartActionType["ClearItems"] = 8] = "ClearItems";
    CartActionType[CartActionType["UpdateItemsFromDB"] = 9] = "UpdateItemsFromDB";
})(CartActionType = exports.CartActionType || (exports.CartActionType = {}));
