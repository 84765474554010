"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledLabel = void 0;
const tslib_1 = require("tslib");
const styled_components_1 = (0, tslib_1.__importStar)(require("styled-components"));
const inputLabelStyle = `
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;
const getLabelStyle = (type) => {
    const styles = {
        'input-label': inputLabelStyle,
        default: '',
    };
    return styles[type] || styles.default;
};
exports.StyledLabel = styled_components_1.default.label `
  font-family: ATTAleckSansBold;

  ${({ labelStyle }) => (0, styled_components_1.css) `
      ${getLabelStyle(labelStyle)}
    `}
`;
