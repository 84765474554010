"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const framer_motion_1 = require("framer-motion");
const S = (0, tslib_1.__importStar)(require("./styles"));
const FilterButton = ({ label, clicked, onClick, }) => {
    const myTransition = {
        rotate: { type: 'spring', stiffness: 150 },
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(S.ButtonFilter, Object.assign({ "data-testid": "filter-button", onClick: () => onClick(), startIcon: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, Object.assign({ transition: myTransition }, { children: (0, jsx_runtime_1.jsx)(S.FilterIcon, { children: (0, jsx_runtime_1.jsxs)(framer_motion_1.motion.svg, Object.assign({ width: "20", height: "16", viewBox: "0 0 20 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("rect", { y: "3", width: "20", height: "1.5", rx: "0.75", fill: "black" }, void 0), (0, jsx_runtime_1.jsx)("rect", { y: "11", width: "20", height: "1.5", rx: "0.75", fill: "black" }, void 0), (0, jsx_runtime_1.jsx)(framer_motion_1.motion.circle, { cx: 7, cy: "12", r: "3.25", fill: "white", stroke: "black", strokeWidth: "1.5", animate: clicked ? { cx: 13 } : { cx: 7 }, transition: {
                                    cx: { type: 'spring', stiffness: 250 },
                                } }, void 0), (0, jsx_runtime_1.jsx)(framer_motion_1.motion.circle, { cx: 13, cy: "4", r: "3.25", fill: "white", stroke: "black", strokeWidth: "1.5", animate: clicked ? { cx: 7 } : { cx: 13 }, transition: {
                                    cx: { type: 'spring', stiffness: 250 },
                                } }, void 0)] }), void 0) }, void 0) }), void 0), endIcon: (0, jsx_runtime_1.jsx)(S.ArrowDiv, Object.assign({ transition: myTransition, animate: clicked ? { rotate: -180 } : { rotate: 0 } }, { children: (0, jsx_runtime_1.jsx)(S.ArrowForwardIosIcon, {}, void 0) }), void 0) }, { children: label }), void 0) }, void 0));
};
exports.default = FilterButton;
