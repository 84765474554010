import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  ActivityByDealerOrderPaymentRow,
  ActivityByDealerReport,
  ActivityByDealerRow,
} from '@monorepo/interfaces'
import { http, OrderPaymentStatus, roundCurrency } from '@monorepo/infra'
import { format } from 'date-fns'
import * as S from './styles'
import { PageSpinner } from '@monorepo/components'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import moment from 'moment'
import { OrderHelper } from '@monorepo/infra'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const getPaymentsTable = (payments?: ActivityByDealerOrderPaymentRow[]) => {
  if (!Array.isArray(payments) || !payments.length) {
    return undefined
  }

  const totalUnpaid = payments
    .filter((item) => item.paymentStatus === OrderPaymentStatus.UNPAID)
    .reduce((acc, value) => roundCurrency(acc + value.orderTotal), 0)

  const totalPartialUnpaid = payments
    .filter((item) => item.paymentStatus === OrderPaymentStatus.PARTIAL)
    .reduce(
      (acc, value) =>
        roundCurrency(acc + roundCurrency(value.orderTotal - value.paidTotal)),
      0
    )

  const totalPartialPaid = payments
    .filter((item) => item.paymentStatus === OrderPaymentStatus.PARTIAL)
    .reduce((acc, value) => roundCurrency(acc + value.paidTotal), 0)

  const totalPaid = payments
    .filter((item) => item.paymentStatus === OrderPaymentStatus.PAID)
    .reduce((acc, value) => roundCurrency(acc + value.paidTotal), 0)

  return (
    <S.TableContainer>
      <S.Table>
        <S.Caption>Payments</S.Caption>
        <S.TableHead>
          <S.TableRow>
            <S.TableHeadCell>Channel</S.TableHeadCell>
            <S.TableHeadCell>Order #</S.TableHeadCell>
            <S.TableHeadCell>Date</S.TableHeadCell>
            <S.TableHeadCell>Dealer ID</S.TableHeadCell>
            <S.TableHeadCell>Team Member</S.TableHeadCell>
            <S.TableHeadCell>Total</S.TableHeadCell>
            <S.TableHeadCell>Paid</S.TableHeadCell>
            <S.TableHeadCell>Status</S.TableHeadCell>
          </S.TableRow>
        </S.TableHead>
        <S.TableBody>
          {payments
            .sort(
              (
                left: ActivityByDealerOrderPaymentRow,
                right: ActivityByDealerOrderPaymentRow
              ) => left.paymentStatus - right.paymentStatus
            )
            .map((item: ActivityByDealerOrderPaymentRow) => (
              <S.TableRow key={`order${item.orderNumber}`}>
                <S.TableCell>{item.channel}</S.TableCell>
                <S.TableCell>{item.orderNumber}</S.TableCell>
                <S.TableCell>
                  {format(new Date(item.date), 'MM/dd/yyyy')}
                </S.TableCell>
                <S.TableCell>{item.dealerId}</S.TableCell>
                <S.TableCell>{item.teamMember}</S.TableCell>
                <S.TableCell>{formatter.format(item.orderTotal)}</S.TableCell>
                <S.TableCell>{formatter.format(item.paidTotal)}</S.TableCell>
                <S.TableCell>
                  {OrderHelper.getPaymentStatus(item.paymentStatus)}
                </S.TableCell>
              </S.TableRow>
            ))}
          <S.TableRow>
            <S.TableCell colSpan={6} />
            <S.TableCell>Unpaid</S.TableCell>
            <S.TableCell>{formatter.format(totalUnpaid)}</S.TableCell>
          </S.TableRow>
          <S.TableRow>
            <S.TableCell colSpan={6} />
            <S.TableCell>Partial unpaid</S.TableCell>
            <S.TableCell>{formatter.format(totalPartialUnpaid)}</S.TableCell>
          </S.TableRow>
          <S.TableRow>
            <S.TableCell colSpan={6} />
            <S.TableCell>Partial paid</S.TableCell>
            <S.TableCell>{formatter.format(totalPartialPaid)}</S.TableCell>
          </S.TableRow>
          <S.TableRow>
            <S.TableCell colSpan={6} />
            <S.TableCell>Paid</S.TableCell>
            <S.TableCell>{formatter.format(totalPaid)}</S.TableCell>
          </S.TableRow>
        </S.TableBody>
      </S.Table>
    </S.TableContainer>
  )
}

const getPaymentsData = (payments?: ActivityByDealerOrderPaymentRow[]) => {
  return Array.isArray(payments) && payments.length
    ? payments.map((reportRow) => ({
        Channel: reportRow.channel,
        'Order Number': reportRow.orderNumber,
        Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
        'Dealer Id': reportRow.dealerId,
        'Team Member': reportRow.teamMember,
        Total: reportRow.orderTotal,
        Paid: reportRow.paidTotal,
        Status: OrderHelper.getPaymentStatus(reportRow.paymentStatus),
      }))
    : undefined
}

export type ActivityByDealerProps = {
  channel: string
  dealer: string
  startDate: Date
  endDate: Date
}

const ActivityByDealer: React.FC<ActivityByDealerProps> = ({
  channel,
  dealer,
  startDate,
  endDate,
}) => {
  const [report, setReport] = useState<ActivityByDealerReport | null>(null)

  useEffect(() => {
    http
      .get<ActivityByDealerReport>({
        url: `/reports/activityByDealer?channel=${channel}&dealer=${dealer}&teamMember=&dateFrom=${encodeURIComponent(
          moment(startDate).startOf('day').toISOString(true)
        )}&dateTo=${encodeURIComponent(
          moment(endDate).endOf('day').toISOString(true)
        )}&unpaid=true`,
      })
      .then(({ data }) => setReport(data))
  }, [channel, dealer, endDate, startDate])
  const exportExcel = useCallback(async () => {
    if (report !== null) {
      const reportDataEvents = report.events.events.map((reportRow) => {
        return {
          Name: reportRow.name,
          Type: reportRow.type,
          'Media Id': reportRow.mediaId,
          Channel: reportRow.channel,
          'Order Number': reportRow.orderNumber,
          Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
          'Dealer Id': reportRow.dealerId,
          'Team Member': reportRow.teamMember,
          'Co-op': reportRow.coop,
          Spend: reportRow.spend,
          Total: reportRow.total,
        }
      })
      const reportDataEventsPayments = getPaymentsData(report.events.payments)
      const reportDataProducts = report.products.products.map((reportRow) => {
        return {
          Name: reportRow.name,
          Type: reportRow.type,
          'Media Id': reportRow.mediaId,
          Channel: reportRow.channel,
          'Order Number': reportRow.orderNumber,
          Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
          'Dealer Id': reportRow.dealerId,
          'Team Member': reportRow.teamMember,
          'Co-op': reportRow.coop,
          Spend: reportRow.spend,
          Total: reportRow.total,
        }
      })
      const reportDataProductsPayments = getPaymentsData(
        report.products.payments
      )
      const reportDataMedia = report.media.products.map((reportRow) => {
        return {
          Name: reportRow.name,
          Type: reportRow.type,
          'Media Id': reportRow.mediaId,
          Channel: reportRow.channel,
          'Order Number': reportRow.orderNumber,
          Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
          'Dealer Id': reportRow.dealerId,
          'Team Member': reportRow.teamMember,
          'Co-op': reportRow.coop,
          Spend: reportRow.spend,
          Total: reportRow.total,
        }
      })
      const reportDataMediaPayments = getPaymentsData(report.media.payments)
      const reportDataMicrosites = report.microsites.products.map(
        (reportRow) => {
          return {
            Name: reportRow.name,
            Type: reportRow.type,
            'Media Id': reportRow.mediaId,
            Channel: reportRow.channel,
            'Order Number': reportRow.orderNumber,
            Date: format(new Date(reportRow.date), 'MM/dd/yyyy'),
            'Dealer Id': reportRow.dealerId,
            'Team Member': reportRow.teamMember,
            'Co-op': reportRow.coop,
            Spend: reportRow.spend,
            Total: reportRow.total,
          }
        }
      )
      const reportDataMicrositesPayments = getPaymentsData(
        report.microsites.payments
      )
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
      const fileExtension = '.xlsx'
      const eventsWs = XLSX.utils.json_to_sheet(reportDataEvents)
      const eventsPaymentsWs = reportDataEventsPayments
        ? XLSX.utils.json_to_sheet(reportDataEventsPayments)
        : undefined
      const productsWs = XLSX.utils.json_to_sheet(reportDataProducts)
      const productsPaymentsWs = reportDataProductsPayments
        ? XLSX.utils.json_to_sheet(reportDataProductsPayments)
        : undefined
      const mediaWs = XLSX.utils.json_to_sheet(reportDataMedia)
      const mediaPaymentsWs = reportDataMediaPayments
        ? XLSX.utils.json_to_sheet(reportDataMediaPayments)
        : undefined
      const micrositesWs = XLSX.utils.json_to_sheet(reportDataMicrosites)
      const micrositesPaymentsWs = reportDataMicrositesPayments
        ? XLSX.utils.json_to_sheet(reportDataMicrositesPayments)
        : undefined
      const wb = {
        Sheets: {
          Events: eventsWs,
          ...(eventsPaymentsWs && { 'Events payments': eventsPaymentsWs }),
          'Ad Materials': productsWs,
          ...(productsPaymentsWs && {
            'Ad Materials payments': productsPaymentsWs,
          }),
          Media: mediaWs,
          ...(mediaPaymentsWs && { 'Media payments': mediaPaymentsWs }),
          Microsites: micrositesWs,
          ...(micrositesPaymentsWs && {
            'Microsites payments': micrositesPaymentsWs,
          }),
        },
        SheetNames: [
          'Events',
          ...(eventsPaymentsWs ? ['Events payments'] : []),
          'Ad Materials',
          ...(productsPaymentsWs ? ['Ad Materials payments'] : []),
          'Media',
          ...(mediaPaymentsWs ? ['Media payments'] : []),
          'Microsites',
          ...(micrositesPaymentsWs ? ['Microsites payments'] : []),
        ],
      }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, `ActivityByDealer${fileExtension}`)
    }
  }, [report])

  if (!report || report === null) {
    return <PageSpinner />
  }

  return (
    <S.Container>
      <S.ReportTitle>
        Activity by Dealer
        <S.ExcelButton
          label="Download Excel"
          colorOption="blue"
          onClick={exportExcel}
        />
      </S.ReportTitle>
      <S.FilterList>
        <S.FilterListItem>
          <S.FilterListItemTitle>Channel</S.FilterListItemTitle>
          <S.FilterListItemValue>{channel.toUpperCase()}</S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Dealer Name</S.FilterListItemTitle>
          <S.FilterListItemValue>{dealer}</S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
        <S.FilterListItem>
          <S.FilterListItemTitle>Time Period</S.FilterListItemTitle>
          <S.FilterListItemValue>
            {`${format(startDate, 'MM/dd/yyyy')} - ${format(
              endDate,
              'MM/dd/yyyy'
            )}`}
          </S.FilterListItemValue>
          <S.StyledIcon icon="FourPlusRectangle" />
        </S.FilterListItem>
      </S.FilterList>
      <S.ReportTitle>Summary</S.ReportTitle>
      <S.SummaryList>
        <S.SummaryListItem>
          <S.SummaryListItemTitle>Events</S.SummaryListItemTitle>
          <S.SummaryListItemDivider />
          <S.SummaryListItemText>{`Total Sales: ${formatter.format(
            report.events.totalSales
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Paid: ${formatter.format(
            report.events.totalPaid
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Events: ${report.events.totalQuantity}`}</S.SummaryListItemText>
        </S.SummaryListItem>
        <S.SummaryListItem>
          <S.SummaryListItemTitle>Ad Materials</S.SummaryListItemTitle>
          <S.SummaryListItemDivider />
          <S.SummaryListItemText>{`Total Sales: ${formatter.format(
            report.products.totalSales
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Paid: ${formatter.format(
            report.products.totalPaid
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Ad Materials: ${report.products.totalQuantity}`}</S.SummaryListItemText>
        </S.SummaryListItem>
        <S.SummaryListItem>
          <S.SummaryListItemTitle>Media</S.SummaryListItemTitle>
          <S.SummaryListItemDivider />
          <S.SummaryListItemText>{`Total Sales: ${formatter.format(
            report.media.totalSales
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Paid: ${formatter.format(
            report.media.totalPaid
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Media: ${report.media.totalQuantity}`}</S.SummaryListItemText>
        </S.SummaryListItem>
        <S.SummaryListItem>
          <S.SummaryListItemTitle>Microsites</S.SummaryListItemTitle>
          <S.SummaryListItemDivider />
          <S.SummaryListItemText>{`Total Sales: ${formatter.format(
            report.microsites.totalSales
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Paid: ${formatter.format(
            report.microsites.totalPaid
          )}`}</S.SummaryListItemText>
          <S.SummaryListItemText>{`Total Microsites: ${report.microsites.totalQuantity}`}</S.SummaryListItemText>
        </S.SummaryListItem>
      </S.SummaryList>
      {report.events.events.length > 0 && (
        <>
          <S.ReportTitle>Events</S.ReportTitle>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Event Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Media ID</S.TableHeadCell>
                  <S.TableHeadCell>Channel</S.TableHeadCell>
                  <S.TableHeadCell>Order #</S.TableHeadCell>
                  <S.TableHeadCell>Date</S.TableHeadCell>
                  <S.TableHeadCell>Dealer ID</S.TableHeadCell>
                  <S.TableHeadCell>Team Member</S.TableHeadCell>
                  <S.TableHeadCell>Co-Op</S.TableHeadCell>
                  <S.TableHeadCell>Spend</S.TableHeadCell>
                  <S.TableHeadCell>Total</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.events.events.map(
                  (item: ActivityByDealerRow, index: number) => (
                    <S.TableRow key={`events${index}`}>
                      <S.TableCell>{item.name}</S.TableCell>
                      <S.TableCell>{item.type}</S.TableCell>
                      <S.TableCell>{item.mediaId}</S.TableCell>
                      <S.TableCell>{item.channel}</S.TableCell>
                      <S.TableCell>{item.orderNumber}</S.TableCell>
                      <S.TableCell>
                        {format(new Date(item.date), 'MM/dd/yyyy')}
                      </S.TableCell>
                      <S.TableCell>{item.dealerId}</S.TableCell>
                      <S.TableCell>{item.teamMember}</S.TableCell>
                      <S.TableCell>{formatter.format(item.coop)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.spend)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.total)}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
              <S.TableFooter>
                <S.TableRow>
                  <S.TableFootCell>Totals</S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.events.totalCoop)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.events.totalSpend)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.events.totalSales)}
                  </S.TableFootCell>
                </S.TableRow>
              </S.TableFooter>
            </S.Table>
          </S.TableContainer>
          {getPaymentsTable(report.events.payments)}
        </>
      )}
      {report.products.products.length > 0 && (
        <>
          <S.ReportTitle>Ad Materials</S.ReportTitle>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Product Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Media ID</S.TableHeadCell>
                  <S.TableHeadCell>Channel</S.TableHeadCell>
                  <S.TableHeadCell>Order #</S.TableHeadCell>
                  <S.TableHeadCell>Date</S.TableHeadCell>
                  <S.TableHeadCell>Dealer ID</S.TableHeadCell>
                  <S.TableHeadCell>Team Member</S.TableHeadCell>
                  <S.TableHeadCell>Co-Op</S.TableHeadCell>
                  <S.TableHeadCell>Spend</S.TableHeadCell>
                  <S.TableHeadCell>Total</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.products.products.map(
                  (item: ActivityByDealerRow, index: number) => (
                    <S.TableRow key={`products${index}`}>
                      <S.TableCell>{item.name}</S.TableCell>
                      <S.TableCell>{item.type}</S.TableCell>
                      <S.TableCell>{item.mediaId}</S.TableCell>
                      <S.TableCell>{item.channel}</S.TableCell>
                      <S.TableCell>{item.orderNumber}</S.TableCell>
                      <S.TableCell>
                        {format(new Date(item.date), 'MM/dd/yyyy')}
                      </S.TableCell>
                      <S.TableCell>{item.dealerId}</S.TableCell>
                      <S.TableCell>{item.teamMember}</S.TableCell>
                      <S.TableCell>{formatter.format(item.coop)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.spend)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.total)}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
              <S.TableFooter>
                <S.TableRow>
                  <S.TableFootCell>Totals</S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.products.totalCoop)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.products.totalSpend)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.products.totalSales)}
                  </S.TableFootCell>
                </S.TableRow>
              </S.TableFooter>
            </S.Table>
          </S.TableContainer>
          {getPaymentsTable(report.products.payments)}
        </>
      )}
      {report.media.products.length > 0 && (
        <>
          <S.ReportTitle>Media</S.ReportTitle>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Product Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Media ID</S.TableHeadCell>
                  <S.TableHeadCell>Channel</S.TableHeadCell>
                  <S.TableHeadCell>Order #</S.TableHeadCell>
                  <S.TableHeadCell>Date</S.TableHeadCell>
                  <S.TableHeadCell>Dealer ID</S.TableHeadCell>
                  <S.TableHeadCell>Team Member</S.TableHeadCell>
                  <S.TableHeadCell>Co-Op</S.TableHeadCell>
                  <S.TableHeadCell>Spend</S.TableHeadCell>
                  <S.TableHeadCell>Total</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.media.products.map(
                  (item: ActivityByDealerRow, index: number) => (
                    <S.TableRow key={`media${index}`}>
                      <S.TableCell>{item.name}</S.TableCell>
                      <S.TableCell>{item.type}</S.TableCell>
                      <S.TableCell>{item.mediaId}</S.TableCell>
                      <S.TableCell>{item.channel}</S.TableCell>
                      <S.TableCell>{item.orderNumber}</S.TableCell>
                      <S.TableCell>
                        {format(new Date(item.date), 'MM/dd/yyyy')}
                      </S.TableCell>
                      <S.TableCell>{item.dealerId}</S.TableCell>
                      <S.TableCell>{item.teamMember}</S.TableCell>
                      <S.TableCell>{formatter.format(item.coop)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.spend)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.total)}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
              <S.TableFooter>
                <S.TableRow>
                  <S.TableFootCell>Totals</S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.media.totalCoop)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.media.totalSpend)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.media.totalSales)}
                  </S.TableFootCell>
                </S.TableRow>
              </S.TableFooter>
            </S.Table>
          </S.TableContainer>
          {getPaymentsTable(report.media.payments)}
        </>
      )}
      {report.microsites.products.length > 0 && (
        <>
          <S.ReportTitle>Microsites</S.ReportTitle>
          <S.TableContainer>
            <S.Table>
              <S.TableHead>
                <S.TableRow>
                  <S.TableHeadCell>Event Name</S.TableHeadCell>
                  <S.TableHeadCell>Type</S.TableHeadCell>
                  <S.TableHeadCell>Media ID</S.TableHeadCell>
                  <S.TableHeadCell>Channel</S.TableHeadCell>
                  <S.TableHeadCell>Order #</S.TableHeadCell>
                  <S.TableHeadCell>Date</S.TableHeadCell>
                  <S.TableHeadCell>Dealer ID</S.TableHeadCell>
                  <S.TableHeadCell>Team Member</S.TableHeadCell>
                  <S.TableHeadCell>Co-Op</S.TableHeadCell>
                  <S.TableHeadCell>Spend</S.TableHeadCell>
                  <S.TableHeadCell>Total</S.TableHeadCell>
                </S.TableRow>
              </S.TableHead>
              <S.TableBody>
                {report.microsites.products.map(
                  (item: ActivityByDealerRow, index: number) => (
                    <S.TableRow key={`microsites${index}`}>
                      <S.TableCell>{item.name}</S.TableCell>
                      <S.TableCell>{item.type}</S.TableCell>
                      <S.TableCell>{item.mediaId}</S.TableCell>
                      <S.TableCell>{item.channel}</S.TableCell>
                      <S.TableCell>{item.orderNumber}</S.TableCell>
                      <S.TableCell>
                        {format(new Date(item.date), 'MM/dd/yyyy')}
                      </S.TableCell>
                      <S.TableCell>{item.dealerId}</S.TableCell>
                      <S.TableCell>{item.teamMember}</S.TableCell>
                      <S.TableCell>{formatter.format(item.coop)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.spend)}</S.TableCell>
                      <S.TableCell>{formatter.format(item.total)}</S.TableCell>
                    </S.TableRow>
                  )
                )}
              </S.TableBody>
              <S.TableFooter>
                <S.TableRow>
                  <S.TableFootCell>Totals</S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell></S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.microsites.totalCoop)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.microsites.totalSpend)}
                  </S.TableFootCell>
                  <S.TableFootCell>
                    {formatter.format(report.microsites.totalSales)}
                  </S.TableFootCell>
                </S.TableRow>
              </S.TableFooter>
            </S.Table>
          </S.TableContainer>
          {getPaymentsTable(report.microsites.payments)}
        </>
      )}
    </S.Container>
  )
}

export default ActivityByDealer
