"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPopUpStatusOptionDescription = exports.getPopUpStatusOptions = void 0;
var interfaces_1 = require("@monorepo/interfaces");
var getPopUpStatusOptions = function () { return [
    { value: interfaces_1.PopUpStatus.ACTIVE.toString(), label: 'Active' },
    { value: interfaces_1.PopUpStatus.DRAFT.toString(), label: 'Draft' },
    { value: interfaces_1.PopUpStatus.ARCHIVED.toString(), label: 'Archived' },
]; };
exports.getPopUpStatusOptions = getPopUpStatusOptions;
var getPopUpStatusOptionDescription = function (status) {
    if (!status) {
        return 'Unknown';
    }
    var option = (0, exports.getPopUpStatusOptions)().find(function (item) { return item.value === status.toString(); });
    return option === null || option === void 0 ? void 0 : option.label;
};
exports.getPopUpStatusOptionDescription = getPopUpStatusOptionDescription;
