"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const S = (0, tslib_1.__importStar)(require("./styles"));
const TextArea = (_a) => {
    var { onChange } = _a, props = (0, tslib_1.__rest)(_a, ["onChange"]);
    const handleChange = (e) => {
        onChange && onChange(e.target.value);
    };
    return ((0, jsx_runtime_1.jsx)(S.StyledTextArea, Object.assign({ "data-testid": "textarea", onChange: handleChange }, props), void 0));
};
exports.default = TextArea;
