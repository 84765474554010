import * as S from './styles'
import React from 'react'

type SectionPopularProps = {
  featuredTitle?: string|undefined
  featuredContent?: string|undefined
}
const SectionFeaturedTitleAndText: React.FC<SectionPopularProps> = ({
  featuredTitle,
  featuredContent,
}) => {
  return (
    <S.Section>
        <S.FeaturedTitleContainer>
          <S.Title>{featuredTitle ?? 'We’re here to help'}</S.Title>
          <S.Content>
            {featuredContent ?? 'Good news!  We’ve got answers. Our dedicated team is here to guide you every step of the way.'}
          </S.Content>
        </S.FeaturedTitleContainer>
    </S.Section>
  )
}

export default SectionFeaturedTitleAndText
