import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as S from './styles'

import { Product, ProductVariant } from '@monorepo/interfaces'
import { ProductDetail, SectionPopular } from '../..'
import React, { useEffect, useState } from 'react'

import Slider from 'react-slick'

type DetailsProps = {
  product?: Product
  isMedia?: boolean
}

const Details: React.FC<DetailsProps> = ({ product, isMedia }) => {
  const [variant, setVariant] = useState<ProductVariant | undefined>(undefined)

  const [carouselMedia, setCarouselMedia] = useState<string[]>(
    variant?.media || []
  )

  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  useEffect(() => {
    if (!!product && !Array.isArray(product)) {
      setVariant(product?.variants[0])
    }
  }, [product])

  const onChangeVariant = (index: number) => {
    setVariant(product?.variants[index])
  }
  useEffect(() => {
    if (Array.isArray(variant?.media)) {
      setCarouselMedia(variant?.media as string[])
    }
  }, [variant?.media])

  return (
    <S.Wrap>
      {variant && (
        <S.SectionProduct>
          <S.SectionPorductItem>
            <Slider {...sliderSettings}>
              {carouselMedia.map((media, index) => (
                <S.CaroselImage key={`image-${index}`}>
                  <img src={media} alt={`${index}`} />
                </S.CaroselImage>
              ))}
            </Slider>
          </S.SectionPorductItem>
          <S.SectionPorductItem>
            <ProductDetail
              product={product}
              variant={variant}
              isMedia={isMedia}
              onChangeVariant={onChangeVariant}
            />
          </S.SectionPorductItem>
        </S.SectionProduct>
      )}
      {isMedia && <S.MediaHightligh />}
      <SectionPopular detailsPage featuredTitle="What's New" />
    </S.Wrap>
  )
}

export default Details
