/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { appInsights } from '@monorepo/infra'
import { browserHistory } from './routes'

const app = appInsights({
  history: browserHistory,
  instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY!,
})

app.loadAppInsights()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
