import React from 'react'
import {Hero as HeroCreative, 
  HeroCreative2, 
  CarouselV} from '../../organisms'
import * as S from './styles'
import Hero from '../../../assets/microsites/hero.png'
import HeroMobile from '../../../assets/microsites/hero-mobile.png'
import HeaderIsp from '../../../assets/microsites/header-isp.jpg'
import HeaderMdu from '../../../assets/microsites/header-mdu.jpg'
import CarouselIspMdu01 from '../../../assets/microsites/carousel-isp-mdu-01.png'
import CarouselIspMdu02 from '../../../assets/microsites/carousel-isp-mdu-02.png'
import CarouselIspMdu03 from '../../../assets/microsites/carousel-isp-mdu-03.png'
import CarouselIspMdu04 from '../../../assets/microsites/carousel-isp-mdu-04.png'
import CarouselIspMdu05 from '../../../assets/microsites/carousel-isp-mdu-05.png'
import { useChannel } from '@monorepo/infra'
import { DealerChannel } from '@monorepo/interfaces'

const Microsites: React.FC = () => {
  const { selectedChannel } = useChannel()
  const ispMduCarouselTitle = 'Microsite program benefits'
  const buttonGetStartedUrl = '/all-products/details/6245fb8a8c2e8e54efdd463b'
  const ispMduCarouselItems = [
    {
      description:
        'Increase your online presence with a branded microsite that includes your business name & logo, dealer badge, phone number, optional address, services provided.',
      imageUrl: CarouselIspMdu01,
      buttonGetStartedUrl,
    },
    {
      description: 'Perfect home base for digital advertising efforts',
      imageUrl: CarouselIspMdu02,
      buttonGetStartedUrl,
    },
    {
      description:
        'Never worry about out-of-date offers or branding. Content will be automatically updated to reflect current promotions and creative!',
      imageUrl: CarouselIspMdu03,
      buttonGetStartedUrl,
    },
    {
      description:
        'Optimized for lead generation including a floating click-to-call telephone number and contact form for lead capture.',
      imageUrl: CarouselIspMdu04,
      buttonGetStartedUrl,
    },
    {
      description: 'Integrate with your online buy flow',
      imageUrl: CarouselIspMdu05,
      buttonGetStartedUrl,
    },
  ]

  return (
    <>
      {selectedChannel?.toLowerCase() === DealerChannel.MDU ||
      selectedChannel?.toLowerCase() === DealerChannel.ISP ||
      selectedChannel?.toLowerCase() === DealerChannel.LSP ||
      selectedChannel?.toLowerCase() === DealerChannel.D2D ||
      selectedChannel?.toLowerCase() === DealerChannel.EM ? (
        <main>
          <HeroCreative2
            background={
              selectedChannel?.toLowerCase() === DealerChannel.ISP ||
              selectedChannel?.toLowerCase() === DealerChannel.LSP ||
              selectedChannel?.toLowerCase() === DealerChannel.D2D ||
              selectedChannel?.toLowerCase() === DealerChannel.EM
                ? HeaderIsp
                : HeaderMdu
            }
            upTitle="BRANDED MICROSITE PROGRAM"
            title="LET'S GET DIGITAL"
            description="The dealer microsite program provides your business with online presence. Let us manage your site end-to-end, while you manage your business and leads."
            buttonGetStartedUrl={buttonGetStartedUrl}
          />
          <CarouselV title={ispMduCarouselTitle} items={ispMduCarouselItems} />
        </main>
      ) : (
        <main>
          <HeroCreative
            background={{ desk: Hero, mobile: HeroMobile }}
            title="LET'S GET DIGITAL"
            description="Increase your online presence with a co-branded microsite that includes up-to-date DIRECTV, DIRECTV STREAM, Internet and Wireless offers."
          />
          <S.HighlightWrap>
            <S.SalesHighlight>
              <S.HighlighText>
                You're always-up-to-date, online home base
              </S.HighlighText>
              <S.ButtonLink
                to={'/all-products/details/6245fb8a8c2e8e54efdd463b'}
              >
                Learn More
              </S.ButtonLink>
            </S.SalesHighlight>
            <S.HighlightImage />
          </S.HighlightWrap>
          <S.OnlinePresenceWrap>
            <h2>Turn-key Online Presence</h2>
            <p>
              The dealer microsite program provides your business with the
              online presence you need without any of the hassle of running your
              own site. Always up-to-date on pricing, offers, promotions, and
              legal - DIRECTV co-branded microsites make it easy to establish
              your business in the digital space.
            </p>
            <S.ButtonLink to={'/all-products/details/6245fb8a8c2e8e54efdd463b'}>
              Learn More
            </S.ButtonLink>
          </S.OnlinePresenceWrap>
        </main>
      )}
    </>
  )
}

export default Microsites
