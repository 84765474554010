"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Counter = exports.Container = void 0;
const tslib_1 = require("tslib");
const styled_components_1 = (0, tslib_1.__importStar)(require("styled-components"));
const theme_1 = require("@monorepo/theme");
exports.Container = styled_components_1.default.figure `
  display: flex;
  align-items: center;
  & svg {
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  }

  & svg > path,
  circle,
  line {
    ${({ color, hasFill, hasStroke }) => {
    if (color) {
        return (0, styled_components_1.css) `
          stroke: ${hasStroke ? color : ''};
          fill: ${hasFill ? color : ''};
        `;
    }
}}
  }
`;
exports.Counter = styled_components_1.default.span `
  margin-top: -20px;
  background-color: ${theme_1.colors.blue};
  border-radius: 50%;
  padding: 2px;
  ${({ color }) => {
    if (color) {
        return (0, styled_components_1.css) `
        color: ${color};
      `;
    }
}}
`;
