"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.populateTickets = exports.populateTicket = exports.getTicketStatusName = exports.getTicketPriorityName = exports.getTicketCategoryName = exports.getTicketPreferredContactMethodOptions = exports.getTicketCategoryEventOptions = exports.getTicketCategoryProductOptions = exports.getTicketStatusOptions = exports.getTicketPriorityOptions = void 0;
var tslib_1 = require("tslib");
var interfaces_1 = require("@monorepo/interfaces");
var _1 = require(".");
//prettier-ignore
var getTicketPriorityOptions = function () { return [
    { value: interfaces_1.TicketPriority.LOW.toString(), label: 'Low' },
    { value: interfaces_1.TicketPriority.MEDIUM.toString(), label: 'Medium' },
    { value: interfaces_1.TicketPriority.HIGH.toString(), label: 'High' },
]; };
exports.getTicketPriorityOptions = getTicketPriorityOptions;
//prettier-ignore
var getTicketStatusOptions = function () { return [
    { value: interfaces_1.TicketStatus.NEW.toString(), label: 'New' },
    { value: interfaces_1.TicketStatus.ESCALATION.toString(), label: 'Escalation' },
    { value: interfaces_1.TicketStatus.APPROVAL_NEEDED.toString(), label: 'Approval needed' },
    { value: interfaces_1.TicketStatus.APPROVAL_COMPLETE.toString(), label: 'Approval complete' },
    { value: interfaces_1.TicketStatus.AWAITING_DEALER.toString(), label: 'Awaiting dealer' },
    { value: interfaces_1.TicketStatus.AWAITING_VENDOR.toString(), label: 'Awaiting vendor' },
    { value: interfaces_1.TicketStatus.AWAITING_ZONER.toString(), label: 'Awaiting zoner' },
    { value: interfaces_1.TicketStatus.DELETED.toString(), label: 'Deleted' },
    { value: interfaces_1.TicketStatus.CLOSED.toString(), label: 'Closed' },
]; };
exports.getTicketStatusOptions = getTicketStatusOptions;
//prettier-ignore
var getTicketCategoryProductOptions = function () { return [
    { value: interfaces_1.TicketCategoryProduct.HELP_WITH_MICROSITE.toString(), label: 'Help with microsite' },
    { value: interfaces_1.TicketCategoryProduct.HELP_WITH_DOWNLOAD.toString(), label: 'Help with download' },
    { value: interfaces_1.TicketCategoryProduct.HELP_WITH_MEDIA.toString(), label: 'Help with media' },
    { value: interfaces_1.TicketCategoryProduct.HELP_WITH_PRINT_AND_SHIP.toString(), label: 'Help with print and ship' },
    { value: interfaces_1.TicketCategoryProduct.OTHER.toString(), label: 'Other' },
]; };
exports.getTicketCategoryProductOptions = getTicketCategoryProductOptions;
//prettier-ignore
var getTicketCategoryEventOptions = function () { return [
    { value: interfaces_1.TicketCategoryEvent.HELP_WITH_EVENT_ORDER.toString(), label: 'Help with event order' },
    { value: interfaces_1.TicketCategoryEvent.BUILD_MY_OWN_EVENT.toString(), label: 'Build my own event' },
]; };
exports.getTicketCategoryEventOptions = getTicketCategoryEventOptions;
//prettier-ignore
var getTicketPreferredContactMethodOptions = function () { return [
    { value: 'message', label: 'ZONE Messaging' },
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone (please specify below)' },
]; };
exports.getTicketPreferredContactMethodOptions = getTicketPreferredContactMethodOptions;
var getTicketCategoryName = function (vendor, category) {
    var _a;
    return (_a = (vendor === interfaces_1.Vendor.PURE
        ? exports.getTicketCategoryProductOptions
        : exports.getTicketCategoryEventOptions)().find(function (item) { return item.value === (category === null || category === void 0 ? void 0 : category.toString()); })) === null || _a === void 0 ? void 0 : _a.label;
};
exports.getTicketCategoryName = getTicketCategoryName;
var getTicketPriorityName = function (priority) {
    var _a;
    return (_a = (0, exports.getTicketPriorityOptions)().find(function (item) { return item.value === (priority === null || priority === void 0 ? void 0 : priority.toString()); })) === null || _a === void 0 ? void 0 : _a.label;
};
exports.getTicketPriorityName = getTicketPriorityName;
var getTicketStatusName = function (status) {
    var _a;
    return (_a = (0, exports.getTicketStatusOptions)().find(function (item) { return item.value === (status === null || status === void 0 ? void 0 : status.toString()); })) === null || _a === void 0 ? void 0 : _a.label;
};
exports.getTicketStatusName = getTicketStatusName;
//prettier-ignore
var populateTicket = function (ticket) {
    return ticket
        ? (0, tslib_1.__assign)((0, tslib_1.__assign)((0, tslib_1.__assign)((0, tslib_1.__assign)((0, tslib_1.__assign)((0, tslib_1.__assign)((0, tslib_1.__assign)((0, tslib_1.__assign)({}, ticket), (ticket.vendor && { vendor: _1.VendorHelper.getVendorName(ticket.vendor) })), (ticket.category && { category: (0, exports.getTicketCategoryName)(ticket.vendor, ticket.category) })), (!!ticket.channel && { channel: ticket.channel.toUpperCase() })), (ticket.priority && { priority: (0, exports.getTicketPriorityName)(ticket.priority) })), (ticket.status && { status: (0, exports.getTicketStatusName)(ticket.status) })), (!!ticket.createdOn && { createdOn: new Date(ticket.createdOn) })), (!!ticket.updatedOn && { updatedOn: new Date(ticket.updatedOn) })) : ticket;
};
exports.populateTicket = populateTicket;
var populateTickets = function (tickets) {
    return Array.isArray(tickets)
        ? tickets.map(function (item) { return (0, exports.populateTicket)(item); })
        : tickets;
};
exports.populateTickets = populateTickets;
