import { colors } from '@monorepo/theme'
import styled from 'styled-components'
import { Drawer as MuiDrawer } from '@material-ui/core'

const SectionEvent = styled.section`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`

const SectionEventImage = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 50%;
  padding: 40px;
  background: #f4f4f4;

  & > img {
    max-width: 100%;
  }
`

const SectionEventItem = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 50%;
  padding: 0 40px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const EventTitle = styled.h1`
  font-family: 'PFDINText';
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
`

const EventPrice = styled.h2`
  font-family: 'Jubilat';
  font-size: 36px;
  font-weight: 700;
  line-height: 32px;
  font-style: italic;
  margin-bottom: 20px;
`

const EventPriceDescription = styled.h3`
  font-family: 'PFDINText';
  font-size: 20px;
  font-weight: 400;
  color: ${colors.grey};
`

const EventParagraph = styled.p`
  font-family: 'PFDINText':;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.2;
  margin: 20px 0 0;
  text-align: left;

  > strong {
    font-weight: 700
  }
`

const ButtonWrapper = styled.div`
  margin-top: 60px;
`

const CartDrawer = styled(MuiDrawer)`
  & .MuiDrawer-paper {
    width: 40%;
    overflow-x: hidden;
  }
`

export {
  SectionEvent,
  SectionEventItem,
  SectionEventImage,
  EventTitle,
  EventPrice,
  EventParagraph,
  EventPriceDescription,
  ButtonWrapper,
  CartDrawer,
}
