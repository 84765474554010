import * as S from './styles'

import { Product, ProductVariant } from '@monorepo/interfaces'

import { CardProduct } from '@monorepo/components'
// import { format } from 'date-fns'
import React from 'react'

interface ProductListProps {
  products: Product[]
}

function retrieveSize(variants: ProductVariant[]) {
  const length = variants.map((variant) => {
    return variant.size
  }).length

  return length > 1 ? `Available in ${length} sizes` : ''
}

const ProductList: React.FC<ProductListProps> = ({ products }) => {

  if (!products.length) return <S.NoData>No data</S.NoData>
  return (
    <S.ProductList>
      {products.map((prod: Product, index: number) => (
        <CardProduct
          key={index}
          images={prod.variants[0].media}
          link={prod._id ? prod._id : ``}
          title={prod.title}
          isNew={false}
          isFeatured={/featured/i.test(prod.tags.join())}
          // subtitle={getDate(prod.expirationDate ?? null)}
          aditionalInfo={retrieveSize(prod.variants)}
        />
      ))}
    </S.ProductList>
  )
}

export default ProductList
