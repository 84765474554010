"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryMethod = void 0;
var DeliveryMethod;
(function (DeliveryMethod) {
    DeliveryMethod[DeliveryMethod["PRINT"] = 1] = "PRINT";
    DeliveryMethod[DeliveryMethod["DOWNLOAD"] = 2] = "DOWNLOAD";
    DeliveryMethod[DeliveryMethod["CUSTOM_QUOTE"] = 3] = "CUSTOM_QUOTE";
    DeliveryMethod[DeliveryMethod["PLACE_WITH_AGENCY"] = 4] = "PLACE_WITH_AGENCY";
})(DeliveryMethod = exports.DeliveryMethod || (exports.DeliveryMethod = {}));
