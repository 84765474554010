import styled from 'styled-components'
import { TableContainer as MuiTableContainer } from '@material-ui/core'
import { colors } from '@monorepo/theme'
import { Button as BaseButton } from '@monorepo/components'

import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableCell as MuiTableCell,
  Button as MuiButton,
  TableRow as MuiTableRow,
} from '@material-ui/core'
import { StylesConfig } from 'react-select'
import Dialog from '@reach/dialog'
import { Form } from 'formik'
import { Tag as TagComponent } from '@monorepo/components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    overflow-y: hidden;
  }
`

export const Table = styled(MuiTable)`
  background-color: ${colors.white};
`

export const TableHead = styled(MuiTableHead)`
  position: relative;

  &::after {
    line-height: 4px;
    content: '.';
    color: transparent;
    background-color: #f3f6f8;
    width: 100%;
    position: absolute;
  }
`

export const TableRow = styled(MuiTableRow)``

export const TableHeadCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    color: ${colors.slateGrey};
    font-family: PFDINText;
    font-size: 12px;
    text-transform: uppercase;
    height: 48px;
    padding: 0 16px;
  }
`

export const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-weight: bold;
    border-bottom: 2px solid gray;
  }
`

export const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    padding: 0 5px 0 5px;
    border-bottom: 2px solid gray;
  }
`

export const Button = styled(MuiButton).attrs({ disableRipple: true })`
  &.MuiButton-root {
    border: 2px solid ${colors.grey};
  }

  .MuiButton-label {
    color: ${colors.grey};
    font-size: 0.9em;
    font-family: 'ATTAleckSansBold';
    text-transform: none;
  }
`

export const TbodyRowLoading = styled(TableRow)`
  height: 50px;
  position: relative;
`

export const TbodyRow = styled(TableRow)`
  border-bottom: 1px solid #cfd4d9;
  height: 78px;

  & > .MuiTableCell-root:first-child {
    width: 54px;
    padding-left: 16px;
  }
`

export const ProductTitle = styled.p`
  font-family: PFDINText;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${colors.black};
  max-width: 285px;
`

export const StatusTag = styled.div`
  border-radius: 20px;
  height: 30px;
  width: 70px;
  font-family: PFDINText;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;

  &:not([data-status]) {
    background-color: ${colors.highlightGrey};
  }

  &[data-status='1'] {
    background-color: ${colors.green};
  }

  &[data-status='2'] {
    background-color: ${colors.orange};
  }

  &[data-status='3'] {
    background-color: ${colors.red};
  }
`

export const TBodyCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-family: PFDINText;
    font-size: 14px;
    padding: 8px;
  }
`

export const TBodyCellLoading = styled(MuiTableCell)`
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: none;

  &.MuiTableCell-root {
    padding: 0;
    display: flex;
  }
`

export const ProductImageContainer = styled.div`
  width: 136px;
  height: 107px;
  background-color: ${colors.delicateGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`

export const ModalButton = styled(BaseButton)`
  width: 100px;
  justify-content: center;
`

type OptionType = {
  label: string
  value: string
}

type IsMulti = true

export const SelectStyle: StylesConfig<OptionType> = {
  container: (base) => ({
    ...base,
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
    minHeight: 75,
    paddingLeft: 20,
    paddingRight: 20,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  multiValueLabel: (base) => ({
    ...base,
    backgroundColor: '#21528C',
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'PFDINText',
    fontSize: 16,
    letterSpacing: -0.2,
    fontWeight: 'normal',
    color: colors.mediumGrey,
  }),
}

export const MultiSelectStyle: StylesConfig<OptionType, IsMulti> = {
  container: SelectStyle.container,
  control: SelectStyle.control,
  placeholder: SelectStyle.placeholder,
  multiValueLabel: (base) => ({
    ...base,
    color: colors.white,
    padding: 0,
    marginRight: 10,
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    padding: 0,
    height: 20,
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: '.2s ease-in-out background-color',
    backgroundColor: state.isFocused ? 'black' : 'transparent',
    '&:hover': {
      backgroundColor: 'black',
      color: colors.white,
      cursor: 'pointer',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#21528C',
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 5,
    borderRadius: 18,
    color: colors.white,
    padding: '0 10px',
  }),
  menu: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
  }),
  menuList: (base, state) => ({
    ...base,
    display: !!state.options.length ? 'block' : 'none',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    display: 'none',
  }),
}

export const DialogActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const TeamMembersDialog = styled(Dialog)`
  background: #f5f5f5;
`

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const DialogTitle = styled.h1`
  font-size: 20px;
`

export const DialogForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const WrapTags = styled.div`
  display: flex;
  gap: 8px;
`

export const Tag = styled(TagComponent)`
  background: #21528c;
  height: 24px;
`

export const Group = styled.p`
  font-size: 15px;
  font-weight: 500;
`
export const Link = styled.p`
  color: #337ace;
  text-decoration: underline;
  cursor: pointer;
`
export const Email = styled.p`
  color: #707d8d;
  font-size: 15px;
`

export const Name = styled.p`
  font-size: 15px;
  font-weight: 500;
`

export const UserProfileBadge = styled.span`
  user-select: none;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  font-family: Jubilat;
  font-size: 22px;
  font-weight: 700;
  line-height: 54px;
  text-align: center;
  background-color: #102641;
  color: ${colors.white};
  display: flex;
  justify-content: center;
`

export const ErrorMessage = styled.p`
  color: ${colors.red};
  font-family: PFDINText;
  font-size: 14px;
`

export const EmptyMessage = styled.p`
  padding: 1rem;
  color: ${colors.black};
  font-family: PFDINText;
`
