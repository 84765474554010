import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
`

const Main = styled.main`
  height: 100%;
`

export { Container, Main }
