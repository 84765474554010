/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'

import {
  Container,
  MainContainer,
  SelectInput,
  TextInputArea,
  Wrapper,
  Title,
  WrapperOptions,
  SubmitButton,
  ButtonWrapper,
} from './styles'
import { Form, Formik } from 'formik'

import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useTranslation } from 'react-i18next'
import { TicketHelper, useChannel, isFunction, KeycloakHelper } from '@monorepo/infra'
import {
  Ticket,
  TicketPriority,
  TicketStatus,
  Vendor,
} from '@monorepo/interfaces'

const separator = '.'
const labelOther = 'Other'

const categoryOptions = [
  ...TicketHelper.getTicketCategoryProductOptions()
    .filter((item) => item.label !== labelOther)
    .map((item) => ({
      value: `${Vendor.PURE.toString()}${separator}${item.value}`,
      label: item.label,
    })),
  ...TicketHelper.getTicketCategoryEventOptions().map((item) => ({
    value: `${Vendor.DSI.toString()}${separator}${item.value}`,
    label: item.label,
  })),
  ...TicketHelper.getTicketCategoryProductOptions()
    .filter((item) => item.label === labelOther)
    .map((item) => ({
      value: `${Vendor.PURE.toString()}${separator}${item.value}`,
      label: item.label,
    })),
]

const contactOptions = TicketHelper.getTicketPreferredContactMethodOptions()

const TicketSchema = Yup.object().shape({
  vendor: Yup.number().required('vendor is required'),
  category: Yup.number().required('category is required'),
  message: Yup.string().required('message is required'),
})

interface SectionContactSupportProps {
  onSubmit: (ticket: Ticket) => void
}

const SectionContactSupport: React.FC<SectionContactSupportProps> = ({
  onSubmit,
}) => {
  const { selectedMasterDealerId, selectedChannel } = useChannel()
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)

  const { t } = useTranslation('contactSupport')

  return (
    <>
      <MainContainer>
        <Container>
          <Wrapper>
            <Formik
              initialValues={{
                vendor: undefined,
                category: undefined,
                contact: '',
                message: '',
              }}
              validationSchema={TicketSchema}
              onSubmit={(values, actions) => {
                if (!values.vendor || !values.category) {
                  return
                }

                const currentDateTime = new Date().toISOString()
                const categoryOptionName = TicketHelper.getTicketCategoryName(
                  values.vendor,
                  values.category
                )

                if (isFunction(onSubmit)) {
                  onSubmit({
                    number: 0,
                    priority: TicketPriority.LOW,
                    status: TicketStatus.NEW,
                    masterDealerId: selectedMasterDealerId,
                    channel: selectedChannel.toLowerCase(),
                    vendor: +values.vendor,
                    category: +values.category,
                    subject: `Contact: ${values.contact}${
                      !!categoryOptionName ? ` - ${categoryOptionName}` : ''
                    }`,
                    messages: [
                      {
                        message: values.message,
                        createdBy: user?.email,
                        createdOn: currentDateTime,
                      },
                    ],
                    tags: [],
                    dealer: user?.email,
                    createdBy: user?.email,
                    createdOn: currentDateTime,
                  })
                }

                actions.setSubmitting(false)
                actions.resetForm()
              }}
            >
              {({ touched, errors, setFieldValue }) => (
                <Form>
                  <Title as="h1"> {t('contact_label')}</Title>
                  <WrapperOptions>
                    <SelectInput
                      id="category"
                      name="category"
                      options={categoryOptions}
                      placeholder="Category"
                      onChange={(newValue) => {
                        const values = (
                          newValue as { value: string; label: string }
                        ).value.split(separator)

                        setFieldValue('vendor', +values[0])
                        setFieldValue('category', +values[1])
                      }}
                    />
                    <SelectInput
                      id="contact"
                      name="contact"
                      options={contactOptions}
                      placeholder="Contact Preferences"
                      onChange={(newValue) => {
                        setFieldValue('contact', (newValue as any).label)
                      }}
                    />
                  </WrapperOptions>
                  <TextInputArea
                    name="message"
                    id="message"
                    placeholder="Additional Comments"
                    onChange={(option: { target: { value: string } }) => {
                      setFieldValue('message', option.target.value)
                    }}
                  ></TextInputArea>
                  {touched.message && errors.message && (
                    <span>{errors.message}</span>
                  )}
                  <ButtonWrapper>
                    <SubmitButton type="submit">Submit</SubmitButton>
                  </ButtonWrapper>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </Container>
      </MainContainer>
    </>
  )
}

export default SectionContactSupport
