import { Button } from '@monorepo/components'
import React from 'react'
import * as S from './styles'

const SectionZoners: React.FC = () => {
  return (
    <S.Section>
      <S.ImageContainer>
      <S.ImageBanner src={"/images/homepage/contact-support.png"}/>
      </S.ImageContainer>
      <S.Container>
        <S.Title>
          <span className="title_italic">Have questions?</span>
        </S.Title>
        <S.Content>
        Fill out the form, and we will contact you shortly to
        find out how we can support you.
        </S.Content>
        <Button
          colorOption="lapis"
          label="CONTACT SUPPORT"
          onClick={() => (window.location.href = '/contact')}
        />
      </S.Container>
    </S.Section>
  )
}

export default SectionZoners
