"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const molecules_1 = require("../../molecules");
const S = (0, tslib_1.__importStar)(require("./styles"));
const DetailedCard = ({ alt, backgroundImage, onClick, cardText, width, height, href, detailText, linkText, }) => {
    return ((0, jsx_runtime_1.jsxs)(S.Container, { children: [(0, jsx_runtime_1.jsx)(molecules_1.Card, { alt: alt, cardText: cardText, backgroundImage: backgroundImage, onClick: onClick, width: width, height: height }, void 0), (0, jsx_runtime_1.jsxs)(S.CardDetails, { children: [(0, jsx_runtime_1.jsx)(S.DetailText, { children: detailText }, void 0), (0, jsx_runtime_1.jsx)(S.Link, Object.assign({ href: href, target: "_blank" }, { children: linkText }), void 0)] }, void 0)] }, void 0));
};
exports.default = DetailedCard;
