import { OrderAddress } from '@monorepo/interfaces'
import React from 'react'
import * as S from '../styles'

interface CheckoutShippingViewProps {
  address: OrderAddress
  onClickEdit: () => void
  hasContactInfo: boolean
  hasShipping: boolean
}

export const SectionCheckoutShippingView: React.FC<CheckoutShippingViewProps> =
  ({ address, onClickEdit, hasContactInfo, hasShipping }) => {
    const getAddress = (address: OrderAddress) => {
      if (address.address2 && address.address2 !== '') {
        return `
          ${address.firstName} ${address.lastName}
          <br />
          ${address.address1}, ${address.address2}, ${address.city}, ${address.state} ${address.zipCode}
          `
      }
      return `
          ${address.firstName} ${address.lastName}
          <br />
          ${address.address1}, ${address.city}, ${address.state} ${address.zipCode}
          `
    }

    const getContactInfo = (address: OrderAddress) => {
      if (address.contactName && address.contactName !== '') {
        return `
          ${address.contactName}
          <br />
          ${address.email}
          <br/> 
          ${address.phoneNumber}
          <br/> 
           ${address.asmName}   ${address.asmEmail}
          `
      }
      return `${address.contactName}`
    }

    return (
      <S.ViewContainer>
        <S.ViewDetails flexBasis="90%">
          <S.Title>
            {hasContactInfo && 'CONTACT INFORMATION'}
            {hasContactInfo && hasShipping && ' AND '}
            {hasShipping && 'SHIPPING'}
          </S.Title>
          {address.address1 === '' && <S.Text>Click Edit to Update</S.Text>}
          {hasContactInfo && (
            <S.Text dangerouslySetInnerHTML={{ __html: getContactInfo(address)}} />
            )}
            {hasShipping && (
              <S.Text dangerouslySetInnerHTML={{ __html: getAddress(address) }} />
              )}
        </S.ViewDetails>
        <S.ViewDetails flexBasis="10%">
          <S.EditButton onClick={() => onClickEdit()}>Edit</S.EditButton>
        </S.ViewDetails>
      </S.ViewContainer>
    )
  }
