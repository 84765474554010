"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const S = (0, tslib_1.__importStar)(require("./styles"));
const react_router_dom_1 = require("react-router-dom");
const CardEvent = ({ link, image, title, subtitle, startDate, endDate, aditionalInfo, isNew = false, }) => {
    function formatDateRange(startDate, endDate) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const start = new Date(startDate);
        const end = new Date(endDate);
        const startYear = start.getFullYear();
        const endYear = end.getFullYear();
        const startMonth = start.toLocaleString('en-US', { month: 'short' });
        const endMonth = end.toLocaleString('en-US', { month: 'short' });
        if (startYear !== endYear) {
            return `${startMonth} ${start.getDate()}, ${startYear} - ${endMonth} ${end.getDate()}, ${endYear}`;
        }
        if (start.getMonth() !== end.getMonth()) {
            if (start.getDate() !== end.getDate()) {
                return `${startMonth} ${start.getDate()} - ${endMonth} ${end.getDate()}, ${startYear}`;
            }
            return `${startMonth} ${start.getDate()}-${end.getDate()}, ${startYear}`;
        }
        if (startDate === endDate) {
            return `${endMonth} ${end.getDate()}, ${endYear}`;
        }
        return `${startMonth} ${start.getDate()}-${end.getDate()}, ${startYear}`;
    }
    return ((0, jsx_runtime_1.jsxs)(S.Container, { children: [isNew ? (0, jsx_runtime_1.jsx)(S.CustomTag, { colorOption: "white", label: "NEW" }, void 0) : null, (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, Object.assign({ to: `/all-events/details/${link}` }, { children: (0, jsx_runtime_1.jsx)(S.ImageContainer, Object.assign({ background: image }, { children: (0, jsx_runtime_1.jsx)(S.ImageOverlay, { children: (0, jsx_runtime_1.jsx)(S.Title, { children: title }, void 0) }, void 0) }), void 0) }), void 0), (0, jsx_runtime_1.jsxs)(S.InfoContainer, { children: [startDate && endDate && ((0, jsx_runtime_1.jsx)(S.Subtitle, Object.assign({ className: 'date' }, { children: formatDateRange(startDate, endDate) }), void 0)), (0, jsx_runtime_1.jsx)(S.Subtitle, Object.assign({ className: 'location' }, { children: aditionalInfo }), void 0)] }, void 0)] }, void 0));
};
exports.default = CardEvent;
