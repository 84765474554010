import { Icon } from '@monorepo/components'
import * as S from './styles'

import React from 'react'
import { IconProps } from '@monorepo/components/build/src/atoms/Icon'

interface HeroCreativeProps {
  background?: {
    desk?: string
    mobile?: string
  }
  title: string
  description?: string
  placeholder?: string
  searchTerm?: string
  setSearchTerm?: (searchTerm: string) => void
  clearSearchTerm?: () => void
  color?: string
  backgroundColor?: string
  flexFlow?: string
  resetMinHeight?: boolean
  icon?: IconProps
}

const Hero: React.FC<HeroCreativeProps> = ({
  background,
  title,
  description,
  placeholder,
  searchTerm,
  setSearchTerm,
  clearSearchTerm,
  color,
  backgroundColor,
  flexFlow,
  resetMinHeight,
  icon
}) => {
  return (
    <S.Hero
      background={{ desk: background?.desk, mobile: background?.mobile }}
      color={color}
      backgroundColor={backgroundColor}
      flexFlow={flexFlow}
      resetMinHeight={resetMinHeight}
    >
      <div>
      {!!title ? <h1>{title}</h1> : undefined}
      {!!description ? <p>{description}</p> : undefined}
      </div>
      {icon &&
        <Icon {...icon} />
      }
      {setSearchTerm && clearSearchTerm && (
        <S.Search
        placeholder={placeholder}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
          onClear={clearSearchTerm}
          color={color}
          backgroundColor={backgroundColor}
        />
      )}
    </S.Hero>
  )
}

export default Hero
