"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePersistedStateObject = void 0;
var react_1 = require("react");
var utils_1 = require("../utils");
function usePersistedStateObject(key, subKey, initialState) {
    var getLocalStorageValue = function (key) {
        var result = undefined;
        var localStorageValue = localStorage.getItem(key);
        if (!!localStorageValue) {
            try {
                result = JSON.parse(localStorageValue);
            }
            catch (_a) {
                result = localStorageValue;
            }
        }
        return result;
    };
    var _a = (0, react_1.useState)(function () {
        var result = getLocalStorageValue(key);
        return (0, utils_1.isObject)(result)
            ? !!subKey
                ? result[subKey]
                : initialState
            : result || initialState;
    }), state = _a[0], setState = _a[1];
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if (state === undefined || !subKey)
            return;
        var localStorageValue = getLocalStorageValue(key);
        if (localStorageValue === undefined)
            localStorageValue = (_a = {}, _a[subKey] = state, _a);
        else if ((0, utils_1.isObject)(localStorageValue))
            localStorageValue[subKey] = state;
        else
            localStorageValue = (_b = {}, _b[subKey] = state, _b);
        localStorage.setItem(key, JSON.stringify(localStorageValue));
    }, [key, subKey, state]);
    return [state, setState];
}
exports.usePersistedStateObject = usePersistedStateObject;
