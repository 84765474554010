import React from 'react'
import * as S from './styles'
import { useTranslation } from 'react-i18next'

import { CardActionArea } from '@material-ui/core'

const SectionCategories: React.FC = () => {
  const { t } = useTranslation('productsList')

  return (
    <S.Section>
      <S.Cards>
        <S.Card>
          <CardActionArea>
            <S.Typography color="textSecondary">
              {t('section_categories.card1')}
            </S.Typography>
            <S.CardMedia
              image="images/products/card_tv.png"
              title={t('section_categories.card1')}
            />
          </CardActionArea>
        </S.Card>
        <S.Card>
          <CardActionArea>
            <S.Typography color="textSecondary">
              {t('section_categories.card2')}
            </S.Typography>
            <S.CardMedia
              image="images/products/card_direct.png"
              title={t('section_categories.card2')}
            />
          </CardActionArea>
        </S.Card>
        <S.Card>
          <CardActionArea>
            <S.Typography color="textSecondary">
              {t('section_categories.card3')}
            </S.Typography>
            <S.CardMedia
              image="images/products/card_internet.png"
              title={t('section_categories.card3')}
            />
          </CardActionArea>
        </S.Card>
        <S.Card>
          <CardActionArea>
            <S.Typography color="textSecondary">
              {t('section_categories.card4')}
            </S.Typography>
            <S.CardMedia
              image="images/products/card_wireless.png"
              title={t('section_categories.card4')}
            />
          </CardActionArea>
        </S.Card>
      </S.Cards>
    </S.Section>
  )
}

export default SectionCategories
