import styled from 'styled-components'
import {
  CardMedia as MuiCardMedia,
  Card as MuiCard,
  Typography as MuiTypography,
} from '@material-ui/core'

export const Section = styled.section`
  position: relative;
  width: 100%;
`
export const Cards = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const Card = styled(MuiCard)`
  position: relative;
  border-radius: 0;
  border: 0;
  width: 40vw;
  height: 40vh;
`

export const CardMedia = styled(MuiCardMedia)`
  height: 40vh;
  width: 100%;
  filter: brightness(50%);
`

export const Typography = styled(MuiTypography)`
  position: absolute;
  z-index: 1;
  top: 85%;
  left: 4%;
  color: white;
  font-weight: bold;
`
