import { useState, useEffect } from 'react'
import { Icon } from '@monorepo/components'
import { colors } from '@monorepo/theme'
import * as S from './styles'

export interface CarouselVItem {
  description?: string
  imageUrl?: string
  buttonGetStartedUrl?: string
}

export interface CarouselVProps {
  title?: string
  items?: CarouselVItem[]
}

const CarouselV: React.FC<CarouselVProps> = ({ title, items }) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number | undefined>()
  const [activeItemImageUrl, setActiveItemImageUrl] = useState<
    string | undefined
  >()
  const [activeItemButtonGetStartedUrl, setActiveItemButtonGetStartedUrl] =
    useState<string | undefined>()

  useEffect(() => {
    if (Array.isArray(items) && items.length) {
      setActiveItemIndex(0)
      setActiveItemData(0)
    }
  }, [])

  const setActiveItemData = (index?: number) => {
    setActiveItemImageUrl(items?.[index as number]?.imageUrl)
    setActiveItemButtonGetStartedUrl(
      items?.[index as number]?.buttonGetStartedUrl
    )
  }

  const handleItemClick = (index?: number) => {
    setActiveItemIndex(index)
    setActiveItemData(index)
  }

  return (
    <S.CarouselVContainer>
      <S.CarouselVImageContainer>
        {!!activeItemImageUrl ? (
          <S.CarouselVImageContainerImage src={activeItemImageUrl} />
        ) : undefined}
      </S.CarouselVImageContainer>
      <S.CarouselVTextContainer>
        {!!title ? (
          <S.CarouselVTextContainerTitle>
            Microsite program benefits
          </S.CarouselVTextContainerTitle>
        ) : undefined}
        <S.CarouselVTextContainerItems>
          {Array.isArray(items) && items.length
            ? items.map((item, index) => (
                <S.CarouselVTextContainerItem
                  key={index}
                  onClick={() => {
                    handleItemClick(index)
                  }}
                >
                  <S.CarouselVTextContainerItemArrow>
                    <Icon
                      icon="DottedArrowRight2"
                      color={
                        activeItemIndex === index
                          ? colors.turquoise
                          : colors.mediumGrey
                      }
                    />
                  </S.CarouselVTextContainerItemArrow>
                  <S.CarouselVTextContainerItemDescription>
                    {item.description}
                  </S.CarouselVTextContainerItemDescription>
                </S.CarouselVTextContainerItem>
              ))
            : undefined}
        </S.CarouselVTextContainerItems>
        <S.CarouselVTextContainerButtons>
          {!!activeItemButtonGetStartedUrl ? (
            <S.ButtonLink to={{ pathname: `${activeItemButtonGetStartedUrl}` }}>
              Get started
            </S.ButtonLink>
          ) : undefined}
        </S.CarouselVTextContainerButtons>
      </S.CarouselVTextContainer>
    </S.CarouselVContainer>
  )
}

export default CarouselV
