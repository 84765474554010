import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

import { useChannel, KeycloakHelper } from '@monorepo/infra'

import { Spinner } from '@monorepo/components'
import { NavItemsInterface } from '../../organisms/Header'
import { useMerchandiseURL } from './useMerchandiseUrl'

import * as S from './styles'
import { useDemoAppURL } from './useDemoAppUrl'
import { LogItemType, LogType, log } from '../../../services/log.service'

export interface NavItemProps {
  item: NavItemsInterface
}

const NavItem: React.FC<NavItemProps> = ({ item }) => {
  const [currentImage, setCurrentImage] = useState('')
  const { merchandiseURL, error } = useMerchandiseURL()
  const { demoAppUrl } = useDemoAppURL()
  const { selectedChannel, selectedMasterDealerId } = useChannel()
  const { keycloak } = useKeycloak()

  const logNavItemClick = (item: NavItemsInterface) => {
    if (isNaN(item.logItemType as number)) {
      return
    }

    log(
      {
        type: LogType.CLICK,
        itemType: item.logItemType,
        masterDealerId: selectedMasterDealerId,
        channel: selectedChannel,
      },
      KeycloakHelper.getToken(keycloak)
    )
  }

  return item && item.children?.length ? (
    <S.ListItemWithChildren>
      <S.ListItemWithoutLink>{item.name}</S.ListItemWithoutLink>
      <S.ListItemsContainer data-list-items-container>
        <S.ListMenu>
          {item.children.map((children, index) => (
            <S.ListItemChildren key={index}>
              <Link
                onMouseEnter={() => setCurrentImage(children.image)}
                to={children.route}
              >
                {children.name}
              </Link>
            </S.ListItemChildren>
          ))}
        </S.ListMenu>
        <S.ListItemImage isVisible={!!currentImage} src={currentImage} />
      </S.ListItemsContainer>
    </S.ListItemWithChildren>
  ) : (
    <S.ListItem>
      {item.route === '/merchandise' ? (
        merchandiseURL || error ? (
          merchandiseURL ? (
            <S.MerchandiseLink
              href={merchandiseURL}
              target="_blank"
              rel="noreferrer"
            >
              {item.name}
            </S.MerchandiseLink>
          ) : (
            <S.MerchandiseError title={error as string}>
              {item.name}
            </S.MerchandiseError>
          )
        ) : (
          <Spinner spinnerColor="white" spinnerSize={4} />
        )
      ) : item.route === '/demoapp' ? (
        <S.MerchandiseLink
          href={demoAppUrl}
          target="_blank"
          onClick={() => logNavItemClick(item)}
        >
          {item.name}
        </S.MerchandiseLink>
      ) : (
        <S.HeaderLink to={item.route}>{item.name}</S.HeaderLink>
      )}
    </S.ListItem>
  )
}

export default NavItem
